import React, { useReducer } from 'react';
import "./PageWrapper.css";
import MapPage from './Map/MapPage';
import SensorListPage from './SensorList/SensorListPage';
import ProfilePage from './Profile/ProfilePage';
import { useLocation, useNavigate } from 'react-router-dom';
import UserManagementPage from './UserManagement/UserManagementPage';
import MasterManagementPage from './MasterManagement/MasterManagementPage';
import { apiListSensors, locateMapContent } from 'apis/MapApi';
import { Auth } from 'aws-amplify';
import { useGeneralSetting } from 'context/GeneralSettingContext';
import PropTypes from 'prop-types';
import {
  PAGE_MAP,
  PAGE_MASTER_MANAGEMENT,
  PAGE_PROFILE,
  PAGE_SENSOR_LIST,
  PAGE_SENSOR_MANAGEMENT,
  PAGE_USER_MANAGEMENT,
} from 'utils/constanst';
import Menu from 'components/Menu';
import { useDialog } from 'context/DialogContext';
import { DIALOG_ERROR_GENERAL_ERROR_MESSAGE } from 'constants/message';
import FanLoadingIcon from 'components/FanLoadingIcon';
import { reducer } from 'utils/function';


const propTypes={};
const defaultProps={};
const PageWrapper = (props) => {
  /*** States and Variables ***/
  const navigate = useNavigate();
  const [topPage, setTopPage] = React.useState(PAGE_MAP);
  const mapRef = React.useRef(null);
  const { generalSetting } = useGeneralSetting();
  const [sensorDetail, setSensorDetail] = React.useState(null);
  const [allSensors, dispatch] = useReducer(reducer, []);
  const { error } = useDialog();
  const [loading, setLoading] = React.useState(false);
  const { pathname } = useLocation();
  const [isBackButtonClicked, setBackbuttonPress] = React.useState(false)
  /*** Processing ***/
  React.useEffect(()=>{
    const checkAuth = async () => {
      try {
        const res = await Auth.currentAuthenticatedUser();
      }
      catch {
        navigate("/login")
      }
    }
    checkAuth();
    downloadSensors();
    if (pathname === '/top') {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
          window.removeEventListener('popstate', onBackButtonEvent);
        }
    }
  }, [])

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      alert('戻るボタンは使用できません。メニューから選択してください。');
      window.history.pushState(null, null, window.location.pathname);
      setBackbuttonPress(false);
    }
  }

  const downloadSensors = async () => {
    setLoading(true);
    const result = await apiListSensors(null);
    console.log("RESULT", result)
    setLoading(false);
    if (result.success) {
      // result.data.items.sort((x,y)=>{return x.managementId.localeCompare(y.managementId)}); 
      // setAllSensors(result.data.items);
      dispatch({ type: 'init', list: result.data.items })
    } else {
      // console.error("ERROR", result.error);
      error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
    }
  }

  React.useEffect(()=>{
    document.title = generalSetting?.appTitle || '冠水監視';
  }, [generalSetting])

  const handleModifiedSensors = (sensor, type) => {
    dispatch({ type, sensor })
  }

  const handleInitSensors = (list, type) => {
    dispatch({ type, list })
  }

  /*** Sub Components ***/
  /*** Event Handlers ***/

  const onMapLoad = (map) => mapRef.current = map;

  const onFocusOnSensor = async (sensor) => {
    setTopPage(PAGE_MAP);
    mapRef.current.flyTo({lat: sensor.lat, lon: sensor.lng}, mapRef.current.getZoom());
  }

  const onViewSensorDetail = (sensor) => {
    setSensorDetail(sensor);
    setTopPage(PAGE_SENSOR_LIST);
  }

  /*** Main Render ***/
  return <div className='page_wrapper_container' style={{ overflow: topPage !== PAGE_MAP ? 'auto' : '' }}>
    <div className='page_wrapper_body' >
      {topPage === PAGE_MAP && <Menu page={topPage} setPage={setTopPage}/>}
      <MapPage 
        onMapLoad={onMapLoad}
        mapRef={mapRef}
        onViewSensorDetail={onViewSensorDetail}
        page={topPage}
        reloadSensors={handleInitSensors}
        allSensors={allSensors}
        downloadSensors={downloadSensors}
      />
      {
        topPage !== PAGE_MAP &&
        <div style={{ position: 'fixed', inset: 0, background: 'white', overflow: 'auto' }}>
          <div className='page_wrapper_top_page_container'>
            <Menu page={topPage} setPage={setTopPage}/>
            <Page 
                page={topPage} 
                onMapLoad={onMapLoad} 
                onFocusOnSensor={onFocusOnSensor}
                mapRef={mapRef}
                sensorDetail={sensorDetail}
                setSensorDetail={setSensorDetail}
                onViewSensorDetail={onViewSensorDetail}
                handleModifiedSensors={handleModifiedSensors}
                allSensors={allSensors}
                downloadSensors={downloadSensors}
            />
          </div>
        </div>
        
      }
      {loading && <FanLoadingIcon size={80}/>}
    </div>
  </div>;
}

const Page = (props) => {
  switch (props.page) {
    case PAGE_SENSOR_LIST:
      return <SensorListPage 
                isManagementPage={false} 
                onFocusOnSensor={props.onFocusOnSensor} 
                sensorDetail={props.sensorDetail}
                setSensorDetail={props.setSensorDetail}
                handleModifiedSensors={props.handleModifiedSensors}
                allSensors={props.allSensors}
                downloadSensors={props.downloadSensors}
                page={props.page}
              />
    case PAGE_PROFILE:
      return <ProfilePage />
    case PAGE_SENSOR_MANAGEMENT:
      return <SensorListPage 
                isManagementPage={true}
                handleModifiedSensors={props.handleModifiedSensors}
                allSensors={props.allSensors}
                downloadSensors={props.downloadSensors}
                page={props.page}
              />
    case PAGE_MASTER_MANAGEMENT:
      return <MasterManagementPage />
    case PAGE_USER_MANAGEMENT:
      return <UserManagementPage />
    default: 
      return <></>
  }
}

Page.propTypes = {
  page: PropTypes.number,
  onFocusOnSensor: PropTypes.func,
  sensorDetail: PropTypes.object,
  setSensorDetail: PropTypes.func,
  handleModifiedSensors: PropTypes.func,
  allSensors: PropTypes.array,
  downloadSensors: PropTypes.func,
}

Page.defaultProps = {
  page: PAGE_MAP,
  onFocusOnSensor: () => {},
  sensorDetail: {},
  setSensorDetail: () => {},
  handleModifiedSensors: () => {},
  allSensors: [],
  downloadSensors: () => {},
}

PageWrapper.propTypes = propTypes;
PageWrapper.defaultProps = defaultProps;
export default PageWrapper;