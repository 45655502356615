import './Menu.css'
import React from 'react';
import hamburger from 'assets/svgicon/icon-hamburger.png';
import { ADMIN_SETTING_MENU, MANAGER_SETTING_MENU, PAGE_MAP, PAGE_PROFILE, SENSOR_MANAGE_MENU, SETTING_MENU } from 'utils/constanst';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { logoutCognitoUser } from 'apis/UserApi';
import { useOutsideClick } from 'utils/function';
import PropTypes from 'prop-types';
import Icon, { IconList } from 'components/Icon';
import { AccountType } from 'models';

const GroupMenuItem = ({ page, onClick, label, menuList, styles }) => {
  return (
    <div className={styles}>
        <p className='margin-none menu-label'>{label}</p>
        {
          menuList.map((menu) => (
              <div key={menu.page} onClick={onClick(menu)} className={`menu-flex_row menu-item ${page === menu.page ? 'current_page' : ''}`}>
                <Icon icon={menu.icon} />
                <p className='margin-none'>{menu.label}</p>
              </div>
            ))
        }
    </div>
  )
}

GroupMenuItem.propTypes = {
  page: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  menuList: PropTypes.arrayOf(PropTypes.object),
  styles: PropTypes.string,
}

GroupMenuItem.defaultProps = {
  page: PAGE_MAP,
  onClick: () => {},
  label: 'センサ管理',
  menuList: [],
  styles: '',
}


const Menu = ({ page, setPage }) => {
    const navigate = useNavigate();
    const { currentUser, setCurrentUser } = useAuth();
    React.useEffect(()=>{
        const checkAuth = async () => {
          try {
            const res = await Auth.currentAuthenticatedUser();
          }
          catch {
            navigate("/login")
          }
          // eslint-disable-next-line no-restricted-globals
          // history.pushState(null, null, null);
          // window.addEventListener('popstate', () => {
          //   // eslint-disable-next-line no-restricted-globals
          //   history.pushState(null, null, null);
          //   alert("戻るボタンは使用できません。メニューから選択してください。");
          // });
        }
        checkAuth();
      }, [])

    const handleClickOutside = () => setIsOpen(false);
    const isValidation = () => isOpen;
    const onNavigate = (item) => () => setPage(item.page);

    const [isOpen, setIsOpen] = React.useState(false);
    const menuRef = useOutsideClick(handleClickOutside, [isOpen], isValidation);

    const logOut = async () => {
        try {
          await logoutCognitoUser();
          setCurrentUser(null);
          navigate("/login");
        } catch (e) {
          // console.error(e)
        }
    }

    return (
        <div ref={menuRef} className='menu'>
            <div className='menu_container page_wrapper_menu-button' style={{ '--top': page === PAGE_MAP ? '34px' : '28px' }} onClick={() => setIsOpen(!isOpen)}>
                <img alt='menu' src={hamburger} className='page_wrapper_menu-icon_menu'/>
                <p style={{ margin: 0 }}>メニュー</p>
            </div>
            {
              isOpen && 
              <div className='menu_container page_wrapper_menu-container' style={{ '--top': page === PAGE_MAP ? '137px' : '134px' }}>
                <div className='menu-flex_row page_wrapper_menu-user' onClick={()=>{setPage(PAGE_PROFILE)}}>
                  <div className='menu-flex_row menu-user_info'>
                      <Icon icon={IconList.iconUser} />
                      <div>{currentUser?.name || ''}</div>
                  </div>
                  <Icon icon={IconList.iconAllow} />
                </div>
                <GroupMenuItem
                  page={page}
                  onClick={onNavigate}
                  label='センサ管理'
                  menuList={SENSOR_MANAGE_MENU}
                  styles='page_wrapper_menu-sensor_manage'
                />
                {currentUser.accountType!==AccountType.USER && <GroupMenuItem
                  page={page}
                  onClick={onNavigate}
                  label='管理設定'
                  menuList={currentUser.accountType===AccountType.ADMIN? ADMIN_SETTING_MENU: MANAGER_SETTING_MENU}
                />}
                <div className='menu-flex_row page_wrapper_menu-logout' onClick={logOut}>
                  <Icon icon={IconList.iconMenuLogout} />
                  <p style={{ margin: 0 }}>ログアウト</p>
                </div>
              </div>
            }
            
        </div>
    )
}

Menu.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
}

Menu.defaultProps = {
  page: PAGE_MAP,
}

export default Menu;