import React from 'react';
import PropTypes from 'prop-types';
import "./SearchBox.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { standardizeString } from 'utils/function';

const propTypes={
  defaultKeyword: PropTypes.string,
  onSearch: PropTypes.func,
};
const defaultProps={
  defaultKeyword: '',
  onSearch: (originalKeyword, analyzedKeywords) => {},
};
const SearchBox = (props) => {
  /*** States and Variables ***/
  const [keyword, setKeyword] = React.useState('');
  const [preKeyword, setPreKeyword] = React.useState('');
  /*** Processing ***/
  React.useEffect(()=>{
    if (keyword!==props.defaultKeyword) {
      setKeyword(props.defaultKeyword);
      setPreKeyword(props.defaultKeyword);
    }
  }, [props.defaultKeyword])
  /*** Sub Components ***/
  /*** Event Handlers ***/
  const onKeydown = (e) => {
    if(e.key==='Enter' && keyword!==preKeyword) {
      onSearch();
    }
  }
  const onSearch = () => {
    setPreKeyword(keyword);
    let keywords = standardizeString(keyword).replaceAll(/[、､ 　]/g,",");
		keywords = keywords.split(",");
		keywords = keywords.map(keyword=>keyword.trim());
		keywords = keywords.filter(keyword=>keyword!=="");
    props.onSearch(keyword, keywords);
  }
  /*** Main Render ***/
  return <div className='search_box_container'>
    <input 
    className='search_box_input'
    value={keyword}
    onKeyDown={onKeydown}
    onChange={(e)=>{setKeyword(e.target.value)}}
    />
    <button 
    className='search_box_button'
    disabled={keyword===preKeyword}
    onClick={onSearch}>
      <FontAwesomeIcon icon={faMagnifyingGlass} />
    </button>
  </div>;
}
SearchBox.propTypes = propTypes;
SearchBox.defaultProps = defaultProps;
export default SearchBox;