import React, { useState } from 'react';
import PropTypes from 'prop-types';
import "./BaseList.css";
import Pagination from './Pagination';
import { CURRENT_PAGE_DEFAULT, ITEM_NUM_PER_PAGE } from '../constants/config';
import { getComparator, stableSort } from 'utils/function';
import { SENSOR_PROPERTIES } from 'utils/constanst';

const propTypes = {
  items: PropTypes.array,
  renderHeader: PropTypes.func,
  renderItem: PropTypes.func,
  curPage: PropTypes.number,
  onPageChange: PropTypes.func,
  itemNumPerPage: PropTypes.number,
  isNew: PropTypes.bool,
  functionBtn: PropTypes.func,
  isSort: PropTypes.bool,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  maxPage: PropTypes.number,
  maxItems: PropTypes.number,
};
const defaultProps = {
  items: [],
  renderHeader: () => null,
  renderItem: (item) => null,
  curPage: CURRENT_PAGE_DEFAULT,
  onPageChange: (newPage) => {},
  itemNumPerPage: ITEM_NUM_PER_PAGE,
  isNew: false,
  functionBtn: () => null,
  isSort: false,
  order: 'asc',
  orderBy: SENSOR_PROPERTIES.MANAGE_ID,
  maxPage: null,
  maxItems: null,
};

const BaseList = (props) => {
  /*** States and Variables ***/
  const pageNum = !props.items.length ? 1 : Math.ceil(props.items.length / props.itemNumPerPage);
  const [total, setTotal] = useState(0);

  React.useEffect(() => {
    if (props.maxItems) {
      return props.items.length > props.maxItems ? setTotal(props.maxItems) : setTotal(props.items.length);
    } else {
      return setTotal(props.items.length);
    }
  }, [props.items.length, props.maxItems])

  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    if (props.isSort) {
      const result = stableSort(props.items, getComparator(props.order, props.orderBy));
      setList(result);
    } else {
      setList(props.items);
    }
  }, [props.items, props.isSort, props.order, props.orderBy])
/*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  /*** Main Render ***/
  return <div className='base_list_container'>
    <Pagination
      pageNum={props.maxPage ? ( pageNum > props.maxPage - 1 ? props.maxPage : pageNum ) : pageNum}
      curPage={props.curPage}
      onPageChange={props.onPageChange}
      itemNumPerPage={props.itemNumPerPage}
      total={total}
      isNew={props.isNew}
      functionBtn={props.functionBtn}
    />

    <div
      className='base_list_list_items_container'
      style={{height: pageNum <= 1 ? undefined : 'calc(100% - 24px)'}}
    >
      <div className='base_list_list_items_subcontainer'>
          {props.renderHeader()}
          {
            (props.itemNumPerPage > 0 ? list.slice((props.curPage - 1) * props.itemNumPerPage, (props.curPage - 1) * props.itemNumPerPage + props.itemNumPerPage) : list)
            .map((item, i) => props.renderItem(item, i))
          }
      </div>
    </div>
  </div>
}
BaseList.propTypes = propTypes;
BaseList.defaultProps = defaultProps;
export default BaseList;