import Icon, { IconList } from 'components/Icon';
import './DetailHeader.css';
import PropTypes from 'prop-types';
import { TEXT_PROFILE } from 'constants/message';

const DetailHeader = ({ mainLabel, subLabel, onClick }) => {
    return (
        <div className='detail_header-flex_row detail_header'>
            <div className='detail_header-flex_row detail_header-title'>
                <div className='detail_header-flex_row detail-header-title-symbol' onClick={onClick}>
                    { mainLabel !== TEXT_PROFILE && <Icon icon={IconList.iconListingDetail} /> }
                    <label className='detail-header-title-text'>{mainLabel}</label>
                </div>
                <Icon icon={IconList.iconAllowDetail} />
            </div>
            <div className='detail_header-flex_row'>
                <label className='detail-header-sub-title'>{subLabel}</label>
            </div>
        </div>
    )
}

DetailHeader.propTypes = {
    mainLabel: PropTypes.string,
    subLabel: PropTypes.string,
    onClick: PropTypes.func,
}

DetailHeader.defaultProps = {
    mainLabel: '',
    subLabel: '',
    onClick: () => {},
}

export default DetailHeader;