import React from 'react';
import PropTypes from 'prop-types';
import "./MapView.css"
import {MapContainer, Marker, TileLayer} from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import { useSensorGroups } from 'context/SensorGroupsContext';
import { SENSOR_STATUS_ABNORMAL, SENSOR_STATUS_ALERT, SENSOR_STATUS_ATTENTION, SENSOR_STATUS_NORMAL } from 'constants/config';
import { LockState } from 'models';
import { useGeneralSetting } from 'context/GeneralSettingContext';

const propTypes = {
  markers: PropTypes.array,
  selectedId: PropTypes.string, // id of selected item, this does not need to be one among 'markers'
  onViewportChange: PropTypes.func,
  onMarkerClick: PropTypes.func,
  onMapClick: PropTypes.func,
  onMapLoad: PropTypes.func,
};

const defaultProps = {
  markers: [],
  selectedId: '',
  onViewportChange: (e) => {
  },
  onMarkerClick: (marker) => {
  },
  onMapClick: () => {
  },
  onMapLoad: (e) => {
  }
};
const MapView = (props) => {
  /*** States and Variables ***/
  const mapRef = React.useRef(null);
  const { sensorGroups } = useSensorGroups();
  const markersRef = React.useRef([]);
  const { generalSetting } = useGeneralSetting();
  /*** Processing ***/
  React.useEffect(() => {
    if (props.onViewportChange) {
      if (mapRef.current) mapRef.current.on('moveend', props.onViewportChange)
    }
    return () => {
      if (mapRef.current) mapRef.current.off('moveend', props.onViewportChange)
    }
  }, [props.onViewportChange])
  React.useEffect(() => {
    if (props.onMapLoad) {
      if (mapRef.current) mapRef.current.on('load', props.onMapLoad)
    }
    return () => {
      if (mapRef.current) mapRef.current.off('load', props.onMapLoad)
    }
  }, [props.onMapLoad])
  React.useEffect(()=>{
    sensorGroups.length && renderMarkers();
    return () => {
      for(const marker of markersRef.current) {
        mapRef.current.removeLayer(marker);
      }
      markersRef.current = [];
    }
  }, [props.markers, sensorGroups, props.onMarkerClick])
  /**
   * マップイベントハンドル設定：マップをクリック
   */
  React.useEffect(() => {
    mapRef.current.on('click', onMapClick);
    return () => {
      mapRef.current.off('click', onMapClick);
    }
  }, [props.onMapClick])
  /*** Sub Components ***/

  const getTargetGroup = (value) => sensorGroups.find(g => g.id === value);
  const getTargetMessage = (value, group) => group.messages.find(m => m.value === value);
  const isLocked = (value) => value === LockState.LOCKED && generalSetting?.alertOn;

  const checkTypeStatus = (value, groupId) => {
    const targetGroup = getTargetGroup(groupId);
    if (targetGroup) {
      const targetMessage = getTargetMessage(value, targetGroup);
      if (targetMessage) return true;
    }
    return false;
  }

  const modifiedMarker = (marker) => {
    let status, pos, groupId;
    //group sensors
    if (marker.id.includes('#')) {
      //alert sensor with locked state
      if (generalSetting?.alertOn) {
        const indexLocked = marker.isLocked.indexOf(LockState.LOCKED);
        if (indexLocked !== -1) {
          groupId = marker.groupId[indexLocked];
          const targetGroup = getTargetGroup(groupId);
          if (targetGroup) {
            status = targetGroup.messages.map(m => m.value).indexOf(SENSOR_STATUS_ALERT);
          }
          return {
            status,
            groupId
          }
        }
      }
      //other state without locked
      const uniqueStatus = new Set(marker.status.filter(statusValue => statusValue !== -1));
      if (uniqueStatus.size) {
        for (const statusValue of uniqueStatus) {
          status = statusValue;
          pos = marker.status.indexOf(statusValue);
          groupId = marker.groupId[pos];
          const isAlert = checkTypeStatus(SENSOR_STATUS_ALERT, groupId);
          if (isAlert) break;

          const isAttension = checkTypeStatus(SENSOR_STATUS_ATTENTION, groupId);
          if (isAttension) break;

          const isAbnormal = checkTypeStatus(SENSOR_STATUS_ABNORMAL, groupId);
          if (isAbnormal) break;
        }
      } else {
        status = -1;
        pos = marker.status.indexOf(status);
        groupId = marker.groupId[pos];
      }
    } else {
      //single sensor
      status = marker.status;
      groupId = marker.groupId;
      if (isLocked(marker.isLocked)) {
        const targetGroup = sensorGroups.find(g => g.id === groupId);
        if (targetGroup) {
          status = targetGroup.messages.map(m => m.value).indexOf(SENSOR_STATUS_ALERT);
        }
      }
    }

    return {
      status, 
      groupId
    }
  }
  
  const renderMarkers = () => {
    props.markers?.forEach((marker) => {
      let color = '#000';
      const { status, groupId } = modifiedMarker(marker);
      const targetGroup = sensorGroups.find(g => g.id === groupId);
      if (targetGroup && status !== -1) {
        const targetMessage = targetGroup.messages[status];
        color = targetMessage.color;
      }
      const svgIcon = L.divIcon({
        html: `
        <svg xmlns="http://www.w3.org/2000/svg" width="40.282" height="46.497" viewBox="0 0 40.282 46.497">
          <g id="icon-sensor-caution" transform="translate(-210.5 -273.5)">
            <path id="パス_24" data-name="パス 24" d="M240.95,274H220.332A9.332,9.332,0,0,0,211,283.332V303.95a9.332,9.332,0,0,0,9.332,9.332h5.773l3.115,5.4a1.64,1.64,0,0,0,2.841,0l3.115-5.4h5.773a9.332,9.332,0,0,0,9.332-9.332V283.332A9.332,9.332,0,0,0,240.95,274Z" fill="#fff" stroke="${color}" stroke-width="1"/>
            <path id="パス_25" data-name="パス 25" d="M240.95,274H220.332A9.332,9.332,0,0,0,211,283.332V303.95a9.332,9.332,0,0,0,9.332,9.332h5.773l3.115,5.4a1.64,1.64,0,0,0,2.841,0l3.115-5.4h5.773a9.332,9.332,0,0,0,9.332-9.332V283.332A9.332,9.332,0,0,0,240.95,274Zm5.077,29.95a5.082,5.082,0,0,1-5.077,5.077H220.332a5.082,5.082,0,0,1-5.077-5.077V283.332a5.082,5.082,0,0,1,5.077-5.077H240.95a5.082,5.082,0,0,1,5.077,5.077Z" fill="${color}"/>
            <path id="パス_26" data-name="パス 26" d="M239.884,312.37a7.857,7.857,0,0,0,0,11.111l1.852-1.852a5.244,5.244,0,0,1,0-7.407Zm-1.389-1.389-1.852-1.852a12.439,12.439,0,0,0,0,17.592l1.852-1.852A9.831,9.831,0,0,1,238.5,310.982Zm12.5,1.389-1.852,1.852a5.244,5.244,0,0,1,0,7.407l1.852,1.852A7.857,7.857,0,0,0,250.995,312.37Zm-5.555,3.591a1.964,1.964,0,1,0,1.964,1.964A1.964,1.964,0,0,0,245.439,315.962Zm8.8-6.832-1.852,1.852a9.831,9.831,0,0,1,0,13.888l1.852,1.852A12.439,12.439,0,0,0,254.235,309.13Z" transform="translate(-14.798 -23.63)" fill="${color}"/>
          </g>
        </svg>`,
        className: "map_view_marker",
        iconSize: [39, 45],
        iconAnchor: [12, 40],
      });
      markersRef.current
        .push(L.marker([marker.lat, marker.lng], { icon: svgIcon })
        .addTo(mapRef.current)
        .on('click', ()=>{props.onMarkerClick(marker)}));
    })
  }
  
  /*** Event Handlers ***/
  const onMapReady = (e) => {
    const map = e.target;
    mapRef.current = map;
    map.on('click', onMapClick);
    if (props.onViewportChange) {
      map.on('moveend', props.onViewportChange)
    }
    props.onMapLoad(e);
  }

  /**
   * イベントハンドル：マップをクリック
   * @param {event} e
   */
  const onMapClick = (e) => {
    props.onMapClick();
  }
  /*** Main Render ***/
  return <div className='map_view_map_container'>
    <MapContainer
      center={[36.83763081714207, 139.3154172350928]}
      zoom={5}
      scrollWheelZoom={true}
      style={{height: '100%', zIndex: 0}}
      zoomControl={false}
      zoomDelta={0.5}
      whenReady={onMapReady}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  </div>;
}
MapView.propTypes = propTypes;
MapView.defaultProps = defaultProps;
export default MapView;