import React from 'react';
import PropTypes from 'prop-types';
import "./SensorDetailView.css";
import SensorHistoryList from './SensorHistoryList';
import { useSensorGroups } from 'context/SensorGroupsContext';
import { apiListHistories } from 'apis/SensorApi';
import { ITEM_NUM_PER_PAGE } from 'constants/config';
import { useDialog } from 'context/DialogContext';
import { DIALOG_ERROR_GENERAL_ERROR_MESSAGE } from 'constants/message';
import FanLoadingIcon from 'components/FanLoadingIcon';
import iconAllow from 'assets/svgicon/icon-return.svg';
import { SENSOR_LEFT_INFO, SENSOR_PROPERTIES, SENSOR_RIGHT_INFO, UNDEFINED_TEXT } from 'utils/constanst';
import Icon, { IconList } from 'components/Icon';
import { modifiedValue } from 'utils/function';

const propTypes={
  sensor: PropTypes.object,
  onButtonMapClick: PropTypes.func,
  setSelectedSensor: PropTypes.func,
  setSensorDetail: PropTypes.func,
};
const defaultProps={
  sensor: {},
  onButtonMapClick: () => {},
  setSelectedSensor: () => {},
  setSensorDetail: () => {},
};

const ReturnBtn = ({ onClick }) => {
  return (
    <div className='sensor_detail_view-flex_row comeback-btn-container' >
        <button className='sensor_detail_view-flex_row comeback-btn' onClick={onClick}>
          <Icon icon={IconList.iconReturn}/>
          <label style={{ color: '#1d679d' }}>戻る</label>
        </button>
    </div>
  )
}

ReturnBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
}
ReturnBtn.defaultProps = {
  onClick: () => {},
}


const SensorItem = ({ sensor, field }) => {
  
  return (
    <div className='sensor_detail_view-flex_row sensor_detail_view_info'>
        {
          field.map((info, index) => (
            <div key={index} className='sensor_detail_view-flex_row sensor_detail_view_item'>
              <label className='label-text'>{info.label}</label>
              <label className='value-text'>{modifiedValue(sensor[info.id], info.id)}</label>
            </div>
          ))
        }
    </div>
  )
}

SensorItem.propTypes = {
  sensor: PropTypes.object,
  field: PropTypes.array,
}

SensorItem.defaultProps = {
  sensor: {},
  field: []
}

const SensorDetailView = (props) => {
  /*** States and Variables ***/
  const [histories, setHistories] = React.useState([]);
  const { sensorGroups } = useSensorGroups();
  const nextTokenRef = React.useRef(null);
  const {sensor} = props;
  const [curPage, setCurPage] = React.useState(1);
  const { error } = useDialog();
  const [loading, setLoading] = React.useState(false);

  //handle Modified data

  const handleHistoryInfo = React.useCallback((item) => {
    const targetGroup = sensorGroups.find(g => g.id === sensor.groupId);
    const messageInfo = targetGroup && targetGroup.messages && targetGroup.messages[item.status];
    const statusValue = {
        color: '#000',
        content: UNDEFINED_TEXT, 
    };
    if (messageInfo && item.status >= 0) {
        statusValue.color = messageInfo.color;
        statusValue.content = messageInfo.status;
    }
    return statusValue;
  }, [sensorGroups, sensor]);

  /*** Processing ***/
  React.useEffect(()=>{
    const downloadHistories = async () => {
      const result = await apiListHistories(sensor.id, nextTokenRef.current);
      if (result.success) {
        const modifiedData = result.data?.map((data) => {
          const statusValue = handleHistoryInfo(data);
          return {
            ...data,
            statusValue,
          }
        });
        setHistories(modifiedData);
        nextTokenRef.current = result.nextToken;
      }
      else {
        error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
      }
    }
    downloadHistories();
    return ()=>{
      setHistories([]);
      nextTokenRef.current = null;
    }
  }, [sensor.id])
  React.useEffect (()=>{
    return () => {
      setHistories([]);
      nextTokenRef.current = null;
    }
  }, [])
  /*** Sub Components ***/
  /*** Event Handlers ***/
  const onPageChange = async (newPage) => {
    const pageNum = Math.ceil(histories.length / ITEM_NUM_PER_PAGE);
    if (pageNum===newPage && nextTokenRef.current) {
      setLoading(true);
      const result = await apiListHistories(sensor.id, nextTokenRef.current);
      if (result.success) {
        const modifiedData = result.data?.map((data) => {
          const statusValue = handleHistoryInfo(data);
          return {
            ...data,
            statusValue,
          }
        });
        histories.push(...modifiedData);
        setHistories(Object.assign([], histories));
        nextTokenRef.current = result.nextToken;
      }
      else {
        error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
      }
      setLoading(false);
    }
    setCurPage(newPage);
  }
  

  const handleComeback = () => {
    props.setSelectedSensor(null)
    props.setSensorDetail(null);
  }
  /*** Main Render ***/
  return (
    <div className='sensor_detail_view_container'>
      <div className='sensor_detail_view-flex_row sensor_detail_main_info'>
      <SensorItem sensor={sensor} field={SENSOR_LEFT_INFO} />
      <SensorItem sensor={sensor} field={SENSOR_RIGHT_INFO} />
      </div>
      <ReturnBtn onClick={handleComeback} />
      <div className='sensor_detail_view_history_list_container'>
        <SensorHistoryList
          sensor={sensor}
          sensorHistories={histories}
          curPage={curPage}
          onPageChange={onPageChange}
          itemNumPerPage={ITEM_NUM_PER_PAGE}
        />
      </div>
      <ReturnBtn onClick={handleComeback} />
      {loading? <FanLoadingIcon size={80}/>: null}
    </div>
  )
}
SensorDetailView.propTypes = propTypes;
SensorDetailView.defaultProps = defaultProps;
export default SensorDetailView;