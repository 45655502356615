import React from 'react';
import PropTypes from 'prop-types';
import "./Pagination.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAnglesLeft, faAnglesRight} from '@fortawesome/free-solid-svg-icons';
import { CURRENT_PAGE_DEFAULT, ITEM_NUM_PER_PAGE } from '../constants/config';
import iconAllow from 'assets/svgicon/allow.svg';

const propTypes = {
  total: PropTypes.number,
  pageNum: PropTypes.number,
  curPage: PropTypes.number,
  onPageChange: PropTypes.func,
  itemNumPerPage: PropTypes.number,
  isNew: PropTypes.bool,
  functionBtn: PropTypes.func,
};
const defaultProps = {
  total: 0,
  pageNum: 1,
  curPage: CURRENT_PAGE_DEFAULT,
  onPageChange: (newPage) => {},
  itemNumPerPage: ITEM_NUM_PER_PAGE,
  isNew: false,
  functionBtn: () => null,
};

const Pagination = (props) => {
  /*** States and Variables ***/
  const {
    total,
    pageNum,
    curPage,
    onPageChange,
    itemNumPerPage,
    isNew,
    functionBtn
  } = props
  const [indicate, setIndicate] = React.useState('');

  React.useEffect(() => {
    if (total === 0) {
      return setIndicate(0);
    }
    const start = (curPage - 1) * itemNumPerPage + 1;
    const end = (start + itemNumPerPage) - 1;
    if (total < end) {
      setIndicate(`${start}-${total}`)
    } else {
      setIndicate(`${start}-${end}`)
    }

  }, [curPage, itemNumPerPage, total]);

  /*** Sub Components ***/
  const renderPages = () => {
    return Array.from({length: 5}).map((_, i) => i + props.curPage - 2).map(i => {
      if (i < 1 || i > props.pageNum) return null;
      return <button
        key={i}
        className={i === props.curPage ? 'pagination_indicator_selected_button' : 'pagination_indicator_button'}
        onClick={() => {
          props.onPageChange(i)
        }}>
        {i}
      </button>
    });
  }

  const renderPageIndicator = () => {
    return <div className='pagination_indicator_container'>
      <button
        className={'pagination_indicator_button'}
        onClick={() => {
          props.onPageChange(1)
        }}>
        <FontAwesomeIcon icon={faAnglesLeft}/>
      </button>
      {renderPages()}
      <button
        className={'pagination_indicator_button'}
        onClick={() => {
          props.onPageChange(props.pageNum)
        }}>
        <FontAwesomeIcon icon={faAnglesRight}/>
      </button>
    </div>
  }
  /*** Event Handlers ***/
  const onNext = () => {
    onPageChange(curPage + 1);
  }
  const onPrev = () => {
    onPageChange(curPage - 1);
  }

  /*** Main Render ***/
  return <div className='pagination-flex_row pagination_container'>
    {
      isNew ?
      <>
        {functionBtn()}
        <div className='pagination-flex_row pagination_container' style={{ marginLeft: 0, marginRight: 0 }}>
          <label className='pagination-text'>{indicate} 件</label>
          <button className='pagination-flex_row pagination_btn' disabled={curPage <= 1} onClick={onPrev}>
            <div className='pagination-flex_row pagination-flex_row'>
              <img alt='allow' src={iconAllow} style={{ transform: 'rotate(180deg)'}} />
            </div>
            <label>前{itemNumPerPage}件</label>
          </button>
          <button className='pagination-flex_row pagination_btn' disabled={curPage >= pageNum} onClick={onNext} >
            <label>次{itemNumPerPage}件</label>
            <div className='pagination-flex_row'>
                <img alt='allow' src={iconAllow} />
            </div>
          </button>
        </div>
      </> :
      renderPageIndicator()
    }    
  </div>
}
Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;                      