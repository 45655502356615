import React, {useEffect, useState} from 'react';
import "./LoginPage.css";
import logoImage from "assets/logo_company_name.png"
import {ReactComponent as IconEnvelop} from "assets/icon_envelop.svg";
import {ReactComponent as IconLocked} from "assets/icon_locked.svg";
import {ReactComponent as IconResend} from "assets/icon_resend.svg";
import {useNavigate} from "react-router-dom";
import {useGeneralSetting} from 'context/GeneralSettingContext';
import {
  loginCognitoUser,
  sendVerificationCodeForgotPassword,
  submitNewPasswordWithVerificationCode
} from "apis/UserApi";
import {
  TEXT_BUTTON_BACKWARD,
  TEXT_BUTTON_LOGIN,
  TEXT_BUTTON_SEND,
  TEXT_ERROR_INCORRECT_PASSWORD,
  TEXT_ERROR_USER_NOT_CONFIRMED,
  TEXT_FORGOT_PASSWORD_INSTRUCTION,
  TEXT_FORGOT_PASSWORD_LINK,
  TEXT_PASSWORD,
  TEXT_CONFIRM_NEW_PASSWORD,
  TEXT_SAVE_LOGIN,
  TEXT_LOGIN_ID,
  TEXT_FORGOT_PASSWORD_INPUT_CODE,
  DIALOG_ERROR_USE_SHORT_KEYWORD,
  DIALOG_ERROR_INVALID_CONFIRM_PASSWORD,
  TEXT_NEW_PASSWORD
} from 'constants/message';
import {useForm} from "react-hook-form";
import FanLoadingIcon from 'components/FanLoadingIcon';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const propTypes = {};
const defaultProps = {};
const LoginPage = (props) => {
  /*** States and Variables ***/
  const {
    register, handleSubmit, formState: { errors }, watch, control, setValue, getValues
  } = useForm();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [autoLogin, setAutoLogin] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { generalSetting } = useGeneralSetting();
  const [loading, setLoading] = useState(false);
  /*** Processing ***/
  useEffect(() => {
    if (isCodeSent) {
      let seconds = 300;
      const countTime = setInterval(() => {
        if (seconds > 0) {
          --seconds;
          setCountDown(seconds)
        } else {
          setIsCodeSent(false)
          clearInterval(countTime);
        }
      }, 1000)
    }
  }, [isCodeSent])

  React.useEffect(()=>{
    document.title = generalSetting?.appTitle || '冠水監視';
  }, [generalSetting])
  
  React.useEffect(()=>{
    const checkAutoLogin = async () => {
      const autoLoginVal = localStorage.getItem('AUTO_LOGIN')==='true';
      setAutoLogin(autoLoginVal);
      try {
        await Auth.currentAuthenticatedUser();
        if(localStorage.getItem('AUTO_LOGIN')==='true') {
          navigate('/top');
        }
      }
      catch (e) {}
    }
    checkAutoLogin();
  }, [])
  /*** Sub Components ***/
  const renderLoginContent = () => {
    return <>
      <div className='login_page_input_container'>
        <IconEnvelop/>
        <input
          type='text'
          className='login_page_input'
          placeholder={TEXT_LOGIN_ID}
          value={email}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            setEmail(e.target.value)
          }}/>
      </div>
      <div className='login_page_input_container'>
        <IconLocked/>
        <input
          type="password"
          className='login_page_input'
          placeholder={TEXT_PASSWORD}
          value={password}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            setPassword(e.target.value)
          }}/>
      </div>
      {errorMessage && <div className='login_page_error'>{errorMessage}</div>}
      <div className='login_page_checkbox_container'>
        <div
          onClick={onButtonAutoLoginClick}
          className='login_page_checkbox'>
          {autoLogin && <FontAwesomeIcon icon={faCheck}/>}
        </div>
        <label 
        className='login_page_checkbox_text'
        onClick={onButtonAutoLoginClick}>
          {TEXT_SAVE_LOGIN}
        </label>

      </div>
      <button
        className='login_page_button_login'
        onClick={login}>
        {TEXT_BUTTON_LOGIN}
      </button>
      <label
        onClick={() => {setErrorMessage(''); setForgotPassword(true)}}
        className='login_page_box_bottom_text'>
        {TEXT_FORGOT_PASSWORD_LINK}
      </label>
    </>
  }

  const renderResetPasswordContent = () => {
    return <form onSubmit={handleSubmit(handleForgotPassword)} className='forgot_password_form'>
      <label className='login_page_label_reset_password'>
        {!showPasswordInput? TEXT_FORGOT_PASSWORD_INSTRUCTION: TEXT_FORGOT_PASSWORD_INPUT_CODE}
      </label>
      <div className='login_page_input_container'>
        <IconEnvelop/>
        <input
          type='text'
          autoComplete="noop"
          className='login_page_input'
          placeholder={TEXT_LOGIN_ID}
          {...register('email')}
        />
      </div>

      {showPasswordInput && <>
        <div className='login_page_input_container'>
          <input
            type='text'
            autoComplete="noop"
            className='login_page_input'
            placeholder='認証コード'
            {...register('code')}
          />
          {countDown || <IconResend className='pointer' onClick={sendVerificationCode}/>}
        </div>
        <div className='login_page_input_container'>
          <input
            type='password'
            autoComplete="noop"
            className='login_page_input'
            placeholder={TEXT_NEW_PASSWORD}
            {...register('password')}
          />
        </div>
        <div className='login_page_input_container'>
          <input
            type='password'
            autoComplete="noop"
            className='login_page_input'
            placeholder={TEXT_CONFIRM_NEW_PASSWORD}
            {...register('confirm_password')}
          />
        </div>
      </>}
      {errorMessage && <div className='login_page_error'>{errorMessage}</div>}
      <button
        className='login_page_button_login'>
        { TEXT_BUTTON_SEND}
      </button>
      <label
        onClick={() => {setErrorMessage('');setForgotPassword(false)}}
        className='login_page_box_bottom_text'>
        {TEXT_BUTTON_BACKWARD}
      </label>
    </form>
  }

  /*** Event Handlers ***/
  const onKeyDown = async (e) => {
    if (e.key==='Enter') {
      await login();
    }
  }

  const errorHandle = (error) => {
    switch (error) {
      case 'User is not confirmed.':
        setErrorMessage(TEXT_ERROR_USER_NOT_CONFIRMED);
        break;
      default:
        setErrorMessage(TEXT_ERROR_INCORRECT_PASSWORD);
        break;
    }
  }

  const login = async () => {
    setLoading(true);
    const response = await loginCognitoUser(email, password);
    if (response.code === 1) navigate('/top');
    else errorHandle(response.message);
    setLoading(false);
  }

  const sendVerificationCode = async () => {
    if (isCodeSent) return;
    setLoading(true);
    const res = await sendVerificationCodeForgotPassword(getValues('email'));
    
    setLoading(false);
    setIsCodeSent(true);
    setShowPasswordInput(true);
  }

  const handleForgotPassword = async (data) => {
    setLoading(true);
    if (!showPasswordInput) {
      await sendVerificationCode();
    }
    if (data.code) {
      if (data.password.length<8) {
        setErrorMessage(DIALOG_ERROR_USE_SHORT_KEYWORD);
        setLoading(false);
        return;
      }
      else if (data.password!==data.confirm_password){
        setErrorMessage(DIALOG_ERROR_INVALID_CONFIRM_PASSWORD);
        setLoading(false);
        return;
      }
      await submitNewPasswordWithVerificationCode(data.email, data.code, data.password);
      setForgotPassword(false);
      setErrorMessage('');
    }
    setLoading(false);
  }

  const onButtonAutoLoginClick = () => {
    localStorage.setItem('AUTO_LOGIN', !autoLogin);
    setAutoLogin(!autoLogin);
  }

  /*** Main Render ***/
  return <div className='login_page_container'>
    <div className='login_page_upper_background'/>
    <div className='login_page_content_container'>
      <img
        className='login_page_logo'
        src={logoImage}/>
      <div className='login_page_content_box'>
        <label className='login_page_box_title'>
          {generalSetting?.appTitle}
        </label>
        {!forgotPassword ? renderLoginContent() : renderResetPasswordContent()}
      </div>
    </div>
    {loading && <FanLoadingIcon size={80} />}
  </div>;
}
LoginPage.propTypes = propTypes;
LoginPage.defaultProps = defaultProps;
export default LoginPage;