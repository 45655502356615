import Icon, { IconList } from 'components/Icon'
import { TEXT_CREATE_NEW, TEXT_USER_MANAGEMENT } from 'constants/message'
import './UserListHeader.css';
import PropTypes from 'prop-types';
import SearchBox from 'components/SearchBox';

const UserListHeader = ({ setModifyingUser, keyword, onSearch }) => {
    return (
        <div className='user-list-header-flex-row user-list_header'>
                <div className='user-list-header-flex-row user-header-title'>
                    <Icon icon={IconList.iconListing} />
                    <label className='header-text'>{TEXT_USER_MANAGEMENT}</label>
                </div>
                <div className='user-list-header-flex-row user-header-top-container'>
                    <div className='user_management_page_search_box_container'>
                    <SearchBox 
                        defaultKeyword={keyword}
                        onSearch={onSearch}
                    />
                    </div>
                    <button
                            onClick={() => {setModifyingUser({})}}
                            className='user_management_page_button_create button_active'>
                            {TEXT_CREATE_NEW}
                    </button>
                </div>
            
        </div>
    )
}

UserListHeader.propTypes = {
    setModifyingUser: PropTypes.func,
}

UserListHeader.defaultProps = {
    setModifyingUser: () => {},
}

export default UserListHeader;