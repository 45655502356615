// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AccountType = {
  "ADMIN": "ADMIN",
  "MANAGER": "MANAGER",
  "USER": "USER"
};

const LockState = {
  "NOLOCK": "NOLOCK",
  "LOCKED": "LOCKED",
  "UNLOCKED": "UNLOCKED"
};

const { User, Sensor, SensorGroup, SensorHistory, SensorChangeHistory, MutateCognitoUserOutput, SendMailOutput, GroupMessage } = initSchema(schema);

export {
  User,
  Sensor,
  SensorGroup,
  SensorHistory,
  SensorChangeHistory,
  AccountType,
  LockState,
  MutateCognitoUserOutput,
  SendMailOutput,
  GroupMessage
};