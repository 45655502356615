import React from 'react';
import PropTypes from 'prop-types';
import "./RangeSlider.css";

const propTypes={
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func
};
const defaultProps={
  min: 0,
  max: 10,
  step: 1,
  value: 0,
  onChange: (newValue) => {}
};
const RangeSlider = (props) => {
  /*** States and Variables ***/
  const progress = (props.value - props.min)/ (props.max - props.min);
  /*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  /*** Main Render ***/

  return <div className='range_slider_container'>
    <label className='range_slider_left_text'>
      {props.min}
    </label>
    <div className='range_slider-slider-container'>
      <input 
      className='range_slider'
      min={props.min}
      max={props.max}
      value={props.value}
      step={props.step}
      onChange={(e)=>{props.onChange(parseInt(e.target.value))}}
      type="range" />
      <div 
      className='range_slider_progress'
      style={{width: `calc(${progress * 100}% - ${8 * progress}px)`}} />
    </div>
    <label className='range_slider_right_text'>
      {props.max}
    </label>
  </div>;
}
RangeSlider.propTypes = propTypes;
RangeSlider.defaultProps = defaultProps;
export default RangeSlider;