import React from 'react';
import PropTypes from 'prop-types';
import "./SensorHistoryList.css";
import BaseList from '../../../components/BaseList';
import { ITEM_NUM_PER_PAGE, MAX_PAGE } from 'constants/config';
import { useSensorGroups } from 'context/SensorGroupsContext';
import { TEXT_DATA_RECEIVED_DATETIME, TEXT_STATUS, TEXT_VALUE } from 'constants/message';
import { ROLE } from 'utils/constanst';
import { TableHeader, TableRow } from './SensorTable';

const propTypes = {
  sensor: PropTypes.object,
  sensorHistories: PropTypes.array,
  curPage: PropTypes.number,
  onPageChange: PropTypes.func,
  itemNumPerPage: PropTypes.number
};
const defaultProps = {
  sensor: {},
  sensorHistories: [],
  curPage: 1,
  onPageChange: (newPage) => {},
  itemNumPerPage: ITEM_NUM_PER_PAGE
};
const SensorHistoryList = (props) => {
  /*** States and Variables ***/
  const { sensorGroups } = useSensorGroups();
  const targetGroup = sensorGroups.find(g=>g.id===props.sensor.groupId);
  /*** Processing ***/
  /*** Sub Components ***/
  const renderHeader = () => {
    return <>
      <div className='sensor_history_list_item'>
        <div className='sensor_history_list_date_cell'>
          {TEXT_DATA_RECEIVED_DATETIME}
        </div>
        <div className='sensor_history_list_status_text'>
          {TEXT_STATUS}
        </div>
        <div className='sensor_history_list_value_text'>
          {TEXT_VALUE}
        </div>
      </div>
      <div className='sensor_history_list_separator' />
    </>;
  }
  const renderItem = (item) => {
    const messageInfo = targetGroup?.messages && targetGroup.messages[item.status];
    return <>
      <div
        key={'item' + item.tx}
        className='sensor_history_list_item'>
        <div className='sensor_history_list_date_cell'>
          {item.tx}
        </div>
        <div className='sensor_history_list_status_cell'>
          <div 
          style={{backgroundColor: messageInfo?.color || '#000000'}}
          className='sensor_history_list_status'>
            {messageInfo?.status}
          </div>
        </div>
        <div className='sensor_history_list_value_cell' />
      </div>
      <div 
      key={'separator' + item.createdAt}
      className='sensor_history_list_separator'/>
    </>;
  }
  /*** Event Handlers ***/
  const isValidationColumn = (item) => {
    return item.role === ROLE.DETAIL;
  }
  /*** Main Render ***/
  return <BaseList
    items={props.sensorHistories}
    renderHeader={() => <TableHeader isValidationColumn={isValidationColumn}/>}
    renderItem={(item, i) => <TableRow 
                                  sensor={item} 
                                  key={i} 
                                  isValidationColumn={isValidationColumn}
                              />}
    curPage={props.curPage}
    onPageChange={props.onPageChange}
    itemNumPerPage={props.itemNumPerPage} 
    isNew={true}
    maxPage={MAX_PAGE}
    maxItems={MAX_PAGE * props.itemNumPerPage}
    />;
}
SensorHistoryList.propTypes = propTypes;
SensorHistoryList.defaultProps = defaultProps;
export default SensorHistoryList;