import React from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import "./ChangePasswordForm.css";
import { DIALOG_ERROR_INVALID_CONFIRM_PASSWORD, DIALOG_ERROR_USE_SHORT_KEYWORD, TEXT_BUTTON_UPDATE, TEXT_CANCEL, TEXT_CONFIRM_NEW_PASSWORD, TEXT_CURRENT_PASSWORD, TEXT_NEW_PASSWORD } from 'constants/message';
import { validateRequired } from 'utils/validator';
import { useDialog } from 'context/DialogContext';

const FORM_ITEMS = [
  {name: 'oldPassword', displayName: TEXT_CURRENT_PASSWORD,},
  {name: 'password', displayName: TEXT_NEW_PASSWORD},
  {name: 'confirmPassword', displayName: TEXT_CONFIRM_NEW_PASSWORD},
];
const propTypes={
  isManagementForm: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};
const defaultProps={
  isManagementForm: false,
  onCancel: ()=>{},
  onSubmit: (currentPassword, newPassword) => {}
};
const ChangePasswordForm = (props) => {
  /*** States and Variables ***/
  const {
    register, handleSubmit, formState: {errors}, watch
  } = useForm();
  const {error} = useDialog();
  /*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  const onSubmit = (data) => {
    if(data.password!==data.confirmPassword) {
      error(DIALOG_ERROR_INVALID_CONFIRM_PASSWORD);
      return;
    }
    if (data.password.length<8) {
      error(DIALOG_ERROR_USE_SHORT_KEYWORD);
      return;
    }
    props.onSubmit(data.oldPassword, data.password);
  }
  /*** Main Render ***/
  return <form 
  className='change_password_form'
  onSubmit={handleSubmit(onSubmit)}>
    {FORM_ITEMS.map(item=>{
      if (props.isManagementForm && item.name==='oldPassword') return null;
      return <>
        <div 
        key={item.name}
        className={'change_password_form_item' + (errors[item.name]? ' error': '')}>
          <label className='change_password_form_item_key'>
            {item.displayName}
          </label>
          <input 
          type="password"
          {...register(item.name, validateRequired(item.displayName))}/>
        </div>
        {errors[item.name] && 
        <label className='change_password_form_error'>{errors[item.name].message}</label>}
      </>
    })}
    <div className='change_password_form_buttons_container'>
      <button 
        className='change_password_form_button'>
        {TEXT_BUTTON_UPDATE}
      </button>
      <button 
      className='change_password_form_button_cancel'
      type='button'
      onClick={props.onCancel}>
        {TEXT_CANCEL}
      </button>
    </div>
  </form>;
}
ChangePasswordForm.propTypes = propTypes;
ChangePasswordForm.defaultProps = defaultProps;
export default ChangePasswordForm;