import './SensorDetailHeader.css';
import PropTypes from 'prop-types';
import Icon, { IconList } from 'components/Icon';
import { TEXT_SENSOR_LIST } from 'constants/message';
import { LockState } from 'models';
import { useGeneralSetting } from 'context/GeneralSettingContext';
import { useSensorGroups } from 'context/SensorGroupsContext';
import { SENSOR_STATUS_ALERT } from 'constants/config';


const IconSensor = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="55" viewBox="0 0 51 55">
            <g id="icon-sensor-caution" transform="translate(-210.5 -273.5)">
                <path id="パス_24" data-name="パス 24" d="M240.95,274H220.332A9.332,9.332,0,0,0,211,283.332V303.95a9.332,9.332,0,0,0,9.332,9.332h5.773l3.115,5.4a1.64,1.64,0,0,0,2.841,0l3.115-5.4h5.773a9.332,9.332,0,0,0,9.332-9.332V283.332A9.332,9.332,0,0,0,240.95,274Z" fill="#fff" stroke={color} stroke-width="1"/>
                <path id="パス_25" data-name="パス 25" d="M240.95,274H220.332A9.332,9.332,0,0,0,211,283.332V303.95a9.332,9.332,0,0,0,9.332,9.332h5.773l3.115,5.4a1.64,1.64,0,0,0,2.841,0l3.115-5.4h5.773a9.332,9.332,0,0,0,9.332-9.332V283.332A9.332,9.332,0,0,0,240.95,274Zm5.077,29.95a5.082,5.082,0,0,1-5.077,5.077H220.332a5.082,5.082,0,0,1-5.077-5.077V283.332a5.082,5.082,0,0,1,5.077-5.077H240.95a5.082,5.082,0,0,1,5.077,5.077Z" fill={color}/>
                <path id="パス_26" data-name="パス 26" d="M239.884,312.37a7.857,7.857,0,0,0,0,11.111l1.852-1.852a5.244,5.244,0,0,1,0-7.407Zm-1.389-1.389-1.852-1.852a12.439,12.439,0,0,0,0,17.592l1.852-1.852A9.831,9.831,0,0,1,238.5,310.982Zm12.5,1.389-1.852,1.852a5.244,5.244,0,0,1,0,7.407l1.852,1.852A7.857,7.857,0,0,0,250.995,312.37Zm-5.555,3.591a1.964,1.964,0,1,0,1.964,1.964A1.964,1.964,0,0,0,245.439,315.962Zm8.8-6.832-1.852,1.852a9.831,9.831,0,0,1,0,13.888l1.852,1.852A12.439,12.439,0,0,0,254.235,309.13Z" transform="translate(-14.798 -23.63)" fill={color}/>
            </g>
        </svg>
    )
}

const SensorDetailHeader = ({ sensor, onFocusItem, onSendMail, handleComeback }) => {
    const { generalSetting } = useGeneralSetting();
    const { sensorGroups } = useSensorGroups();
    const targetGroup = sensorGroups.find(g=>g.id===sensor.groupId);
    const alertMessage = targetGroup?.messages && targetGroup.messages.find(m=>m.value===SENSOR_STATUS_ALERT);
    // console.log("VANVIET 1", sensor, targetGroup);
    return (
        <div className='sensor-detail_flex-row sensor-detail_header'>
            <div className='sensor-detail_flex-row sensor-detail_header-title'>
                <div className='sensor-detail_flex-row header-title-symbol' onClick={handleComeback}>
                    <Icon icon={IconList.iconListingDetail} />
                    <label className='header-title-text'>{TEXT_SENSOR_LIST}</label>
                </div>
                <Icon icon={IconList.iconAllowDetail} />
            </div>
            <div className='sensor-detail_flex-row sensor-detail_header-sensor'>
                <IconSensor color={targetGroup.messages[sensor.status].color || '#0A3D71'} />
                {sensor.isLocked===LockState.LOCKED &&
                generalSetting.alertOn && <>
                  &nbsp;
                  <label className={'card-info_value-text'} 
                  style={{ background: alertMessage?.color, 
                  minWidth:'unset', 
                  padding: '4px 8px' }}>
                    警報ラッチ
                  </label>
                </>}
                <label className='sensor-name'>{sensor.name}</label>
            </div>
            <div className='sensor-detail_flex-row sensor-detail_header-function'>
                <button className='sensor-detail_flex-row sensor-detail_function-btn' onClick={() => onSendMail(sensor)}>
                    <Icon icon={IconList.iconMailDetail} />
                    <label style={{ color: '#1d679d' }}>メール送信</label>
                </button>
                <button className='sensor-detail_flex-row sensor-detail_function-btn' onClick={() => onFocusItem(sensor)}>
                    <Icon icon={IconList.iconMapDetail}/>
                    <label style={{ color: '#1d679d' }}>マップ確認</label>
                </button>
            </div>
        </div>
    )
}

SensorDetailHeader.propTypes = {
    sensor: PropTypes.object,
    onFocusItem: PropTypes.func,
    onSendMail: PropTypes.func,
    setSelectedSensor: PropTypes.func,
    setSensorDetail: PropTypes.func,
}

SensorDetailHeader.defaultProps = {
    sensor: {},
    onFocusItem: () => {},
    onSendMail: () => {},
    setSelectedSensor: () => {},
    setSensorDetail: () => {},
}

export default SensorDetailHeader;