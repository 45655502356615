import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SensorDetailList from './components/SensorDetailList';
import "./SensorListPage.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import ModifySensorForm from 'pages/SensorList/components/ModifySensorForm';
import { apiCreateSensor, apiDeleteSensor, apiUpdateSensor } from 'apis/SensorApi';
import { useDialog } from 'context/DialogContext';
import { DIALOG_ERROR_GENERAL_ERROR_MESSAGE, DIALOG_ERROR_INVALID_STATUS_TO_SEND_MAIL, DIALOG_ERROR_USE_UPPERCASE_ELTRES_ID, DIALOG_MESSAGE_CONFIRM_DELETE_SENSOR, DIALOG_MESSAGE_CONFIRM_SEND_MESSAGE, DIALOG_MESSAGE_CREATED_SENSOR_SUCCESSFULLY, DIALOG_MESSAGE_DELETED_SENSOR_SUCCESSFULLY, DIALOG_MESSAGE_SENT_MESSAGE_SUCCESSFULLY, DIALOG_MESSAGE_UPDATED_SENSOR_SUCCESSFULLY, TEXT_BUTTON_BACK, TEXT_CREATE_NEW, TEXT_CREATE_SENSOR, TEXT_DOWNLOAD_HISTORY, TEXT_ERROR_SENSOR_ALREADY_EXISTED, TEXT_SENSOR_DETAIL, TEXT_SENSOR_LIST, TEXT_SENSOR_MANAGEMENT, TEXT_UPDATE_SENSOR } from 'constants/message';
import SensorDetailView from './components/SensorDetailView';
import SearchBox from 'components/SearchBox';
import { standardizeString } from 'utils/function';
import { useSensorGroups } from 'context/SensorGroupsContext';
import FanLoadingIcon from 'components/FanLoadingIcon';
import { apiListSensors } from 'apis/MapApi';
import { apiSendMail } from 'apis/MailApi';
import { CURRENT_PAGE_DEFAULT, SENSOR_STATUS_ABNORMAL, SENSOR_STATUS_ALERT, SENSOR_STATUS_ATTENTION, SENSOR_STATUS_NORMAL } from 'constants/config';
import HistoryPage from 'pages/History/HistoryPage';
import SensorListHeader from './components/SensorListHeader';
import SensorDetailHeader from './components/SensorDetailHeader';
import DetailHeader from 'components/DetailHeader'
import { PAGE_SENSOR_LIST } from 'utils/constanst';

const propTypes = {
  isManagementPage: PropTypes.bool,
  onFocusOnSensor: PropTypes.func,
  sensorDetail: PropTypes.object,
  setSensorDetail: PropTypes.func,
  handleModifiedSensors: PropTypes.func,
  allSensors: PropTypes.array,
};

const defaultProps = {
  isManagementPage: false,
  onFocusOnSensor: (sensor) => {},
  sensorDetail: null,
  setSensorDetail: (sensor) => {},
  handleModifiedSensors: () => {},
  allSensors: [],
};

const SensorListPage = (props) => {
  /*** States and Variables ***/
  // const [allSensors, setAllSensors] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');
  const [searchedSensors, setSearchSensors] = React.useState([]);
  const [selectedSensor, setSelectedSensor] = React.useState(null);
  const { error, confirm, success, warningConfirm } = useDialog();
  const { sensorGroups } = useSensorGroups();
  const [loading, setLoading] = React.useState(false);
  const [curPage, setCurPage] = React.useState(CURRENT_PAGE_DEFAULT);
  const [history, setHistory] = React.useState(false);

  const onViewSensor = (item) => {
    setSelectedSensor({
      mode: 'view',
      item
    });
  }
  
  useEffect(() => {
    if (props.sensorDetail) {
      onViewSensor(props.sensorDetail);
    }
  }, [])

  /*** Processing ***/
  React.useEffect(() => {
    props.downloadSensors();
  }, [props.page])

  React.useEffect(()=>{
    return () => {
      setSelectedSensor(null);
      props.setSensorDetail(null);
    }
  }, [props.isManagementPage])

  React.useEffect(() => {
    setCurPage(1);
  }, [searchedSensors])

  /**
   * call this function after creating, modifying, deleting a sensor
   */
  const validateSearchedSensors = (sensors) => {
    if (searchedSensors) {
      let keywords = standardizeString(keyword).replaceAll(/[、､ 　]/g,",");
      keywords = keywords.split(",");
      keywords = keywords.map(keyword=>keyword.trim());
      keywords = keywords.filter(keyword=>keyword!=="");
      setSearchSensors(searchSensors(sensors, keywords));
    }
  }

  React.useEffect(() => {
    validateSearchedSensors(props.allSensors);
  }, [props.allSensors]);

  const searchSensors = (sensors, keywords) => {
    const searchResult = [];
    if (props.isManagementPage) {
      for (const sensor of sensors) {
        const sensorInfo = standardizeString(sensor.managementId) + ' ' +
        standardizeString(sensor.id) + ' ' + 
        standardizeString(sensor.apiGroupId) + ' ' + 
        standardizeString(sensor.name) + ' ' + 
        standardizeString(sensor.location) + ' ' + 
        standardizeString(sensor.lat.toString()) + ' ' + 
        standardizeString(sensor.lng.toString()) + ' ' + 
        standardizeString(sensor.createdAt.split(' ')[0]);
        let accepted = true;
        for (const keyword of keywords) {
          if (!sensorInfo.includes(keyword)) {
            accepted = false;
            break;
          }
        }
        if (accepted) searchResult.push(sensor);
      }
    }
    else {
      for (const sensor of sensors) {
        const targetGroup = sensorGroups.find(g => g.id === sensor.groupId);
        const  messageInfo = targetGroup.messages && targetGroup.messages[sensor.status];
        const sensorInfo = standardizeString(sensor.managementId) + ' ' + 
        standardizeString(sensor.name) + ' ' + 
        standardizeString(sensor.location) + ' ' + 
        standardizeString(sensor.tx) + ' ' + 
        (messageInfo? standardizeString(messageInfo.status): '') + ' ' + 
        (messageInfo? standardizeString(messageInfo.message): '') + ' ';
        let accepted = true;
        for (const keyword of keywords) {
          if (!sensorInfo.includes(keyword)) {
            accepted = false;
            break;
          }
        }
        if (accepted) searchResult.push(sensor);
      }
    }
    return searchResult;
  }
  /*** Sub Components ***/
  const renderPath = () => {
    return <div className='sensor_list_page_path'>
      <div className='sensor_list_page_path_container'>
        <label
          className='sensor_list_page_path_item'
          onClick={() => {
            setSelectedSensor(null)
          }}>
          {props.isManagementPage?TEXT_SENSOR_MANAGEMENT: TEXT_SENSOR_LIST}
        </label>
        {selectedSensor ?
          <>
            &nbsp;
            <FontAwesomeIcon icon={faChevronRight}/>
            &nbsp;
            <label className='sensor_list_page_path_item'>
              {props.isManagementPage? (selectedSensor?.item?.id? TEXT_UPDATE_SENSOR: TEXT_CREATE_SENSOR): TEXT_SENSOR_DETAIL}
            </label>
          </> : null}
      </div>
      {!props.isManagementPage && selectedSensor && <button 
      className='button_cancel'
      onClick={()=>{setSelectedSensor(null)}}>
        {TEXT_BUTTON_BACK}
      </button>}
    </div>
  }
  const renderDetailList = () => {
    return <>
      <div className='sensor_list_page_list_action_container'>
        <div className='sensor_list_page_search_box_container'>
          <SearchBox 
          defaultKeyword={keyword}
          onSearch={onSearchSensors}/>
        </div>
        <div className='sensor_list_page_right_head_buttons_container'>
          <button 
          className='sensor_list_page_button_create button_active'
          onClick={()=>{setHistory(true)}}>
            {TEXT_DOWNLOAD_HISTORY}
          </button>
          {props.isManagementPage && <>
            <button 
            className='sensor_list_page_button_create button_active'
            onClick={()=>{
              setSelectedSensor({
                mode: 'modify',
                item: {}
              })
            }}>
              {TEXT_CREATE_NEW}
            </button>
          </>}
        </div>
      </div>
      <div className='sensor_list_page_detail_list_container'>
        <SensorDetailList
          items={searchedSensors || props.allSensors}
          isManagementList={props.isManagementPage}
          curPage={curPage}
          onEditItem={onEditSensor}
          onViewItem={onViewSensor}
          onDeleteItem={onDeleteSensor}
          onFocusItem={props.onFocusOnSensor}
          onSendMail={onSendMail}
          onPageChange={setCurPage} />
      </div>
    </>
  }
  const renderDetailView = (item) => {
    return <SensorDetailView 
      sensor={item}
      onButtonMapClick={()=>{props.onFocusOnSensor(item)}}
      setSelectedSensor={setSelectedSensor}
      setSensorDetail={props.setSensorDetail}
    />;
  }
  const renderModifyForm = () => {
    return <div className='sensor_list_page_modify_form_container'>
      <ModifySensorForm 
      sensor={selectedSensor.item} 
      onModify={onModifySensor}
      onCancel={()=>{
        setSelectedSensor(null);
        props.setSensorDetail(null);
      }}/>
    </div>;
  }
  /*** Event Handlers ***/
  const onEditSensor = (item) => {
    setSelectedSensor({
      mode: 'modify',
      item
    });
  }
  
  const onDeleteSensor = (item) => {
    if (loading) return;
    warningConfirm(
      DIALOG_MESSAGE_CONFIRM_DELETE_SENSOR, 
      async () => {
        setLoading(true);
        const result = await apiDeleteSensor(item.id);
        setLoading(false);
        if (result.success) {
          success(DIALOG_MESSAGE_DELETED_SENSOR_SUCCESSFULLY);
          props.handleModifiedSensors(result.data, 'delete');
          // allSensors.splice(allSensors.indexOf(item), 1);
          // validateSearchedSensors(props.allSensors);
          // setAllSensors(Object.assign([], allSensors));
        }
        else {
          error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
        }
      });
  }

  const onModifySensor = async (newSensor) => {
    if (newSensor.id.toLowerCase()!==newSensor.id) {
      error(DIALOG_ERROR_USE_UPPERCASE_ELTRES_ID);
      return;
    }
    if (loading) return;
    setLoading(true);
    const result = selectedSensor.item.id? await apiUpdateSensor(newSensor): await apiCreateSensor(newSensor);
    setLoading(false);
    if (result.success) {
      if (selectedSensor.item.id){
        success(DIALOG_MESSAGE_UPDATED_SENSOR_SUCCESSFULLY);
        props.handleModifiedSensors(result.data, 'update')
        // const pos = allSensors.indexOf(selectedSensor.item);
        // allSensors.splice(pos, 1, result.data);
      }
      else {
        success(DIALOG_MESSAGE_CREATED_SENSOR_SUCCESSFULLY);
        props.handleModifiedSensors(result.data, 'add')
        // allSensors.push(result.data);
        // allSensors.sort((s1,s2)=>s1.managementId.localeCompare(s2.managementId));
      }
      // validateSearchedSensors(props.allSensors);
      // setAllSensors(Object.assign([], props.allSensors));
      setSelectedSensor(null);
    }
    else {
      error(result.error===TEXT_ERROR_SENSOR_ALREADY_EXISTED? TEXT_ERROR_SENSOR_ALREADY_EXISTED: DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
    }
  }

  const onSearchSensors = (originalKeyword, keywords) => {
    setKeyword(originalKeyword);
    if (!keywords.length) {
      setSearchSensors(null);
      return;
    }
    setSearchSensors(searchSensors(props.allSensors, keywords));
    setCurPage(CURRENT_PAGE_DEFAULT);
  }

  const onSendMail = async (sensor) => {
    if (loading) return;
    const statusValue = sensor.status;
    if (statusValue === -1) {
      error(DIALOG_ERROR_INVALID_STATUS_TO_SEND_MAIL);
      return;
    }
    confirm(DIALOG_MESSAGE_CONFIRM_SEND_MESSAGE, async ()=>{
      setLoading(true);
      const targetGroup = sensorGroups.find(g=>g.id===sensor.groupId);
      const sensorStatusValue = targetGroup.messages[sensor.status].value;
      const status = (sensorStatusValue===SENSOR_STATUS_NORMAL && SENSOR_STATUS_NORMAL) || (sensorStatusValue===SENSOR_STATUS_ALERT && SENSOR_STATUS_ALERT) || (sensorStatusValue===SENSOR_STATUS_ATTENTION && SENSOR_STATUS_ATTENTION) || SENSOR_STATUS_ABNORMAL;
      const subject = status!==SENSOR_STATUS_ABNORMAL? `【${status}】冠水警報システム ${sensor.name} ${status}レベル水位`: `【${status}】冠水警報システム ${sensor.name} ${status}発生`;
      const text = status!==SENSOR_STATUS_ABNORMAL? `${sensor.name}の水位が${status}レベルになりました。`: `${sensor.name}の機器で${status}が発生しました。`;
      const res = await apiSendMail(subject, text);
      setLoading(false);
      if (res.success) {
        success(DIALOG_MESSAGE_SENT_MESSAGE_SUCCESSFULLY);
      }
      else {
        error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
      }
    });
  }

  const handleComeback = () => {
    setSelectedSensor(null);
    props.setSensorDetail(null);
  }

  /*** Main Render ***/
  if (history) {
    return <HistoryPage 
              prevPage={props.isManagementPage?TEXT_SENSOR_MANAGEMENT: TEXT_SENSOR_LIST}
              onClose={()=>{setHistory(false)}}
            />
  }

  return (
    <div className='sensor_list_page_container'>
      {
        selectedSensor ?
        (
          selectedSensor.mode==='modify' ?
          <>
            {/* {renderPath()} */}
            <DetailHeader
              mainLabel={props.isManagementPage ? TEXT_SENSOR_MANAGEMENT : TEXT_SENSOR_LIST}
              subLabel={selectedSensor.item?.id ? TEXT_UPDATE_SENSOR : TEXT_CREATE_SENSOR}
              onClick={handleComeback}
            />
            {renderModifyForm()}
          </> :
          <>
            <SensorDetailHeader 
              sensor={selectedSensor.item} 
              onFocusItem={props.onFocusOnSensor} 
              onSendMail={onSendMail} 
              handleComeback={handleComeback}
            />
            {renderDetailView(selectedSensor.item)}
          </>
        ) :
        <>
          <SensorListHeader
            initSensors={props.allSensors} 
            setSensorList={setSearchSensors} 
            isManagementPage={props.isManagementPage}
            setCurPage={setCurPage}
          />
          <div className='sensor_list_page_detail_list_container'>
          <SensorDetailList
            items={searchedSensors}
            isManagementList={props.isManagementPage}
            curPage={curPage}
            onEditItem={onEditSensor}
            onViewItem={onViewSensor}
            onDeleteItem={onDeleteSensor}
            onFocusItem={props.onFocusOnSensor}
            onSendMail={onSendMail}
            onPageChange={setCurPage}
            functionBtn={() => <FunctionButton
                              setHistory={setHistory}
                              isManagementPage={props.isManagementPage}
                              setSelectedSensor={setSelectedSensor}
                            />}
            />
          </div>
        </>
      }
      {loading && <FanLoadingIcon size={80}/>}
    </div>
  )
      
}

const FunctionButton = ({ setHistory, isManagementPage, setSelectedSensor }) => {

  return (
    <div className='sensor_list_page_right_head_buttons_container'>
      <button 
        className='sensor_list_page_button_create button_active'
        onClick={()=>{setHistory(true)}}
      >
        {TEXT_DOWNLOAD_HISTORY}
      </button>
      {
        isManagementPage && 
        <>
          <button 
            className='sensor_list_page_button_create button_active'
            onClick={()=>
              setSelectedSensor({
                mode: 'modify',
                item: {}
              })
            }
          >
              {TEXT_CREATE_NEW}
          </button>
        </>}
    </div>
  )
}
FunctionButton.propTypes = {
  setHistory: PropTypes.func,
  isManagementPage: PropTypes.bool,
  setSelectedSensor: PropTypes.func,
  page: PropTypes.number,
}
FunctionButton.defaultProps = {
  setHistory: () => {},
  isManagementList: false,
  setSelectedSensor: () => {},
  page: PAGE_SENSOR_LIST,
}

SensorListPage.propTypes = propTypes;
SensorListPage.defaultProps = defaultProps;
export default SensorListPage;