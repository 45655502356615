export const TEXT_BUTTON_CREATE = '作成';
export const TEXT_BUTTON_UPDATE = '更新';
export const TEXT_BUTTON_SEND = '送信';
export const TEXT_BUTTON_BACKWARD = '前へ戻る';
export const TEXT_BUTTON_BACK = '戻る';
export const TEXT_BUTTON_LOGIN = 'ログイン';

// Create User Form And Profile
export const TEXT_USER_NAME = 'ユーザー名';
export const TEXT_LOGIN_ID = 'ユーザーID';
export const TEXT_PASSWORD = 'パスワード';
export const TEXT_ACCOUNT_TYPE = '権限';
export const TEXT_CONTACT = '通知用メールアドレス';
export const TEXT_PHONE = '連絡先';
export const TEXT_DESCRIPTION = '備考';
export const TEXT_CURRENT_PASSWORD = '現在のパスワード';
export const TEXT_NEW_PASSWORD = '新しいパスワード';
export const TEXT_CONFIRM_PASSWORD = 'パスワード(確認用)';
export const TEXT_CONFIRM_NEW_PASSWORD = '新しいパスワード(確認用)';
export const TEXT_DOWNLOAD_HISTORY = '履歴ダウンロード';

// Login And Forgot Password Form
export const TEXT_FORGOT_PASSWORD_LINK = 'パスワードを忘れた方はこちら';
export const TEXT_FORGOT_PASSWORD_INSTRUCTION = 'ユーザー登録で使用したメールアドレスを入力してください。 パスワード再設定のメールを送信します。';
export const TEXT_FORGOT_PASSWORD_INPUT_CODE = 'メールを送信しました。メール本文に記載されている認証コードを入力し、新しいパスワードを設定してください。';
// Error
export const TEXT_ERROR_NAME_REQUIRED = 'Name is required!';
export const TEXT_ERROR_PASSWORD_REQUIRED = 'Password is required';
export const TEXT_ERROR_PASSWORD_INVALID = 'Password must have at least 8 characters, contain letter and number';
export const TEXT_ERROR_PASSWORD_NOT_MATCH = 'パスワードが一致していません。';
export const TEXT_ERROR_CONFIRM_PASSWORD_REQUIRED = 'パスワード(確認用)を設定していません。';
export const TEXT_ERROR_EMAIL_REQUIRED = 'Email is required!';
export const TEXT_ERROR_NULL = 'を設定していません。';
export const TEXT_ERROR_EMAIL_INVALID = 'Email is invalid!';
export const TEXT_ERROR_EMAIL_EXIST = '既に存在しているユーザーIDです。';
export const TEXT_ERROR_USER_NOT_CONFIRMED = 'User is not confirmed';
export const TEXT_ERROR_INCORRECT_PASSWORD = 'ユーザーIDまたはパスワードが正しくありません。';
export const TEXT_ERROR_SENSOR_ALREADY_EXISTED = '既に登録されているセンサ（ELTRES ID）のため新規作成できません。';
// Dialog Message
export const DIALOG_MESSAGE_CONFIRM_DELETE_USER = "ユーザーを削除してもよろしいですか？";
export const DIALOG_MESSAGE_CONFIRM_DELETE_GROUP = "センサグループを削除してもよろしいですか？";
export const DIALOG_MESSAGE_CONFIRM_DELETE_SENSOR = "センサを削除してもよろしいですか？";
export const DIALOG_MESSAGE_CONFIRM_SEND_MESSAGE = "メールを送信してもよろしいですか？";
export const DIALOG_MESSAGE_CREATED_UDER_SUCCESSFULLY = "ユーザーを正常に作成しました。";
export const DIALOG_MESSAGE_CREATED_GROUP_SUCCESSFULLY = "グループを正常に作成しました。";
export const DIALOG_MESSAGE_CREATED_SENSOR_SUCCESSFULLY = "センサを正常に作成しました。";
export const DIALOG_MESSAGE_UPDATED_UDER_SUCCESSFULLY = "ユーザーを正常に更新しました。";
export const DIALOG_MESSAGE_UPDATED_GROUP_SUCCESSFULLY = "グループを正常に更新しました。";
export const DIALOG_MESSAGE_UPDATED_SENSOR_SUCCESSFULLY = "センサを正常に更新しました。";
export const DIALOG_MESSAGE_DELETED_UDER_SUCCESSFULLY = "ユーザーを正常に削除しました。";
export const DIALOG_MESSAGE_DELETED_GROUP_SUCCESSFULLY = "グループを正常に削除しました。";
export const DIALOG_MESSAGE_DELETED_SENSOR_SUCCESSFULLY = "センサを正常に削除しました。";
export const DIALOG_MESSAGE_CHANGED_PASSWORD_SUCCESSFULLY = "パスワードを正常に変更しました。";
export const DIALOG_MESSAGE_SENT_MESSAGE_SUCCESSFULLY = "メールを正常に送信しました。";
export const DIALOG_MESSAGE_UPDATED_GENERAL_SETTING_SUCCESSFULLY = "一般設定を正常に更新しました。";

// Dialog Error
export const DIALOG_ERROR_GENERAL_ERROR_MESSAGE = 'エラーが発生しました。やり直してください。';
export const DIALOG_ERROR_INVALID_CONFIRM_PASSWORD = "パスワードが一致していません。"
export const DIALOG_ERROR_INVALID_SCAN_TIME = "センサ受信間隔は不正です。"
export const DIALOG_ERROR_INVALID_CONFIRM_TIME = "異常検知時間は不正です。"
export const DIALOG_ERROR_INVALID_LAT = "緯度は不正です。"
export const DIALOG_ERROR_INVALID_LNG = "経度は不正です。"
export const DIALOG_ERROR_CONFIRM_TIME_LESS_THAN_SCAN_TIME = "異常検知時間はセンサ受信間隔より短いです。"
export const DIALOG_ERROR_INVALID_STATUS_TO_SEND_MAIL = "センサ情報が取得できていないため、メールを送信できません。"
export const DIALOG_ERROR_USE_SHORT_KEYWORD = "パスワードは8桁以上で設定してください。"
export const DIALOG_ERROR_USE_UPPERCASE_ELTRES_ID = "ELTRES IDの英字は小文字で入力してください。";
export const DIALOG_ERROR_DOWNLOAD_MORE_THAN_A_YEAR_OF_HISTORY = "1年以内の期間を指定してください。";
// Left Menu
export const TEXT_SENSOR_MAP = 'センサマップ';
export const TEXT_SENSOR_LIST = 'センサ一覧';
export const TEXT_MANAGEMENT_SETTING = '管理設定';
export const TEXT_SENSOR_MANAGEMENT = 'センサ管理';
export const TEXT_USER_MANAGEMENT = 'ユーザー管理';
export const TEXT_MASTER_MANAGEMENT = 'マスタ管理';

// Properties
export const TEXT_NAME = '名称';
export const TEXT_GROUP_ID = 'グループID';
export const TEXT_ELTRES_ID = 'ELTRES ID';
export const TEXT_GROUP_NAME = 'グループ名';
export const TEXT_REGISTERED_DATETIME = '登録日時';
export const TEXT_REGISTERED_DATE = '登録日';
export const TEXT_DATA_RECEIVED_DATETIME = 'データ取得日時';
export const TEXT_LATITUDE = '緯度';
export const TEXT_LONGITUDE = '経度';
export const TEXT_ZOOM_RATE = 'ズーム率';
export const TEXT_MANAGEMENT_ID = '管理番号';
export const TEXT_STATUS = 'ステータス';
export const TEXT_MESSAGE = 'メッセージ';
export const TEXT_LOCATION = '設置場所';
export const TEXT_UPDATED_DATE = '更新日';
export const TEXT_MESSAGE_GROUP = 'メッセージグループ';
export const TEXT_VALUE = '値';

// Page Login
export const TEXT_SAVE_LOGIN = 'ログイン状態を保持する';
export const TEXT_BACK = '前へ戻る';
export const TEXT_SEND = '送信';

// Page Profile
export const TEXT_PROFILE = 'プロフィール';
export const TEXT_CHANGE_PASSWORD = 'パスワード変更';
export const TEXT_CANCEL = 'キャンセル';

// Page User Management
export const TEXT_CREATE_USER = 'ユーザー作成';
export const TEXT_UPDATE_USER = 'ユーザー更新';
export const TEXT_RESET_PASSWORD = 'パスワードリセット';
export const TEXT_CREATE_NEW = '新規作成';
// Page Sensor Management
export const TEXT_CREATE_SENSOR = 'センサ作成';
export const TEXT_UPDATE_SENSOR = 'センサ更新';
export const TEXT_SENSOR_DETAIL = 'センサ詳細';

// Page Master Management
export const TEXT_MESSAGE_MANAGEMENT = 'メッセージ設定';

// User
export const TEXT_ROLE_USER = '一般';
export const TEXT_ROLE_MANAGER = '管理者';
export const TEXT_ROLE_ADMIN = '管理者（岩崎）';