import React, {useEffect} from 'react';
import "./UserManagementPage.css";
import UserList from 'pages/UserManagement/components/UserList';
import ModifyUserForm from 'pages/UserManagement/components/ModifyUserForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {apiAdminChangeUserPassword, apiCreateUser, apiDeleteUser, apiListUsers, apiUpdateUser} from 'apis/UserApi';
import { DIALOG_ERROR_GENERAL_ERROR_MESSAGE, DIALOG_ERROR_INVALID_CONFIRM_PASSWORD, DIALOG_ERROR_USE_SHORT_KEYWORD, DIALOG_MESSAGE_CHANGED_PASSWORD_SUCCESSFULLY, DIALOG_MESSAGE_CONFIRM_DELETE_USER, DIALOG_MESSAGE_CREATED_UDER_SUCCESSFULLY, DIALOG_MESSAGE_DELETED_UDER_SUCCESSFULLY, DIALOG_MESSAGE_UPDATED_UDER_SUCCESSFULLY, TEXT_CREATE_NEW, TEXT_CREATE_USER, TEXT_UPDATE_USER, TEXT_USER_MANAGEMENT } from 'constants/message';
import { useDialog } from 'context/DialogContext';
import SearchBox from 'components/SearchBox';
import { standardizeString } from 'utils/function';
import FanLoadingIcon from 'components/FanLoadingIcon';
import ChangePasswordForm from 'pages/Profile/components/ChangePasswordForm';
import { useAuth } from 'context/AuthContext';
import { AccountType } from 'models';
import { CURRENT_PAGE_DEFAULT } from 'constants/config';
import UserListHeader from './components/UserListHeader';
import DetailHeader from 'components/DetailHeader';

const propTypes = {};
const defaultProps = {};
const UserManagementPage = (props) => {
  /*** States and Variables ***/
  const { currentUser } = useAuth();
  const [curPage, setCurPage] = React.useState(CURRENT_PAGE_DEFAULT);
  const [loading, setLoading] =  React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');
  const [searchedUsers, setSearchedUsers] = React.useState(null);
  const { error, success, warningConfirm } = useDialog();
  const [modifyingUser, setModifyingUser] = React.useState(null);
  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);
  /*** Processing ***/
  useEffect(() => {
    initUsers();
  }, [])

  const initUsers = async () => {
    const result = await apiListUsers();
    if (result.success) {
      if(currentUser.accountType===AccountType.ADMIN){
        setUsers(result.data);
      }
      else if (currentUser.accountType===AccountType.MANAGER){
        setUsers(result.data.filter(user=>user.accountType!==AccountType.ADMIN));
      }
    }
    else {
      error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
    }
  }

  /**
   * call this function after creating, modifying, deleting an user
   */
  const validateSearchedUsers = (users) => {
    if (searchedUsers) {
      let keywords = standardizeString(keyword).replaceAll(/[、､ 　]/g,",");
      keywords = keywords.split(",");
      keywords = keywords.map(keyword=>keyword.trim());
      keywords = keywords.filter(keyword=>keyword!=="");
      setSearchedUsers(searchUsers(users, keywords));
    }
  }

  const searchUsers = (users, keywords) => {
    const searchResult = [];
    for(const user of users) {
      const userInfo = standardizeString(user.name) + ' ' + 
      standardizeString(user.email) + ' ' + 
      standardizeString(user.contact) + ' ' + 
      standardizeString(user.phone) + ' ' + 
      standardizeString(user.description) + ' ' +
      standardizeString(user.createdAt.split(' ')[0]);
      let accepted = true;
      for (const keyword of keywords) {
        if (!userInfo.includes(keyword)) {
          accepted = false;
          break;
        }
      }
      if (accepted) searchResult.push(user);
    }
    return searchResult;
  }

  /*** Sub Components ***/
  const renderPath = () => {
    return <div className='user_management_page_path'>
      <label
        onClick={() => {setModifyingUser(null)}}
        className='user_management_page_path_item'>
        {TEXT_USER_MANAGEMENT}
      </label>
      {modifyingUser ?
        <>
          &nbsp;<FontAwesomeIcon icon={faChevronRight}/>&nbsp;
          <label className='user_management_page_path_item'>
            {modifyingUser.id? TEXT_UPDATE_USER: TEXT_CREATE_USER}
          </label>
        </> : null}
    </div>;
  }
  const renderUserList = () => {
    return <>
      <div className='user_management_page_list_action_container'>
        {/* <div className='user_management_page_search_box_container'>
          <SearchBox 
          defaultKeyword={keyword}
          onSearch={onSearch}/>
        </div> */}
        {/* <button
          onClick={() => {setModifyingUser({})}}
          className='user_management_page_button_create button_active'>
          {TEXT_CREATE_NEW}
        </button> */}
      </div>
      <div className='user_management_page_list_container'>
        <UserList 
        items={searchedUsers || users}
        curPage={curPage}
        onEditItem={setModifyingUser}
        onDeleteItem={onDeleteUser}
        onPageChange={setCurPage}/>
      </div>
    </>
  }
  const renderUserModifyingForm = () => {
    if (changePasswordOpen) {
      return <ChangePasswordForm
      isManagementForm={true} 
      onCancel={()=>{setChangePasswordOpen(false)}}
      onSubmit={onChangePassword}/>
    }
    return <ModifyUserForm 
    user={modifyingUser}
    onCancel={()=>{setModifyingUser(null)}}
    onModifyUser={onModifyUser}
    onChangePassword={()=>{setChangePasswordOpen(true)}}/>;
  }
  /*** Event Handlers ***/
  const onDeleteUser = (user) => {
    if (loading) return;
    warningConfirm(
      DIALOG_MESSAGE_CONFIRM_DELETE_USER,
      async () => {
        setLoading(true);
        const result = await apiDeleteUser(user.id, user.email);
        setLoading(false);
        if (result.success) {
          success(DIALOG_MESSAGE_DELETED_UDER_SUCCESSFULLY);
          const newUsers = users.filter(user=>user.id!==result.data.id);
          validateSearchedUsers(newUsers);
          setUsers(newUsers);
        }
        else {
          error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
        }
      })
  }
  const onSearch = (originalKeyword, keywords) => {
    setKeyword(originalKeyword);
    if (!keywords.length) {
      setSearchedUsers(null);
      return;
    }
    setSearchedUsers(searchUsers(users, keywords));
    setCurPage(1);
  }
  const onModifyUser = async (userData) => {
    if (loading) return;
    if (userData.password!==userData.confirmPassword) {
      error(DIALOG_ERROR_INVALID_CONFIRM_PASSWORD);
      return ;
    }
    else if (userData.password && userData.password.length<8) {
      error(DIALOG_ERROR_USE_SHORT_KEYWORD);
      return ;
    }
    setLoading(true);
    if (!modifyingUser.id) {
      const createResult = await apiCreateUser(userData);
      if (createResult.success) {
        success(DIALOG_MESSAGE_CREATED_UDER_SUCCESSFULLY);
        users.push(createResult.data);
        validateSearchedUsers(users);
        setUsers(Object.assign([], users));
        setModifyingUser(null);
      }
      else {
        error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
      }
    }
    else {
      const updateResult = await apiUpdateUser({
        id: modifyingUser.id,
        name: userData.name,
        email: userData.email,
        accountType: userData.accountType,
        contact: userData.contact,
        phone: userData.phone,
        description: userData.description
      });
      console.log("VANVIET", updateResult)
      if (updateResult.success) {
        success(DIALOG_MESSAGE_UPDATED_UDER_SUCCESSFULLY);
        users.splice(users.indexOf(modifyingUser), 1, updateResult.data);
        validateSearchedUsers(users);
        setUsers(Object.assign([], users));
        setModifyingUser(null);
      }
      else {
        error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
      }
    }
    setLoading(false);
  }
  const onChangePassword = async (_, newPassword) => {
    if (loading) return;
    setLoading(true);
    const result = await apiAdminChangeUserPassword(modifyingUser.email, newPassword);
    if (result.success) {
      success(DIALOG_MESSAGE_CHANGED_PASSWORD_SUCCESSFULLY);
      setChangePasswordOpen(false);
    }
    else {
      error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
    }
    setLoading(false);
  }
  /*** Main Render ***/
  return <div className='user_management_page_container'>
    {
      modifyingUser ?
      <DetailHeader
        mainLabel={TEXT_USER_MANAGEMENT}
        subLabel={modifyingUser.id? TEXT_UPDATE_USER: TEXT_CREATE_USER}
        onClick={() => {setModifyingUser(null)}}
      /> :
      <UserListHeader
        setModifyingUser={setModifyingUser}
        keyword={keyword}
        onSearch={onSearch}
      />
    }
    
    {/* {renderPath()} */}
    {modifyingUser ? 
    renderUserModifyingForm() : 
    renderUserList()}
    {loading && <FanLoadingIcon size={80}/>}
  </div>;
}
UserManagementPage.propTypes = propTypes;
UserManagementPage.defaultProps = defaultProps;
export default UserManagementPage;