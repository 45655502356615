import './Icon.css';
import iconAllow from 'assets/svgicon/allow.svg';
import iconAllowDetail from 'assets/svgicon/icon-allow-detail.svg';
import iconArrowDown from 'assets/svgicon/icon-arrow-down.svg';
import iconArrowUp from 'assets/svgicon/icon-arrow-up.svg';
import iconCloseBlue from 'assets/svgicon/icon-close-blue.svg';
import iconClose from 'assets/svgicon/icon-close.svg';
import iconDelete from 'assets/svgicon/icon-delete.svg';
import iconUser from 'assets/svgicon/icon-icon.svg';
import iconListingDetail from 'assets/svgicon/icon-listing-detail.svg';
import iconListing from 'assets/svgicon/icon-listing.svg';
import iconMailBig from 'assets/svgicon/icon-mail-big.svg';
import iconMailDetail from 'assets/svgicon/icon-mail-detail.svg';
import iconMail from 'assets/svgicon/icon-mail.svg';
import iconMapBig from 'assets/svgicon/icon-map-big.svg';
import iconMapDetail from 'assets/svgicon/icon-map-detail.svg';
import iconMenuLogout from 'assets/svgicon/icon-menu-logout.svg';
import iconMenuMap from 'assets/svgicon/icon-menu-map.svg';
import iconMenuMaster from 'assets/svgicon/icon-menu-masteradmin.svg';
import iconMenuSensorDetail from 'assets/svgicon/icon-menu-sensordetail.svg';
import iconMenuSensorManage from 'assets/svgicon/icon-menu-sensormanage.svg';
import iconMenuUser from 'assets/svgicon/icon-menu-user.svg';
import iconPlus from 'assets/svgicon/icon-plus.svg';
import iconReload from 'assets/svgicon/icon-reroad.svg';
import iconReturn from 'assets/svgicon/icon-return.svg';
import iconSearch from 'assets/svgicon/icon-search.svg';
import iconSensor from 'assets/svgicon/icon-sensor-white.svg';
import iconSort from 'assets/svgicon/icon-sort.svg';
import PropTypes from 'prop-types'

export const IconList = {
    iconAllow,
    iconAllowDetail,
    iconArrowDown,
    iconArrowUp,
    iconCloseBlue,
    iconClose,
    iconDelete,
    iconUser,
    iconListingDetail,
    iconListing,
    iconMailBig,
    iconMailDetail,
    iconMail,
    iconMapBig,
    iconMapDetail,
    iconMenuLogout,
    iconMenuMap,
    iconMenuMaster,
    iconMenuSensorDetail,
    iconMenuSensorManage,
    iconMenuUser,
    iconPlus,
    iconReload,
    iconReturn,
    iconSearch,
    iconSensor,
    iconSort,
}

const Icon = ({ icon, iconStyle, containerStyle, onClick }) => {
    return <div className={`icon_flex_row ${containerStyle}`} onClick={onClick}>
        <img alt='icon' src={icon} className={iconStyle}/>
    </div>
}

Icon.protoTypes = {
    icon: PropTypes.any,
    iconStyle: PropTypes.string,
    containerStyle: PropTypes.string,
    onClick: PropTypes.func,
}

Icon.defaultProps = {
    iconName: IconList.iconAllow,
    iconStyle: '',
    containerStyle: '',
    onClick: () => {},
}

export default Icon;