import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { apiUpdateUser } from 'apis/UserApi';
import FanLoadingIcon from 'components/FanLoadingIcon';
import { useAuth } from 'context/AuthContext';
import "./ProfilePage.css";
import { DIALOG_ERROR_GENERAL_ERROR_MESSAGE, DIALOG_MESSAGE_CHANGED_PASSWORD_SUCCESSFULLY, DIALOG_MESSAGE_UPDATED_UDER_SUCCESSFULLY, TEXT_BUTTON_UPDATE, TEXT_CANCEL, TEXT_CHANGE_PASSWORD, TEXT_CONFIRM_NEW_PASSWORD, TEXT_CONTACT, TEXT_CURRENT_PASSWORD, TEXT_DESCRIPTION, TEXT_LOGIN_ID, TEXT_NEW_PASSWORD, TEXT_PASSWORD, TEXT_PHONE, TEXT_PROFILE, TEXT_USER_NAME } from 'constants/message';
import { useDialog } from 'context/DialogContext';
import ChangePasswordForm from './components/ChangePasswordForm';
import { Auth } from 'aws-amplify';
import { AccountType } from 'models';
import DetailHeader from 'components/DetailHeader';

const propTypes={};
const defaultProps={};
const ProfilePage = (props) => {
  /*** States and Variables ***/
  const [loading, setLoading] = React.useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = React.useState(false);
  const {currentUser, setCurrentUser} = useAuth();
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [description, setDrescription] = React.useState('');
  const { error, success } = useDialog();
  /*** Processing ***/
  React.useEffect(()=>{
    if (currentUser) {
      setName(currentUser.name);
      setEmail(currentUser.contact);
      setPhone(currentUser.phone);
      setDrescription(currentUser.description);
    }
  }, []);
  /*** Sub Components ***/
  const renderProfileUpdate = () => {
    return <>
      <div className='profile_page_one_line_item'>
        <label className='profile_page_item_key'>
          {TEXT_LOGIN_ID}
        </label>
        <label >
          {currentUser?.email}
        </label>
      </div>
      <div className='profile_page_one_line_item'>
        <label className='profile_page_item_key'>
          {TEXT_USER_NAME}
        </label>
        <input 
        type="text"
        value={name}
        onChange={(e)=>{setName(e.target.value)}} />
      </div>
      <div className='profile_page_one_line_item'>
        <label className='profile_page_item_key'>
          {TEXT_CONTACT}
        </label>
        {currentUser.accountType===AccountType.USER?
        <label>
          {email}
        </label>:
        <input 
        type='text'
        value={email}
        onChange={(e)=>{setEmail(e.target.value)}} />}
      </div>
      <div className='profile_page_one_line_item'>
        <label className='profile_page_item_key'>
          {TEXT_PHONE}
        </label>
        <input 
        type="text"
        value={phone}
        onChange={(e)=>{setPhone(e.target.value)}} />
      </div>
      <div className='profile_page_one_line_item'>
        <label className='profile_page_item_key'>
          {TEXT_DESCRIPTION}
        </label>
        <input 
        type="text"
        value={description}
        onChange={(e)=>{setDrescription(e.target.value)}}/>
      </div>
      <div className='profile_page_buttons_container'>
        <button 
        className='profile_page_button'
        disabled={name === currentUser?.name && email === currentUser?.contact && phone === currentUser?.phone && description === currentUser?.description}
        onClick={onUpdateUser}>
          {TEXT_BUTTON_UPDATE}
        </button>
        <button 
        className='profile_page_button'
        onClick={()=>{setResetPasswordOpen(true)}}>
          {TEXT_CHANGE_PASSWORD}
        </button>
      </div>  
    </>
  }
  const renderPasswordReset = () => {
    return <ChangePasswordForm 
    isManagementForm={false}
    onCancel={()=>{setResetPasswordOpen(false)}}
    onSubmit={onChangePassword}/>;
  }
  /*** Event Handlers ***/
  const onUpdateUser = async () => {
    if (!loading){
      setLoading(true);
      const updateResult = await apiUpdateUser({
        id: currentUser.id,
        name,
        contact: email,
        phone,
        description,
      });
      if (updateResult.success) {
        success(DIALOG_MESSAGE_UPDATED_UDER_SUCCESSFULLY);
        setCurrentUser(updateResult.data);
      }
      else {
        error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
      }
      setLoading(false);
    }
  }

  const onChangePassword = async (currentPassword, newPassword) => {
    if (loading) return;
    setLoading(true);
    try{
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, currentPassword, newPassword);
      success(DIALOG_MESSAGE_CHANGED_PASSWORD_SUCCESSFULLY);
      setResetPasswordOpen(false);
    }
    catch (e) {
      error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
    }
    setLoading(false);
  }
  /*** Main Render ***/
  return <div className='profile_page_container'>
    {
      resetPasswordOpen ?
      <DetailHeader
        mainLabel={TEXT_PROFILE}
        subLabel={TEXT_CHANGE_PASSWORD}
        onClick={()=>{setResetPasswordOpen(false)}}
      /> :
      <div className='master_header'>
        <label className='header-text' style={{ fontWeight: 900 }}>{TEXT_PROFILE}</label>
      </div>
    }
    
    {/* <div className='profile_page_path'>
      <label 
      onClick={()=>{setResetPasswordOpen(false)}}
      className='profile_page_path_item'>
        {TEXT_PROFILE}
      </label>
      {resetPasswordOpen?<>
        &nbsp;
        <FontAwesomeIcon icon={faChevronRight}/>
        &nbsp;
        <label className='profile_page_path_item'>
          {TEXT_CHANGE_PASSWORD}
        </label>
      </>: null}
    </div> */}
    {resetPasswordOpen? renderPasswordReset(): renderProfileUpdate()}
    {loading? <FanLoadingIcon size={80}/>: null}
  </div>;
}
ProfilePage.propTypes = propTypes;
ProfilePage.defaultProps = defaultProps;
export default ProfilePage;