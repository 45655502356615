import React from 'react';
import PropTypes from 'prop-types';
import "./MapPage.css";
import MapView from './components/MapView';
import { 
  getMapContent, 
  waitToGetMapContent,
  analyzeGetResult,
  apiListSensors,
  locateMapContent
 } from '../../apis/MapApi';
import { useGeneralSetting } from 'context/GeneralSettingContext';
import FanLoadingIcon from 'components/FanLoadingIcon';
import { apiUpdateSensor } from 'apis/SensorApi';
import { useDialog } from 'context/DialogContext';
import { DIALOG_ERROR_GENERAL_ERROR_MESSAGE, DIALOG_ERROR_INVALID_STATUS_TO_SEND_MAIL, DIALOG_MESSAGE_CONFIRM_SEND_MESSAGE, DIALOG_MESSAGE_SENT_MESSAGE_SUCCESSFULLY } from 'constants/message';
import { LockState } from 'models';
import Popup from '../../components/Popup';
import { PAGE_MAP } from 'utils/constanst';
import { SENSOR_STATUS_ABNORMAL, SENSOR_STATUS_ALERT, SENSOR_STATUS_ATTENTION, SENSOR_STATUS_NORMAL } from 'constants/config';
import SensorSearchbar from './components/SensorSearchbar';
import { apiSendMail } from 'apis/MailApi';
import { PopupContent } from './components/SensorInfoCard';

const SENSOR_STATUS_NORMAL_VALUE = 13;
const SENSOR_STATUS_ALERT_VALUE = 11;
const SENSOR_STATUS_ATTENTION_VALUE = 7;

const propTypes={
  onMapLoad: PropTypes.func,
  mapRef: PropTypes.any,
  onViewSensorDetail: PropTypes.func,
  page: PropTypes.number,
  allSensors: PropTypes.array,
  reloadSensors: PropTypes.func,
  downloadSensors: PropTypes.func,
};
const defaultProps={
  onMapLoad: (map)=>{},
  mapRef: null,
  onViewSensorDetail: (sensor) => {},
  page: PAGE_MAP,
  allSensors: [],
  reloadSensors: () => {},
  downloadSensors: () => {},
};
const MapPage = (props) => {
  /*** States and Variables ***/
  const { mapRef } = props;
  const [sensors, setSensors] = React.useState([]);
  const [selectedSensorId, setSelectedSensorId] = React.useState('');
  const [allSensors, setAllSensors] = React.useState([]);
  const refreshMap = React.useRef(null);
  const { generalSetting } = useGeneralSetting();
  const [loading, setLoading] = React.useState(false);
  const { error, confirm, success } = useDialog();
  const [isOpen, setIsOpen] = React.useState(false);
  const [sensorInfo, setSensorInfo] = React.useState(null);
  const [hasWarningSensor, setHasWarningSensor] = React.useState(false);

  /*** Processing ***/
  React.useEffect(()=>{
    autoRefreshMap();
    // onRefreshSensorListPopup();
    return ()=>{
      clearInterval(refreshMap.current);
    }
  }, []);
  const autoRefreshMap = React.useCallback(()=>{
    if (refreshMap.current) {
      clearInterval(refreshMap.current);
      refreshMap.current = null;
    }
    refreshMap.current = setInterval(async () => {
      const map = mapRef.current;
      if (!map) return;
      const bounds = map.getBounds();
      const result = await getMapContent(
        map.getZoom(), 
        bounds._southWest.lat, 
        bounds._northEast.lat, 
        bounds._southWest.lng, 
        bounds._northEast.lng);
      // console.log('VANVIET', result);
      if (result.success) {
        setSensors(result.data.items);
        // const warningStatus = result.data.status.find(s=>s.status===11);
        // console.log(86, warningStatus)
        // setHasWarningSensor(!!warningStatus);
      }
    }, 60000);
  }, []);

  React.useEffect(()=>{
    if (mapRef.current && generalSetting?.lat && generalSetting?.lng) {
      mapRef.current.setView(
        {lat: generalSetting.lat , 
        lon: generalSetting.lng}, 
        generalSetting.zoom);
    }
  }, [generalSetting])
  /*** Sub Components ***/
  /*** Event Handlers ***/
  const onViewportChange = React.useCallback (async (e)=>{
    const map = e.target;
    const bounds = map.getBounds();
    waitToGetMapContent(
      map.getZoom(), 
      bounds._southWest.lat, 
      bounds._northEast.lat, 
      bounds._southWest.lng, 
      bounds._northEast.lng, 
      (data)=>{
        setSensors(data.items);
        // const warningStatus = data.status.find(s=>s.status===11);
        // console.log(114, warningStatus)
        // setHasWarningSensor(!!warningStatus);
      });
    // reset refreshing map interval function
    autoRefreshMap();
  }, [])
  
  const onMarkerClick = React.useCallback(async (sensor)=> {
    setIsOpen(true);
    setSelectedSensorId(sensor.id);
    if (sensor.id.includes('#')) {
      const result = await analyzeGetResult(sensor.id);
      if (result.success && result.data) {
        setSensorInfo(result.data.items);
      }
    } else {
      setSensorInfo([sensor]);
    }
    if (mapRef.current) {
      mapRef.current.flyTo({lat: sensor.lat, lon: sensor.lng}, Math.min(mapRef.current.getZoom()+3, 18))
    }
  }, [])
  const onMapClick = React.useCallback(()=>{
    setSelectedSensorId('');
  }, [])
  const onMapLoad = React.useCallback(async (e)=>{
    const map = e.target;
    mapRef.current = map;
    props.onMapLoad(map);
    const bounds = map.getBounds();
    const result = await getMapContent(
      map.getZoom(), 
      bounds._southWest.lat, 
      bounds._northEast.lat, 
      bounds._southWest.lng, 
      bounds._northEast.lng);
    // console.log('VANVIET', result);
    if (result.success) {
      setSensors(result.data.items);
    }
  }, [props.onMapLoad])
  
  const onSensorClick = async (sensor) => {
    const result = await locateMapContent(sensor.id);
    if (result.success) {
      const data = result.data;
      if (mapRef.current){
        mapRef.current.flyTo({lat: data.lat, lon: data.lng}, Math.min(data.zoom+8, 18));
      }
      setSelectedSensorId('');
    }
    else {
      // console.error("ERROR", result.error);
    }
  }

  const onUnlockSensor = async (sensor) => {
    if(loading) return;
    setLoading(true);
    const result = await apiUpdateSensor({
      id: sensor.id,
      isLocked: LockState.UNLOCKED
    });
    setLoading(false);
    if (result.success) {
      const newSensors = props.allSensors.filter((sensor) => sensor.id !== result.data.id);
      props.reloadSensors([...newSensors, result.data], 'init');
    } 
    else {
      error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
    }
  }


  const onSendMail = async (sensor) => {
    if (loading) return;
    const statusValue = sensor.status;
    if (statusValue === -1) {
      error(DIALOG_ERROR_INVALID_STATUS_TO_SEND_MAIL);
      return;
    }
    confirm(DIALOG_MESSAGE_CONFIRM_SEND_MESSAGE, async ()=>{
      setLoading(true);
      const status = (statusValue === SENSOR_STATUS_NORMAL_VALUE && SENSOR_STATUS_NORMAL) || 
                      (statusValue === SENSOR_STATUS_ALERT_VALUE && SENSOR_STATUS_ALERT) || 
                      (statusValue === SENSOR_STATUS_ATTENTION_VALUE && SENSOR_STATUS_ATTENTION) || 
                      SENSOR_STATUS_ABNORMAL;
      const subject = status!==SENSOR_STATUS_ABNORMAL? `【${status}】冠水警報システム ${sensor.name} ${status}レベル水位`: `【${status}】冠水警報システム ${sensor.name} ${status}発生`;
      const text = status!==SENSOR_STATUS_ABNORMAL? `${sensor.name}の水位が${status}レベルになりました。`: `${sensor.name}の機器で${status}が発生しました。`;
      const res = await apiSendMail(subject, text);
      setLoading(false);
      if (res.success) {
        success(DIALOG_MESSAGE_SENT_MESSAGE_SUCCESSFULLY);
      }
      else {
        error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
      }
    });
  }

  React.useEffect(() => {
    setIsOpen(false);
    setSensorInfo(null);
  }, [props.page]);

  React.useEffect(() => {
    if (sensorInfo?.length) {
      const ids = sensorInfo.map((item) => item.id);
      const newSensor = props.allSensors.filter((item) => ids.includes(item.id));
      if (newSensor) setSensorInfo(newSensor);
    }
    const warningStatus = props.allSensors.filter((item) => item.status === SENSOR_STATUS_ALERT_VALUE)
    setHasWarningSensor(!!warningStatus.length);
  }, [props.allSensors]);

  /*** Main Render ***/
  return <div className='map_page_container'>
    <MapView 
      markers={sensors}
      selectedId={selectedSensorId}
      onMarkerClick={onMarkerClick}
      onViewportChange={onViewportChange}
      onMapClick={onMapClick}
      onMapLoad={onMapLoad} />
    {
      props.page === PAGE_MAP &&
      <>
        <SensorSearchbar 
          initSensors={props.allSensors} 
          onClick={onSensorClick} 
          onUnlock={onUnlockSensor} 
          setSensorInfo={setSensorInfo}
          sensorInfo={sensorInfo}
          onViewSensorDetail={props.onViewSensorDetail}
          onSendMail={onSendMail}
          onReload={props.downloadSensors}
          hasWarningSensor={hasWarningSensor}
        />
        <Popup 
          isOpen={isOpen} 
          styles={{ bottom: '32px', right: '32px' }}
          isDrag={true}
        >
          <PopupContent 
            setIsOpen={setIsOpen}
            sensors={sensorInfo}
            onUnlockSensor={onUnlockSensor}
            setSensor={setSensorInfo}
            onViewSensorDetail={props.onViewSensorDetail}
            onSendMail={onSendMail}
          />
        </Popup>
      </>
    }
    
    {loading && <FanLoadingIcon size={80}/>}
  </div>;
}

MapPage.propTypes = propTypes;
MapPage.defaultProps = defaultProps;
export default MapPage;