import React from 'react';
import PropTypes from 'prop-types';
import "./NavBar.css";

const propTypes={
  tabs: PropTypes.arrayOf(PropTypes.string),
  currentTab: PropTypes.string,
  onTabChange: PropTypes.func
};
const defaultProps={
  tabs: [],
  currentTab: undefined,
  onTabChange: (newTab) => {}
};
const NavBar = (props) => {
  /*** States and Variables ***/
  /*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  /*** Main Render ***/
  return <div className='navbar_container'>
    {
      props.tabs.map(tab => {
        return <button 
        key={tab}
        onClick={()=>{props.onTabChange(tab)}}
        className={tab===props.currentTab? 'navbar_selected_tab': 'navbar_tab'}>
          {tab}
        </button>
      })
    }
  </div>;
}
NavBar.propTypes = propTypes;
NavBar.defaultProps = defaultProps;
export default NavBar;