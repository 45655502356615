import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { apiListGroups } from 'apis/SensorApi';
import { useAuth } from './AuthContext';

const Context = createContext({});

export default function SensorGroupsProvider({ children }) {
  const { currentUser } = useAuth();
  const [sensorGroups, setSensorGroups] = useState([]);
  /***** Processing *****/
  useEffect(() => {
    if (currentUser) listGroups();
  }, [currentUser])

  const listGroups = async () => {
    try {
      const res = await apiListGroups();
      if (res.success) {
        setSensorGroups(res.data);
      }
    }
    catch (e) {
      // console.error("ERROR", e);
    }
  }
  /***** Main Render *****/
  return (
    <Context.Provider value={{ sensorGroups, setSensorGroups }}>
      {children}
    </Context.Provider>
  );
}

export const useSensorGroups = () => useContext(Context);
