/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      contact
      phone
      address
      description
      accountType
      isLocked
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        contact
        phone
        address
        description
        accountType
        isLocked
        isActive
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        contact
        phone
        address
        description
        accountType
        isLocked
        isActive
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSensor = /* GraphQL */ `
  query GetSensor($id: ID!) {
    getSensor(id: $id) {
      id
      apiGroupId
      groupId
      managementId
      name
      status
      lat
      lng
      location
      description
      tx
      isLocked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSensors = /* GraphQL */ `
  query ListSensors(
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        apiGroupId
        groupId
        managementId
        name
        status
        lat
        lng
        location
        description
        tx
        isLocked
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSensorGroups = /* GraphQL */ `
  query ListSensorGroups(
    $filter: ModelSensorGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensorGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        messages {
          status
          message
          color
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSensorHistory = /* GraphQL */ `
  query GetSensorHistory($sensorId: ID!, $createdAt: AWSDateTime!) {
    getSensorHistory(sensorId: $sensorId, createdAt: $createdAt) {
      sensorId
      status
      lat
      lng
      createdAt
      tx
      payload
      updatedAt
      __typename
    }
  }
`;
export const listSensorHistorys = /* GraphQL */ `
  query ListSensorHistorys(
    $sensorId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelSensorHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSensorHistorys(
      sensorId: $sensorId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sensorId
        status
        lat
        lng
        createdAt
        tx
        payload
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSensorChangeHistory = /* GraphQL */ `
  query GetSensorChangeHistory($sensorId: ID!, $createdAt: AWSDateTime!) {
    getSensorChangeHistory(sensorId: $sensorId, createdAt: $createdAt) {
      sensorId
      status
      lat
      lng
      createdAt
      tx
      payload
      updatedAt
      __typename
    }
  }
`;
export const listSensorChangeHistorys = /* GraphQL */ `
  query ListSensorChangeHistorys(
    $sensorId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelSensorChangeHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSensorChangeHistorys(
      sensorId: $sensorId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sensorId
        status
        lat
        lng
        createdAt
        tx
        payload
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
