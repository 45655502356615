import { TEXT_MANAGEMENT_ID, 
    TEXT_NAME, TEXT_SENSOR_MAP, 
    TEXT_SENSOR_LIST,
    TEXT_USER_MANAGEMENT,
    TEXT_SENSOR_MANAGEMENT,
    TEXT_MASTER_MANAGEMENT,
    TEXT_ELTRES_ID,
    TEXT_GROUP_ID,
    TEXT_LOCATION,
    TEXT_LATITUDE,
    TEXT_LONGITUDE,
    TEXT_REGISTERED_DATE,
    TEXT_DATA_RECEIVED_DATETIME,
    TEXT_STATUS,
    TEXT_MESSAGE,
    TEXT_VALUE,
} from 'constants/message';
import { IconList } from 'components/Icon';

const PAGE_MAP = 0;
const PAGE_SENSOR_LIST = 1;
const PAGE_SENSOR_MANAGEMENT = 2;
const PAGE_USER_MANAGEMENT = 3;
const PAGE_MASTER_MANAGEMENT = 4;
const PAGE_PROFILE = 5;

const TEXT_SEND_MAIL = 'メール送信';
const TEXT_VIEW_MAP = 'マップ確認';

const SENSOR_PROPERTIES = {
    ID: 'id',
    MANAGE_ID: 'managementId',
    NAME: 'name',
    GROUP_ID: 'apiGroupId',
    LOCATION: 'location',
    LAT: 'lat',
    LONG: 'lng',
    CREATEDAT: 'createdAt',
    TX: 'tx',
    STATUS: 'status',
    MESSAGE: 'message',
}

const UNDEFINED_TEXT = '-';

const SENSOR_MANAGE_MENU = [
    {
        page: PAGE_MAP,
        label: TEXT_SENSOR_MAP,
        icon: IconList.iconMenuMap,
    },
    {
        page: PAGE_SENSOR_LIST,
        label: TEXT_SENSOR_LIST,
        icon: IconList.iconMenuSensorDetail,
    }
]

const MANAGER_SETTING_MENU = [
  {
      page: PAGE_SENSOR_MANAGEMENT,
      label: TEXT_SENSOR_MANAGEMENT,
      icon: IconList.iconMenuSensorManage,
  },
  {
      page: PAGE_USER_MANAGEMENT,
      label: TEXT_USER_MANAGEMENT,
      icon: IconList.iconMenuUser,
  }
]

const ADMIN_SETTING_MENU = [
    {
        page: PAGE_SENSOR_MANAGEMENT,
        label: TEXT_SENSOR_MANAGEMENT,
        icon: IconList.iconMenuSensorManage,
    },
    {
        page: PAGE_USER_MANAGEMENT,
        label: TEXT_USER_MANAGEMENT,
        icon: IconList.iconMenuUser,
    },
    {
        page: PAGE_MASTER_MANAGEMENT,
        label: TEXT_MASTER_MANAGEMENT,
        icon: IconList.iconMenuMaster,
    }
]

const SENSOR_LEFT_INFO = [
    {
      id: SENSOR_PROPERTIES.MANAGE_ID,
      label: TEXT_MANAGEMENT_ID
    },
    {
      id: SENSOR_PROPERTIES.NAME,
      label: TEXT_NAME
    },
    {
      id: SENSOR_PROPERTIES.LOCATION,
      label: TEXT_LOCATION,
    },
    {
      id: SENSOR_PROPERTIES.CREATEDAT,
      label: TEXT_REGISTERED_DATE,
    }
  ]
  
const SENSOR_RIGHT_INFO = [
    {
      id: SENSOR_PROPERTIES.ID,
      label: TEXT_ELTRES_ID,
    },
    {
      id: SENSOR_PROPERTIES.GROUP_ID,
      label: TEXT_GROUP_ID,
    },
    {
      id: SENSOR_PROPERTIES.LAT,
      label: TEXT_LATITUDE,
    },
    {
      id: SENSOR_PROPERTIES.LONG,
      label: TEXT_LONGITUDE,
    }
]

const SENSOR_INFO = [
    {
        label: TEXT_MANAGEMENT_ID,
        key: SENSOR_PROPERTIES.MANAGE_ID
    },
    {
        label: TEXT_NAME,
        key: SENSOR_PROPERTIES.NAME,
    },
    {
        label: TEXT_DATA_RECEIVED_DATETIME,
        key: SENSOR_PROPERTIES.TX,
    },
    {
        label: TEXT_STATUS,
        key: SENSOR_PROPERTIES.STATUS,
    },
    {
        label: TEXT_MESSAGE,
        key: SENSOR_PROPERTIES.MESSAGE
    }
]

const ROLE = {
    GENERAL: 'general',
    LIST: 'list',
    DETAIL: 'detail',
    MANAGE: 'manage',
}
  
const HEADER = [
    { id: SENSOR_PROPERTIES.MANAGE_ID, label: TEXT_MANAGEMENT_ID, role: ROLE.GENERAL, width: '100px', isSort: true },
    { id: SENSOR_PROPERTIES.NAME, label: TEXT_NAME, role: ROLE.GENERAL },
    { id: SENSOR_PROPERTIES.LOCATION, label: TEXT_LOCATION, role: ROLE.GENERAL },
    { id: SENSOR_PROPERTIES.TX, label: TEXT_DATA_RECEIVED_DATETIME, role: ROLE.LIST, width: '120px' },
    { id: SENSOR_PROPERTIES.STATUS, label: TEXT_STATUS, role: ROLE.LIST, width: '80px', isSort: true },
    { id: SENSOR_PROPERTIES.MESSAGE, label: TEXT_MESSAGE, role: ROLE.LIST },
    { id: 'iconMail', label: TEXT_SEND_MAIL, role: ROLE.LIST },
    { id: 'iconMap', label: TEXT_VIEW_MAP, role: ROLE.LIST },
  
    { id: SENSOR_PROPERTIES.CREATEDAT, label: TEXT_REGISTERED_DATE, role: ROLE.MANAGE },
    { id: SENSOR_PROPERTIES.ID, label: TEXT_ELTRES_ID, role: ROLE.MANAGE, isSort: true },
    { id: SENSOR_PROPERTIES.GROUP_ID, label: TEXT_GROUP_ID, role: ROLE.MANAGE, isSort: true },
    { id: SENSOR_PROPERTIES.LAT, label: TEXT_LATITUDE, role: ROLE.MANAGE, isSort: true },
    { id: SENSOR_PROPERTIES.LONG, label: TEXT_LONGITUDE, role: ROLE.MANAGE, isSort: true },
    { id: 'iconDelete', label: '', role: ROLE.MANAGE },
  
    { id: SENSOR_PROPERTIES.TX, label: TEXT_DATA_RECEIVED_DATETIME, role: ROLE.DETAIL },
    { id: SENSOR_PROPERTIES.STATUS, label: TEXT_STATUS, role: ROLE.DETAIL, isSort: true },
    { id: '', label: TEXT_VALUE, role: ROLE.DETAIL },
  ]

export {
    PAGE_MAP,
    PAGE_MASTER_MANAGEMENT,
    PAGE_PROFILE,
    PAGE_SENSOR_LIST,
    PAGE_SENSOR_MANAGEMENT,
    PAGE_USER_MANAGEMENT,
    SENSOR_MANAGE_MENU,
    ADMIN_SETTING_MENU,
    MANAGER_SETTING_MENU,
    SENSOR_INFO,
    UNDEFINED_TEXT,
    HEADER,
    ROLE,
    SENSOR_LEFT_INFO,
    SENSOR_RIGHT_INFO,
    SENSOR_PROPERTIES,
}