/**
 * This API is only allowed to be used by 1 single map view
 */

import AWS from 'aws-sdk'
import { listSensors } from 'graphql/queries';
import { UNDEFINED_SENSOR_RECEIVED_TIME } from '../constants/config';
import { convertDateTimeFormat, convertMomentFormat } from '../utils/function';
import { API } from "aws-amplify"
import { AUTH_MODE } from "../constants/config";

const Lambda = new AWS.Lambda({
  region: "ap-northeast-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  }
});

const EPSILON = 0.001; // add this small value to lat long extents to avoid too small area
let taskGetContent = undefined;
const preParams = {
	zoom: undefined,
	southLat: undefined, 
	northLat: undefined, 
	westLng: undefined, 
	eastLng: undefined,
};

const expandRequestedArea = (southLat, northLat, westLng, eastLng) =>{
	// get more data on the larger area to cache
	let centerLatitude = (northLat + southLat)/2;
	let centerLongitude = 0;
	let latitutdeExpandPad = northLat - southLat;
	let longitudeExpandPad = 0;
	if(eastLng > westLng){
		centerLongitude = (eastLng + westLng) / 2;
		longitudeExpandPad = eastLng - westLng;
	}
	else {
		centerLongitude = (eastLng + westLng + 360) / 2;
		longitudeExpandPad = eastLng + 360 - westLng;
		if(centerLongitude>180){
			centerLongitude = centerLongitude - 360;
		}
	}
	// expand extent
	const original_dLng = eastLng - westLng;
	if(original_dLng>360){ // if the requested area is longer than 1 round
		eastLng = 180;
		westLng = -180;
	}
	northLat = centerLatitude + latitutdeExpandPad;
	northLat = northLat>85.0513 ? 85.0513: northLat; // maximum latitude is 85.0513
	southLat = centerLatitude - latitutdeExpandPad;
	southLat = southLat<-85.0513 ? -85.0513: southLat; // minimum latitude is -85.0513
	eastLng = centerLongitude + longitudeExpandPad;
	eastLng = eastLng > 180 ? eastLng - 360: eastLng;
	westLng = centerLongitude - longitudeExpandPad;
	westLng = westLng < -180 ? westLng + 360: westLng;
	if(original_dLng>eastLng - westLng){ 
		// if after expanding, the area is shorter than the original area
		// reconfig the longitude
		eastLng = 180;
		westLng = -180;
	}
	return {southLat, northLat, westLng, eastLng}
}

/**
 * check if one should ignore the request
 * ingore the request if the requested area is within the previous requested area
 * @param {number} zoom 
 * @param {number} southLat 
 * @param {number} northLat 
 * @param {number} westLng 
 * @param {number} eastLng 
 */
 const shouldIgnore = (zoom, southLat, northLat, westLng, eastLng, keywords=null) => {
	eastLng = eastLng > 180 ? eastLng - 360: eastLng;
	westLng = westLng < -180 ? westLng + 360: westLng;
	let ignore = true;
	zoom = Math.ceil(zoom);
	if(preParams.zoom){
		if (preParams.keywords?.join('')!==keywords?.join('')) {
			ignore=false;
		}
		if (ignore) {
			if(zoom<=(preParams.zoom-1) || zoom>preParams.zoom){
				ignore=false;
			}
		}
		if(ignore){
			if(southLat<preParams.southLat || southLat>preParams.northLat) {
				ignore = false;
			}
		}
		if(ignore){
			if(northLat<preParams.southLat || northLat>preParams.northLat){
				ignore = false;
			}
		}
		if(ignore){
			if(preParams.westLng<preParams.eastLng){
				if(westLng<preParams.westLng || westLng>preParams.eastLng) {
					ignore = false;
				}
				if(ignore){
					if(eastLng<preParams.westLng || eastLng>preParams.eastLng) {
						ignore = false;
					}
				}
			}
			else{
				if(westLng<preParams.westLng && westLng>preParams.eastLng) {
					ignore = false;
				}
				if(ignore){
					if(eastLng<preParams.westLng && eastLng>preParams.eastLng) ignore = false;
				}
			}
		}
	}
	else{
		ignore = false;
	}
	return ignore;
}

/**
 * 
 * @param {number} zoom 
 * @param {number} maxLat 
 * @param {number} maxLng 
 * @param {number} minLat 
 * @param {number} minLng 
 */
const getMapContent = async (zoom, southLat, northLat, westLng, eastLng) => {
	try {
    const params = {
      FunctionName: `manageMapContent-${process.env.REACT_APP_ENV}`,
      Payload: JSON.stringify({
        query_type: 'get_content',
        zoom: Math.min(zoom+3, 21),
        latitude_range: [Math.max(-85.0513, southLat - EPSILON), Math.min(85.0513 ,northLat + EPSILON)],
        longitude_range: [Math.max(-180, westLng - EPSILON), Math.min(180, eastLng + EPSILON)],
      })
    }
		const res = await Lambda.invoke(params).promise();
    const result = JSON.parse(res.Payload);
		if (result.success) {
			for (const item of result.data.items) {
				item.createdAt = item.createdAt? convertDateTimeFormat(item.createdAt): null;
				item.updatedAt = item.updatedAt? convertMomentFormat(item.updatedAt): null;
				item.tx = item.tx? convertMomentFormat(item.tx): UNDEFINED_SENSOR_RECEIVED_TIME;
			}
		}
		return result;
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const waitToGetMapContent = (zoom, southLat, northLat, westLng, eastLng, callback) => {
	if(taskGetContent){
		clearTimeout(taskGetContent);
	}
	taskGetContent = setTimeout(async ()=>{
		// if the requested area is within the previous requested area, ignore this request
		if(shouldIgnore(zoom, southLat, northLat, westLng, eastLng)) {
			return;
		}
		// expand requested area
		const expandedArea = expandRequestedArea(southLat, northLat, westLng, eastLng);
		const expandedSouthLat = expandedArea.southLat;
		const expandedNorthLat = expandedArea.northLat;
		const expandedWestLng = expandedArea.westLng;
		const expandedEastLng = expandedArea.eastLng;
		// get map content
		const result = await getMapContent(zoom, expandedSouthLat, expandedNorthLat, expandedWestLng, expandedEastLng);
		if(result.success){
			callback(result.data);
		}
		else{
			// console.error("ERROR", result.error);
		}
	}, 500);
}

const analyzeGetResult = async (itemId, until, nextToken) => {
	try {
    const params = {
      FunctionName: `manageMapContent-${process.env.REACT_APP_ENV}`,
      Payload: JSON.stringify({
        query_type: 'analyze_get',
        item_id: itemId,
				until: until,
				next_token: nextToken
      })
    }
    const res = await Lambda.invoke(params).promise();
    const result = JSON.parse(res.Payload);
		if (result.success) {
			for (const item of result.data.items) {
				item.createdAt = item.createdAt? convertDateTimeFormat(item.createdAt): null;
				item.updatedAt = item.updatedAt? convertMomentFormat(item.updatedAt): null;
				item.tx = item.tx? convertMomentFormat(item.tx): UNDEFINED_SENSOR_RECEIVED_TIME;
			}
		}
		return result;
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const apiListSensors = async () => {
	try {
		let nextToken = null;
		const data = [];
    while(true) {
			const params = {
				FunctionName: `manageMapContent-${process.env.REACT_APP_ENV}`,
				Payload: JSON.stringify({
					query_type: 'list_content',
					next_token: nextToken
				})
			};
			const res = await Lambda.invoke(params).promise();
			const result = JSON.parse(res.Payload);
			nextToken = res.data?.listSensorGroups?.nextToken;
			if (result.success) {
				for (const item of result.data.items) {
					if (item.createdAt) item.createdAt = convertDateTimeFormat(item.createdAt);
					if (item.updatedAt) item.updatedAt = convertMomentFormat(item.updatedAt);
					item.tx = item.tx? convertMomentFormat(item.tx): UNDEFINED_SENSOR_RECEIVED_TIME;
				}
				data.push(...result.data.items);
			}
			else {
				return result;
			}
			if (!nextToken) break;
			// const res = await API.graphql({
			// 	query: listSensors,
			// 	authMode: AUTH_MODE,
			// 	nextToken
			// });
			// nextToken = res.data.listSensors.nextToken;
			// for(const item of res.data.listSensors.items) {
			// 	if (item.createdAt) item.createdAt = convertDateTimeFormat(item.createdAt);
			// 	if (item.updatedAt) item.updatedAt = convertMomentFormat(item.updatedAt);
			// 	item.tx = item.tx? convertMomentFormat(item.tx): UNDEFINED_SENSOR_RECEIVED_TIME;
			// }
			// data.push(...res.data.listSensors.items);
			// if (!nextToken) break;
		}
		return {
			success: true,
			data: {
				items: data,
			}
		};
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const locateMapContent = async (itemId) => {
	try {
    const params = {
      FunctionName: `manageMapContent-${process.env.REACT_APP_ENV}`,
      Payload: JSON.stringify({
        query_type: 'locate_content',
        item_id: itemId
      })
    }
    const res = await Lambda.invoke(params).promise();
    const result = JSON.parse(res.Payload);
		return result;
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

export {
  getMapContent,
	waitToGetMapContent,
	analyzeGetResult,
	apiListSensors,
	locateMapContent
}