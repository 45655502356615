import './Searchbar.css';
import React from 'react';
import { standardizeString, useAllSensors } from 'utils/function';
import { SENSOR_LOCATION_TEMPLATE, SENSOR_NAME_TEMPLATE } from 'constants/config';
import { UNDEFINED_TEXT } from 'utils/constanst';
import { useSensorGroups } from 'context/SensorGroupsContext';
import PropTypes from 'prop-types'
import Icon, { IconList } from './Icon';

const Searchbar = (props) => {
    const [result, setResult] = React.useState([]);

    const [allSensors] = useAllSensors(props.initSensors, true);

    React.useEffect(() => {
        if (allSensors.length && props.setGroupStatus) {
            const status = new Set(allSensors.map((sensor) => sensor?.statusValue?.content));
            props.setGroupStatus([...props.defaultFilterStatus, ...status]);
        }
        if (result.length) {
            onSearch();
        }
    }, [allSensors]);

    //search
    const handleSearch = async (keywords) => {
        if (!keywords.length) {
            setResult([]);
            props.setIsOpen && props.setIsOpen(true);
            return;
        };
        const result = allSensors.filter((sensor) => keywords.every(key => sensor.search.includes(key)));
        setResult(result);
        props.setIsOpen && props.setIsOpen(true);
    }

    const onSearch = async () => {
        props.setPreKeyword(props.keyword);
        if (!props.keyword) {
            setResult([]);
            props.setIsOpen(true);
            return;
        };
        let searchWords = standardizeString(props.keyword).replaceAll(/[、､ 　]/g,",");
		searchWords = searchWords.split(",").map(keyword => keyword.trim()).filter(keyword => keyword !== " ");
        handleSearch(searchWords);
    }

    const onKeydown = async (e) => {
        if(e.key === 'Enter' && props.keyword !== props.preKeyword) {
            await onSearch();
        }
    }

    const handleClickSearch = async () => {
        if (props.keyword === '') {
            props.onReload();
            setResult([]);
            props.setIsOpen(true);
            return;
        } 
        if (props.keyword !== props.preKeyword) {
            await onSearch()
        }
    }

    const handleReset = () => {
        props.setKeyword('');
        props.setPreKeyword('');
        setResult([]);
    }

    React.useEffect(() => {
        if (result.length) {
            props.setSensorList(result);
        } else {
            props.keyword ? props.setSensorList([]) : props.setSensorList(allSensors);
        }
    }, [result, allSensors]);

    return (
        <label className='searchbar_flex-row searchbar'>
            <div className='searchbar_flex-row main-search'>
                <input 
                    name='sensor-search' 
                    value={props.keyword} 
                    className='search-input' 
                    placeholder={props.placeholder}
                    onChange={(e) => props.setKeyword(e.target.value)}
                    onKeyDown={onKeydown}
                />
                <Icon icon={IconList.iconSearch} containerStyle='icon_container' onClick={handleClickSearch}/>
            </div>
            {
                props.icon && 
                <div className='searchbar_flex-row' style={{ cursor: 'pointer' }} onClick={handleReset}>
                    <img alt='icon' src={props.icon} />
                </div>
            }
        </label>
    )
}

Searchbar.propTypes = {
    initSensors: PropTypes.array, 
    setSensorList: PropTypes.func, 
    setIsOpen: PropTypes.func, 
    placeholder: PropTypes.string,
    keyword: PropTypes.string,
    setKeyword: PropTypes.func,
    preKeyword: PropTypes.string,
    setPreKeyword: PropTypes.func, 
    icon: PropTypes.any,
    isRefreshInit: PropTypes.bool,
    setGroupStatus: PropTypes.func,
    defaultFilterStatus: PropTypes.array,
    onReload: PropTypes.func,
}

Searchbar.defaultProps = {
    initSensors: [], 
    setSensorList: () => {}, 
    setIsOpen: () => {}, 
    placeholder: '',
    keyword: '',
    setKeyword: () => {},
    preKeyword: '',
    setPreKeyword: () => {}, 
    icon: null,
    isRefreshInit: false,
    setGroupStatus: () => {},
    defaultFilterStatus: [],
    onReload: () => {},
}

export default Searchbar;