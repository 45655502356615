import React from 'react';
import "./MasterManagementPage.css";
import NavBar from '../../components/NavBar';
import { Storage } from 'aws-amplify';
import { useGeneralSetting } from '../../context/GeneralSettingContext';
import FanLoadingIcon from '../../components/FanLoadingIcon';
import { useSensorGroups } from '../../context/SensorGroupsContext';
import SensorGroupList from './components/SensorGroupList';
import ModifyGroupForm from './components/ModifyGroupForm';
import { useDialog } from '../../context/DialogContext';
import { apiCreateGroup, apiDeleteGroup, apiUpdateGroup } from '../../apis/SensorApi';
import { DIALOG_ERROR_GENERAL_ERROR_MESSAGE, DIALOG_MESSAGE_CONFIRM_DELETE_GROUP, DIALOG_MESSAGE_UPDATED_GENERAL_SETTING_SUCCESSFULLY, TEXT_CREATE_NEW, TEXT_MASTER_MANAGEMENT, TEXT_MESSAGE_MANAGEMENT } from 'constants/message';
import ModifyGeneralSettingForm from './components/ModifyGeneralSettingForm';
import DetailHeader from 'components/DetailHeader';

const TAB_GENERAL = '一般';
const TAB_MESSAGE = TEXT_MESSAGE_MANAGEMENT;
const TABS = [TAB_GENERAL, TAB_MESSAGE];


const propTypes={};
const defaultProps={};
const MasterManagementPage = (props) => {
  /*** States and Variables ***/
  const { error, success, warningConfirm } = useDialog();
  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState(TAB_GENERAL);
  const { generalSetting, setGeneralSetting } = useGeneralSetting();
  const { sensorGroups, setSensorGroups } = useSensorGroups();
  const [modifyingGroup, setModifyingGroup] = React.useState(null);
  /*** Processing ***/
  /*** Sub Components ***/
  const renderGeneralSetting = () => {
    return <ModifyGeneralSettingForm 
    initSetting={generalSetting || {}}
    onSubmit={onUpdateGeneralSetting}/>
  }

  const renderGroupSetting = () => {
    return <div 
    style={{padding: modifyingGroup? '0 16px': '0'}}
    className='master_management_page_subnav_content_container'>
      {renderCreateGroupForm()}
    </div>
  }

  const renderCreateGroupForm = () => {
    if (modifyingGroup) {
      return <ModifyGroupForm 
      group={modifyingGroup}
      onCancel={()=>{setModifyingGroup(null)}}
      onModify={onModifyGroup} />
    }
    return <>
      <div 
      style={{justifyContent: 'flex-end'}}
      className='master_management_page_list_actions_container'>
        <button 
        className='button_active'
        onClick={()=>{setModifyingGroup({})}}>
          {TEXT_CREATE_NEW}
        </button>
      </div>
      <div className='master_management_page_group_list_container'>
        <SensorGroupList 
        items={sensorGroups}
        onClick={(item)=>{setModifyingGroup(item)}}
        onDelete={onDeleteGroup} />
      </div>
    </>
  }

  /*** Event Handlers ***/
  const onUpdateGeneralSetting = async (data) => {
    if (!loading) {
      setLoading(true);
      try {
        await Storage.put(
          'setting/general_setting.json', 
          JSON.stringify(data),
          {
            level: "public",
            contentType: "text/plain",
          });
        setGeneralSetting(data);
        success(DIALOG_MESSAGE_UPDATED_GENERAL_SETTING_SUCCESSFULLY);
      }
      catch(e) {
        // console.error("ERROR", e);
        error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
      }
      setLoading(false);
    }
  }
  const onModifyGroup = async (newGroup) => {
    if (!newGroup.name) {
      error("グループ名を入力していません！");
      return;
    }
    if (!loading) {
      setLoading(true);
      if (!modifyingGroup.id) {
        const result = await apiCreateGroup(newGroup);
        if (result.success) {
          success("センサグループを作成しました。");
          sensorGroups.push(result.data);
          sensorGroups.sort((g1,g2)=>g1.createdAt.localeCompare(g2.createdAt));
          setSensorGroups(Object.assign([], sensorGroups));
          setModifyingGroup(null);
        }
        else {
          error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
        }
      }
      else {
        delete newGroup.updatedAt;
        delete newGroup.createdAt;
        const result = await apiUpdateGroup(newGroup);
        if (result.success) {
          success("センサグループを更新しました。");
          const newSensorGroups = sensorGroups.filter(group=>group.id!==modifyingGroup.id);
          newSensorGroups.push(result.data);
          newSensorGroups.sort((g1,g2)=>g1.createdAt.localeCompare(g2.createdAt));
          setSensorGroups(newSensorGroups);
          setModifyingGroup(null);
        }
        else {
          error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
        }
      }
      setLoading(false);
    }
  }

  const onDeleteGroup = async (item) => {
    warningConfirm(DIALOG_MESSAGE_CONFIRM_DELETE_GROUP, async () => {
      const result = await apiDeleteGroup(item.id);
      if (result.success) {
        setSensorGroups(sensorGroups.filter(group=>group.id!==item.id));
      }
      else {
        error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
      }
    });
  }
  /*** Main Render ***/
  return <div className='master_management_page_container'>
    <div className='master_header'>
      <label className='header-text' style={{ fontWeight: 900 }}>{TEXT_MASTER_MANAGEMENT}</label>
      <NavBar 
        tabs={TABS}
        currentTab={tab}
        onTabChange={setTab} />
      <label className='master_management_page_version'>Version 1.0</label>
    </div>
    
    <div className='master_management_page_nav_content'>
      {tab===TAB_GENERAL? renderGeneralSetting() : renderGroupSetting()}
    </div>
    {loading? <FanLoadingIcon size={80}/>: null}
  </div>;
}
MasterManagementPage.propTypes = propTypes;
MasterManagementPage.defaultProps = defaultProps;
export default MasterManagementPage;