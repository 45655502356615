import React from 'react';
import PropTypes from 'prop-types';
import "./UserList.css";
import BaseList from 'components/BaseList';
import { TEXT_CONTACT, TEXT_DESCRIPTION, TEXT_LOGIN_ID, TEXT_PHONE, TEXT_UPDATED_DATE, TEXT_USER_NAME } from 'constants/message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'context/AuthContext';
import { AccountType } from 'models';
import { CURRENT_PAGE_DEFAULT } from 'constants/config';
import Icon, { IconList } from 'components/Icon';

const propTypes = {
  items: PropTypes.array,
  curPage: PropTypes.number,
  onEditItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onPageChange: PropTypes.func
};
const defaultProps = {
  items: [],
  curPage: CURRENT_PAGE_DEFAULT,
  onEditItem: (item) => {},
  onDeleteItem: (item) => {},
  onPageChange: (newPage) => {}
};
const UserList = (props) => {
  /*** States and Variables ***/
  const { currentUser } = useAuth();
  /*** Processing ***/
  /*** Sub Components ***/
  const renderHeader = () => {
    return <div className='user_list_wrapper'>
      <div className='user_list_item'>
        <label className='user_list_text_cell'>
          {TEXT_USER_NAME}
        </label>
        <label className='user_list_text_cell'>
          {TEXT_LOGIN_ID}
        </label>
        <label className='user_list_text_cell'>
          {TEXT_CONTACT}
        </label>
        <label className='user_list_text_cell'>
          {TEXT_PHONE}
        </label>
        <label className='user_list_text_cell'>
          {TEXT_DESCRIPTION}
        </label>
        <label className='user_list_date_cell'>
          {TEXT_UPDATED_DATE}
        </label>
        <div className='user_list_action_cell' />
      </div>
      <div className='user_list_item_separator' />
    </div>;
  }
  const renderItem = (item) => {
    return <div className='user_list_wrapper' key={item.id}>
      <div className='user_list_item'>
        <label 
        onClick={()=>{props.onEditItem(item)}}
        className='user_list_text_cell text-link'>
          {item.name}
        </label>
        <label className='user_list_text_cell'>
          {item.email}
        </label>
        <label className='user_list_text_cell'>
          {item.contact}
        </label>
        <label className='user_list_text_cell'>
          {item.phone}
        </label>
        <label className='user_list_text_cell'>
          {item.description}
        </label>
        <label className='user_list_date_cell'>
          {item.updatedAt && item.updatedAt.split(' ')[0]}
        </label>
        <div className='user_list_action_cell'>
          <button 
          className='user_list_button_delete'
          disabled={item.id===currentUser.id || (currentUser.accountType===AccountType.MANAGER && item.accountType===AccountType.ADMIN)}
          onClick={()=>{props.onDeleteItem(item)}}
          title='ユーザー削除'>
            <FontAwesomeIcon icon={faTrash}/>
          </button>
        </div>
      </div>
      <div className='user_list_item_separator' />
    </div>;
  }
  /*** Event Handlers ***/
  /*** Main Render ***/
  return <BaseList
    items={props.items}
    renderHeader={renderHeader}
    renderItem={renderItem}
    curPage={props.curPage}
    onPageChange={props.onPageChange}/>;
}
UserList.propTypes = propTypes;
UserList.defaultProps = defaultProps;
export default UserList;