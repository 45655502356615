import {
  TEXT_ERROR_NULL,
  TEXT_ERROR_EMAIL_INVALID,
  TEXT_ERROR_EMAIL_EXIST,
  TEXT_ERROR_PASSWORD_NOT_MATCH,
  TEXT_ERROR_CONFIRM_PASSWORD_REQUIRED,
  TEXT_LOGIN_ID
} from 'constants/message';
import {checkEmail} from 'utils/function';
import {apiGetUserByEmail} from "apis/UserApi";

export const validateRequired = (item) => {
  return {
    required: item + TEXT_ERROR_NULL
  }
}

export const validateLoginId = {
  required: TEXT_LOGIN_ID + TEXT_ERROR_NULL,
  validate: {
    isEmail: (v) => checkEmail(v) || TEXT_ERROR_EMAIL_INVALID,
    emailExist: async (v) => {
      return !await apiGetUserByEmail(v.toLowerCase()) || TEXT_ERROR_EMAIL_EXIST
    }
  },
}

export const validateConfirmPassword = (password) => {
  return {
    required: TEXT_ERROR_CONFIRM_PASSWORD_REQUIRED,
    validate: (val) => {
      if (password !== val) {
        return TEXT_ERROR_PASSWORD_NOT_MATCH;
      }
    },
  }
}

export const validateContact = {
  required: false,
  validate: {
    isEmail: (v) => checkEmail(v) || TEXT_ERROR_EMAIL_INVALID
  },
}