import './SensorDetailList.css';
import PropTypes from 'prop-types';
import React from 'react';
import { HEADER, SENSOR_PROPERTIES } from 'utils/constanst';
import Icon, { IconList } from 'components/Icon';
import { useSensorGroups } from 'context/SensorGroupsContext';
import { useGeneralSetting } from 'context/GeneralSettingContext';
import { hexToRGB, modifiedValue } from 'utils/function';
import { LockState } from 'models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import iconMail from 'assets/svgicon/icon-mail-big.svg';
import iconMap from 'assets/svgicon/icon-map-big.svg';
import iconDelete from 'assets/svgicon/icon-delete.svg';
import { SENSOR_STATUS_ALERT } from 'constants/config';


export const TableHeader = ({ isValidationColumn, onRequestSort, order, orderBy }) => {

    const createSortHandler = (property, isSort) => () => {
      if (isSort) {
        onRequestSort(property);
      }
    }
    return (
      <div className='sensor_detail_list_flex-row sensor_detail_list_header'>
        {
          HEADER
          .filter((item) => isValidationColumn(item))
          .map((item, index) => (
            <div 
              key={index} 
              className='sensor_detail_list_flex-row sensor_detail_list_header-cell' 
              style={{ 
                '--width': item.width, 
                justifyContent: item.id.includes('icon') ? 'center' : 'flex-start', 
                columnGap: '5px', 
                cursor: item?.isSort ? 'pointer' : 'default',
              }}
              onClick={createSortHandler(item.id, item?.isSort)}
            >
              {item.label}
              { 
                item?.isSort ? 
                  <Icon icon={IconList.iconSort} containerStyle={orderBy === item.id ? '' : 'opacity'} iconStyle={order === 'asc' ? 'rotate-down' : ''}/> : 
                  <></>
              }
            </div>
          ))
        }
      </div>
    )
  }
  
  TableHeader.propTypes = {
    isValidationColumn: PropTypes.func,
    onRequestSort: PropTypes.func,
    order: PropTypes.string,
    orderBy: PropTypes.string,
  }
  
  TableHeader.defaultProps = {
    isValidationColumn: () => {},
    onRequestSort: () => {},
    order: 'asc',
    orderBy: SENSOR_PROPERTIES.MANAGE_ID,
  
  }
  
  const IconValue = {
    'iconMail': iconMail,
    'iconMap': iconMap,
    'iconDelete': iconDelete
  }
  
  export const TableRow = (props) => {
    const { sensorGroups } = useSensorGroups();
    const { generalSetting } = useGeneralSetting();
    const handleClickIcon = (key) => (e) => {
      e.stopPropagation();
      if (key === 'iconMail') {
        props.onSendMail(props.sensor);
      } else if (key === 'iconMap') {
        props.onFocusItem(props.sensor);
      } else if (key === 'iconDelete') {
        props.onDeleteItem(props.sensor);
      }
    }
  
    const handleClickSensor = () => {
      props.isManagementList ?
        props.onEditItem(props.sensor) :
        props.onViewItem(props.sensor);
    }
    const targetGroup = sensorGroups.find(g=>g.id===props.sensor.groupId);
    const alertMessage = targetGroup?.messages && targetGroup.messages.find(m=>m.value===SENSOR_STATUS_ALERT);

    return (
      <div className='sensor_detail_list_flex-row sensor_detail_list_row' onClick={handleClickSensor}>
        {
          HEADER
          .filter((item) => props.isValidationColumn(item))
          .map((item, index) => {
            if (item.id === 'status') {
              return (
                <div key={index} className='sensor_detail_list_flex-row sensor_detail_list_row-cell' style={{ '--width': item.width }}>
                  <label 
                  className={'card-info_value-text'} 
                  style={{ 
                    background: props.sensor?.statusValue?.color, 
                    color: props.sensor?.statusValue?.color ? hexToRGB(props.sensor?.statusValue?.color) >= 400 && 'black' : 'white',
                    minWidth:'unset', 
                    padding: '4px 8px' 
                  }}>
                    {props.sensor?.statusValue?.content}
                  </label>
                  {props.sensor.isLocked===LockState.LOCKED &&
                  generalSetting.alertOn && <>
                    &nbsp;
                    <label className={'card-info_value-text'} 
                    style={{ background: alertMessage?.color, 
                    minWidth:'unset', 
                    padding: '4px 8px' }}>
                      警報ラッチ
                    </label>
                  </>}
                </div>
              )
            }
            if (item.id.includes('icon')) {
              return (
                <div 
                  key={index} 
                  className='sensor_detail_list_flex-row sensor_detail_list_row-cell sensor_detail_list-icon' 
                  style={{ '--width': item.width }}
                  onClick={handleClickIcon(item.id)}
                >
                  { item.id === 'iconDelete' ? <FontAwesomeIcon icon={faTrash}/> : <img alt='icon' src={IconValue[item.id]} /> }
                </div>
              )
            }
  
            return (
              <div key={index} className='sensor_detail_list_row-cell' style={{ '--width': item.width }}>
                {modifiedValue(props.sensor[item.id], item.id)}
              </div>
            )
          })
        }
      </div>
    )
  }
  
  TableRow.propTypes = {
    sensor: PropTypes.object,
    onSendMail: PropTypes.func,
    onFocusItem: PropTypes.func,
    onViewItem: PropTypes.func,
    onEditItem: PropTypes.func,
    isManagementList: PropTypes.bool,
    isValidationColumn: PropTypes.func,
    onDeleteItem: PropTypes.func,
  }
  
  TableRow.defaultProps = {
    sensor: {},
    onSendMail: () => {},
    onFocusItem: () => {},
    onViewItem: () => {},
    onEditItem: () => {},
    isManagementList: false,
    isValidationColumn: () => {},
    onDeleteItem: () => {},
  }
  