import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import "./ModifyGeneralSettingForm.css";
import { DIALOG_ERROR_CONFIRM_TIME_LESS_THAN_SCAN_TIME, DIALOG_ERROR_GENERAL_ERROR_MESSAGE, DIALOG_ERROR_INVALID_CONFIRM_TIME, DIALOG_ERROR_INVALID_LAT, DIALOG_ERROR_INVALID_LNG, DIALOG_ERROR_INVALID_SCAN_TIME, DIALOG_MESSAGE_CONFIRM_DELETE_GROUP, DIALOG_MESSAGE_UPDATED_GENERAL_SETTING_SUCCESSFULLY, TEXT_BUTTON_UPDATE, TEXT_CREATE_NEW, TEXT_LATITUDE, TEXT_LONGITUDE, TEXT_MASTER_MANAGEMENT, TEXT_MESSAGE_MANAGEMENT, TEXT_ZOOM_RATE } from 'constants/message';
import { useDialog } from 'context/DialogContext';
import RangeSlider from 'components/RangeSlider';
import { SENSOR_MAX_LAT, SENSOR_MAX_LNG, SENSOR_MIN_LAT, SENSOR_MIN_LNG } from 'constants/config';
import Switch from 'components/Switch';

const propTypes={
  initSetting: PropTypes.object,
  onSubmit: PropTypes.func
};
const defaultProps={
  initSetting: {},
  onSubmit: (data)=>{}
};
const ModifyGeneralSettingForm = (props) => {
  /*** States and Variables ***/
  const { initSetting } = props;
  const {
    register, handleSubmit
  } = useForm();
  const { error } = useDialog();
  const [zoom, setZoom] = React.useState(initSetting.zoom || 10);
  const [alertOn, setAlertOn] = React.useState(false);
  /*** Processing ***/
  React.useEffect(()=>{
    setAlertOn(!!initSetting.alertOn);
  }, [initSetting])
  /*** Sub Components ***/
  /*** Event Handlers ***/
  const onSubmit = (data) => {
    const parsedScanTime = parseInt(data.scanTime);
    const parsedConfirmTime = parseInt(data.confirmTime);
    data.alertOn = alertOn;
    if (!isFinite(parsedScanTime) || isNaN(parsedScanTime) || parsedScanTime<1 || parsedScanTime>60) {
      error(DIALOG_ERROR_INVALID_SCAN_TIME);
      return;
    }
    if (!isFinite(parsedConfirmTime) || isNaN(parsedConfirmTime) || parsedConfirmTime<1 || parsedConfirmTime>1440) {
      error(DIALOG_ERROR_INVALID_CONFIRM_TIME);
      return;
    }
    if(parsedConfirmTime<parsedScanTime) {
      error(DIALOG_ERROR_CONFIRM_TIME_LESS_THAN_SCAN_TIME);
      return;
    }
    data.scanTime = parsedScanTime;
    data.confirmTime = parsedConfirmTime;
    const parsedLat = parseFloat(data.lat);
    const parsedLng = parseFloat(data.lng);
    if (isNaN(data.lat) || !isFinite(parsedLat) || isNaN(parsedLat) || parsedLat<SENSOR_MIN_LAT || parsedLat>SENSOR_MAX_LAT) {
      error(DIALOG_ERROR_INVALID_LAT);
      return;
    }
    if (isNaN(data.lng) || !isFinite(parsedLng) || isNaN(parsedLng) || parsedLng<SENSOR_MIN_LNG || parsedLng>SENSOR_MAX_LNG) {
      error(DIALOG_ERROR_INVALID_LNG);
      return;
    }
    data.lat = parsedLat;
    data.lng = parsedLng;
    data.zoom = parseInt(zoom);
    props.onSubmit(data);
  }
  /*** Main Render ***/
  return <form 
  onSubmit={handleSubmit(onSubmit)}
  className='modify_general_setting_form'>
    <div className='modify_general_setting_form_item_row'>
      <label className='modify_general_setting_form_item_key'>
        {`センサ受信間隔 (分)`}
      </label>
      <div className='modify_general_setting_form_item_value'>
        <input 
        type="number"
        defaultValue={initSetting.scanTime || 3}
        min={1}
        max={60}
        step={1}
        style={{textAlign: 'right'}}
        {...register('scanTime')} />
      </div>
    </div>
    <div className='modify_general_setting_form_item_row'>
      <label className='modify_general_setting_form_item_key'>
        {`異常検知時間 (分)`}
      </label>
      <div className='modify_general_setting_form_item_value'>
        <input 
        type="number"
        defaultValue={initSetting.confirmTime || 30}
        min={1}
        max={1440}
        step={1}
        style={{textAlign: 'right'}}
        {...register('confirmTime')} />
      </div>
    </div>
    <div className='modify_general_setting_form_item_row'>
      <label className='modify_general_setting_form_item_key'>
        アプリタイトル
      </label>
      <div className='modify_general_setting_form_item_value'>
        <input 
        type="text"
        defaultValue={initSetting.appTitle || '冠水監視'}
        {...register('appTitle')} />
      </div>
    </div>
    <div className='modify_general_setting_form_item_row'>
      <label className='modify_general_setting_form_item_key'>
        APIキー
      </label>
      <div className='modify_general_setting_form_item_value'>
        <input 
        type="password"
        defaultValue={initSetting.apiAuth || ''}
        {...register('apiAuth')} />
      </div>
    </div>
    <div className='modify_general_setting_form_item_row' >
      <label className='modify_general_setting_form_item_key'>
        警報の保持・復旧
      </label>
      <div className='modify_general_setting_form_item_value'>
        <Switch 
        checked={alertOn}
        onChange={setAlertOn}/>
      </div>
    </div>
    <div style={{ width: '512px', border: 'solid 1px #707070', margin: '10px 0'}} />
    <div className='modify_general_setting_form_item_row'>
      センサマップ画面初期表示設定
    </div>
    <div className='modify_general_setting_form_item_row'>
      <label className='modify_general_setting_form_item_key'>
        {TEXT_LATITUDE}
      </label>
      <div className='modify_general_setting_form_item_value'>
        <input 
        type='text'
        style={{textAlign: 'right'}}
        defaultValue={initSetting.lat || 36.83763081714207}
        {...register('lat')} />
      </div>
    </div>
    <div className='modify_general_setting_form_item_row'>
      <label className='modify_general_setting_form_item_key'>
        {TEXT_LONGITUDE}
      </label>
      <div className='modify_general_setting_form_item_value'>
        <input 
        type='text'
        style={{textAlign: 'right'}}
        defaultValue={initSetting.lng || 139.3154172350928}
        {...register('lng')} />
      </div>
    </div>
    <div className='modify_general_setting_form_item_row'>
      <label className='modify_general_setting_form_item_key'>
        {`${TEXT_ZOOM_RATE}(${zoom})`}
      </label>
      <div className='modify_general_setting_form_item_value'>
        <RangeSlider 
        min={1}
        max={18}
        step={0.5}
        defaultValue={zoom}
        value={zoom}
        onChange={setZoom}/>
      </div>
    </div>
    <div style={{ width: '512px', border: 'solid 1px #707070', margin: '0 0 20px'}} />
    <div className='modify_general_setting_form_item_row'>
      <button 
      className='button_active'>
        {TEXT_BUTTON_UPDATE}
      </button>
    </div>
  </form>;
}
ModifyGeneralSettingForm.propTypes = propTypes;
ModifyGeneralSettingForm.defaultProps = defaultProps;
export default ModifyGeneralSettingForm;