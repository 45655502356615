import './SensorListHeader.css';
import iconListing from 'assets/svgicon/icon-listing.svg';
import iconClose from 'assets/svgicon/icon-close-blue.svg';
import React from 'react';
import Searchbar from 'components/Searchbar';
import Selection from 'components/Selections';
import { TEXT_SENSOR_LIST, TEXT_SENSOR_MANAGEMENT } from 'constants/message';
import PropTypes from 'prop-types';
import Icon, { IconList } from 'components/Icon';

const DEFAULT_FILTER_STATUS = 'すべてのステータス';
const initValue = {
    values: [],
    filterValues: [],
    option: DEFAULT_FILTER_STATUS,
}

const SensorListHeader = ({ initSensors, setSensorList, isManagementPage }) => {
    
    const [keyword, setKeyword] = React.useState('');
    const [preKeyword, setPreKeyword] = React.useState('');
    const [value, setValue] = React.useState(initValue);
    const [result, setResult] = React.useState([]);

    const [groupStatus, setGroupStatus] = React.useState([DEFAULT_FILTER_STATUS]);

    React.useEffect(() => {
        setValue({
            values: result,
            filterValues: result,
            option: DEFAULT_FILTER_STATUS,
        })
    }, [result]);

    React.useEffect(() => {
        setSensorList(value.filterValues);
    }, [value.filterValues]);

    const refreshFilter = () => {
        setValue((prev) => ({
            ...prev,
            filterValues: prev.values,
            option: DEFAULT_FILTER_STATUS,
        }))
    }

    return (
        <div className='sensor-list_flex-row sensor-list_header'>
            <div className='sensor-list_flex-row header-title'>
                <Icon icon={IconList.iconListing} />
                <label className='header-text'>{isManagementPage ? TEXT_SENSOR_MANAGEMENT : TEXT_SENSOR_LIST}</label>
            </div>
            <div className='sensor-list_flex-row sensor-list-searchbar'>
                <Searchbar
                    initSensors={initSensors} 
                    setSensorList={setResult} 
                    placeholder='センサーを検索'
                    setKeyword={setKeyword} 
                    setPreKeyword={setPreKeyword}
                    keyword={keyword}
                    preKeyword={preKeyword}
                    icon={IconList.iconCloseBlue}
                    isRefreshInit={true}
                    setGroupStatus={setGroupStatus}
                    defaultFilterStatus={[DEFAULT_FILTER_STATUS]}
                />
                {
                    !isManagementPage &&
                    <Selection 
                        defaultValue={DEFAULT_FILTER_STATUS} 
                        options={groupStatus} 
                        isReload={false} 
                        setFilterValues={setValue} 
                        filterValues={value} 
                        refreshFilter={refreshFilter}
                    />
                }
            </div>
        </div>
    )
}

SensorListHeader.propTypes = {
    initSensors: PropTypes.array,
    setSensorList: PropTypes.func,
    isManagementPage: PropTypes.bool,
}

SensorListHeader.defaultProps = {
    initSensors: [],
    setSensorList: () => {},
    isManagementPage: false,
}

export default SensorListHeader;

