import React from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import "./ModifyUserForm.css";
import {
  TEXT_BUTTON_CREATE,
  TEXT_LOGIN_ID,
  TEXT_PASSWORD,
  TEXT_USER_NAME,
  TEXT_ACCOUNT_TYPE,
  TEXT_CONTACT,
  TEXT_PHONE,
  TEXT_DESCRIPTION,
  TEXT_BUTTON_UPDATE,
  TEXT_CANCEL,
  TEXT_CONFIRM_PASSWORD,
  TEXT_CHANGE_PASSWORD,
  TEXT_ROLE_USER,
  TEXT_ROLE_MANAGER,
  TEXT_ROLE_ADMIN,
} from "constants/message";
import {validateConfirmPassword, validateContact, validateLoginId, validateRequired} from "utils/validator";
import {AccountType} from 'models';
import { useAuth } from 'context/AuthContext';

const FORM_ITEMS = [
  {name: 'name', displayName: TEXT_USER_NAME, notNull: true, inputType: 'text'},
  {name: 'email', displayName: TEXT_LOGIN_ID, notNull: true, inputType: 'text'},
  {name: 'password', displayName: TEXT_PASSWORD, notNull: true, inputType: 'password'},
  {name: 'confirmPassword', displayName: TEXT_CONFIRM_PASSWORD, notNull: true, inputType: 'password'},
  {name: 'accountType', displayName: TEXT_ACCOUNT_TYPE, notNull: true, inputType: 'select'},
  {name: 'contact', displayName: TEXT_CONTACT, notNull: false, inputType: 'text'},
  {name: 'phone', displayName: TEXT_PHONE, notNull: false, inputType: 'text'},
  {name: 'description', displayName: TEXT_DESCRIPTION, notNull: false, inputType: 'text'}
];

const propTypes = {
  user: PropTypes.object,
  onCancel: PropTypes.func,
  onModifyUser: PropTypes.func,
  onChangePassword: PropTypes.func
};
const defaultProps = {
  user: {},
  onCancel: ()=>{},
  onModifyUser: (newUserData) => {},
  onChangePassword: ()=>{}
};

const ModifyUserForm = (props) => {
  /*** States and Variables ***/
  const {
    register, handleSubmit, formState: {errors}, watch
  } = useForm();
  const initUser = props.user;
  const { currentUser } = useAuth();
  /*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  const onModifyUser = (data) => {
    if (initUser.email) data.email = initUser.email;
    props.onModifyUser(data);
  }
  /*** Main Render ***/
  return <form 
  className='modify_user_form_container'
  onSubmit={handleSubmit(onModifyUser)}>
    {FORM_ITEMS.map(item=>{
      if (initUser.id && (item.name==='password' || item.name==='confirmPassword')) return null;
      return <>
        <div
          key={'item' + item.name}
          className='modify_user_form_item_row'>
          <div className='modify_user_form_item_key'>
            {item.displayName}
            {item.notNull && <label className='modify_user_form_item_notnull'>*</label>}
          </div>
          <div className={`modify_user_form_item_value ${errors[item.name] ? 'error' : ''}`}>
            {item.inputType === 'select' ?
              <select
                disabled={currentUser.id===initUser.id}
                defaultValue={initUser[item.name]}
                className='modify_user_form_select'
                {...(currentUser.id!==initUser.id && register(item.name, validateRequired(item.displayName)))}>
                <option value={AccountType.USER}>{TEXT_ROLE_USER}</option>
                <option value={AccountType.MANAGER}>{TEXT_ROLE_MANAGER}</option>
                {currentUser.accountType===AccountType.ADMIN 
                && <option value={AccountType.ADMIN}>{TEXT_ROLE_ADMIN}</option>}
              </select> :
              <input
                autoComplete='new-password'
                defaultValue={initUser[item.name]}
                type={item.inputType}
                disabled={item.name === 'email' && initUser.id}
                {...(
                  (item.name === 'email' && register(item.name, initUser.id ? {required: false} : validateLoginId)) ||
                  (item.name === 'confirmPassword' && register(item.name, validateConfirmPassword(watch('password')))) ||
                  // (item.name === 'contact' && register(item.name, validateContact)) ||
                  (item.notNull && register(item.name, validateRequired(item.displayName))) ||
                  register(item.name)
                )}/>}
          </div>
        </div>
        {errors[item.name] &&
        <label className='modify_user_form_error'>
          {errors[item.name].message}
        </label>}
      </>
    })}
    <div className='modify_user_form_buttons_container'>
      <button
        className='modify_user_form_button button_active'>
        {initUser.id ? TEXT_BUTTON_UPDATE : TEXT_BUTTON_CREATE}
      </button>
      {initUser.id && <button
        type='button'
        className='modify_user_form_button button_active'
        onClick={props.onChangePassword}>
        {TEXT_CHANGE_PASSWORD}
      </button>}
      <button
        className='button_cancel'
        onClick={props.onCancel}>
        {TEXT_CANCEL}
      </button>
    </div>
  </form>;
}
ModifyUserForm.propTypes = propTypes;
ModifyUserForm.defaultProps = defaultProps;
export default ModifyUserForm;