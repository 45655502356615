import React from 'react';
import PropTypes from 'prop-types';
import "./SensorGroupList.css";
import BaseList from '../../../components/BaseList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { TEXT_GROUP_ID, TEXT_GROUP_NAME, TEXT_UPDATED_DATE } from 'constants/message';

const propTypes={
  items: PropTypes.array,
  onClick: PropTypes.func,
  onDelete: PropTypes.func
};
const defaultProps={
  items: [],
  onClick: (item) => {},
  onDelete: (item) => {} 
};
const SensorGroupList = (props) => {
  /*** States and Variables ***/
  /*** Processing ***/
  /*** Sub Components ***/
  const renderHeader = () => {
    return <>
    <div className='sensor_group_list_item'>
      <div className='sensor_group_list_name_cell'>
        {TEXT_GROUP_NAME}
      </div>
      <div className='sensor_group_list_date_cell'>
        {TEXT_UPDATED_DATE}
      </div>
      <div className='sensor_group_list_action_cell'>
      </div>
    </div>
    <div className='sensor_group_list_separator' />
    </>;
  }
  const renderItem = (item) => {
    return <>
    <div 
    key={"item" + item.id}
    className='sensor_group_list_item'>
      <div 
      className='sensor_group_list_name_text'
      onClick={()=>{props.onClick(item)}}>
        {item.name}
      </div>
      <div className='sensor_group_list_date_cell'>
        {item.updatedAt.split(' ')[0]}
      </div>
      <div className='sensor_group_list_action_cell'>
        <button 
        className='sensor_group_list_button_delete'
        onClick={()=>{props.onDelete(item)}}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
    <div 
    key={"separator" + item.id}
    className='sensor_group_list_separator' />
    </>;
  }
  /*** Event Handlers ***/
  /*** Main Render ***/
  return <BaseList
  items={props.items}
  renderHeader={renderHeader}
  renderItem={renderItem} />;
}
SensorGroupList.propTypes = propTypes;
SensorGroupList.defaultProps = defaultProps;
export default SensorGroupList;