import React from 'react';
import PropTypes from 'prop-types';
import "./Switch.css";

const propTypes={
  checked: PropTypes.bool,
  onChange: PropTypes.func
};
const defaultProps={
  checked: false,
  onChange: ()=>{}
};
const Switch = (props) => {
  /*** States and Variables ***/
  /*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  /*** Main Render ***/
  return <label className='switch_container'>
    <input 
    type="checkbox"
    className='switch_checkbox'
    checked={props.checked}
    onChange={(e)=>{props.onChange(e.target.checked)}}/>
    <span 
    className='switch_slider'/>
  </label>;
}
Switch.propTypes = propTypes;
Switch.defaultProps = defaultProps;
export default Switch;