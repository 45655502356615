import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { Auth } from "aws-amplify";
import { apiGetUserByEmail } from 'apis/UserApi';

const Context = createContext({});

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    getCurrentAuth();
  }, [])

  const getCurrentAuth = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch((err) => {});
    const res = await apiGetUserByEmail(cognitoUser.attributes.email);
    setCurrentUser(res);
  }

  return (
    <Context.Provider value={{ currentUser, setCurrentUser}}>
      {children}
    </Context.Provider>
  );
}

export const useAuth = () => useContext(Context);
