import { API } from "aws-amplify"
import { AUTH_MODE } from "../constants/config";
import { adminSendMail } from "../graphql/mutations"
import { listUsers } from "../graphql/queries";

export const apiSendMail = async (subject, text) => {
  try {
    // get all email addresses
    let nextToken = null;
    let to = [];
    while(true) {
      const listUsersRes = await API.graphql({
        query: listUsers,
        variables: {
          nextToken
        },
        authMode: AUTH_MODE
      });
      const users = listUsersRes.data.listUsers.items;
      to.push(...users.map(user=>user.contact));
      nextToken = listUsersRes.data.listUsers.nextToken;
      if (!nextToken) break;
    }
    to = to.filter(email=>!!email);
    to = [...new Set(to)];
    // send mail
    if (to.length===0) {
      return {
        success: true
      }
    }
    const sendMailRes = await API.graphql({
      query: adminSendMail,
      variables: {
        input: {
          type: 'sendMail',
          to,
          subject,
          text
        }
      },
      authMode: AUTH_MODE
    });
    return sendMailRes.data.adminSendMail;
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}