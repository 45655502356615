import React from 'react';
import PropTypes from 'prop-types';
import './Popup.css';
import { useOutsideClick } from 'utils/function';
import Draggable from 'react-draggable';

const Popup = ({ isOpen, children, setIsOpen, styles, isDrag }) => {

    const handleClose = () => setIsOpen(false);
    const isValidation = () => isOpen;

    const ref = useOutsideClick(handleClose, [isOpen], isValidation);

    if (!isOpen) return <></>

    return (
        <>
            {
                isDrag ?
                <Draggable >
                    <div ref={ref} className='layout' style={styles}>
                        {children}
                    </div>
                </Draggable> :
                <div ref={ref} className='layout' style={styles}>
                    {children}
                </div>
            }
        </>
    )
}

Popup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    setIsOpen: PropTypes.func,
    styles: PropTypes.object,
}

Popup.defaultProps = {
    isOpen: false,
    setIsOpen: (state) => {},
    styles: {},
}

export default Popup;