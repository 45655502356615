import { DATE_FORMAT, DATE_TIME_FORMAT, MOMENT_FORMAT, SENSOR_LOCATION_TEMPLATE, SENSOR_NAME_TEMPLATE } from 'constants/config';
import React from 'react';
import moment from 'moment';
import { SENSOR_PROPERTIES, UNDEFINED_TEXT } from './constanst';
import { useSensorGroups } from 'context/SensorGroupsContext';

export const convertDateFormat = (datetime) => {
  return moment(datetime).format(DATE_FORMAT);
}

export const convertDateTimeFormat = (datetime) => {
  return moment(datetime).format(DATE_TIME_FORMAT);
}

export const convertMomentFormat = (datetime) => {
  return moment(datetime).format(MOMENT_FORMAT);
}

export const checkEmail = (email) => {
  const regex = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return regex.test(email.toLowerCase());
};

/**
 * convert fullwidth english ( some common roman characters ) to halfwidth
 * @param {string} s 
 */
const convertFullWidthEnglishToHalfWidth = (s) => {
  let result = "";
  let code = 0;
  let delta = 0xFF01 - 0x0021;
  if(s){
    for (let i=0;i<s.length;i++){
      code = s.charCodeAt(i);
      if(0xFF01<=code && code<0xFF5F){
        result += String.fromCharCode(code - delta);
      }
      else{
        result += s.charAt(i);
      }
    }
  }
  return result; 
}

/**
 * replace all chars in s that exists in sourceTemplate by equivalent chars in targetTemplate
 * ex: s = "ABCEDFG", targetTemplate = "AC", targetTemplate = "13"
 * replace A with 1, C with 3 then return : "1B3DEFG"
 * @param {string} s 
 * @param {string} sourceTemplate 
 * @param {string} targetTemplate 
 */
const convertStringByTemplate = (s, sourceTemplate, targetTemplate) => {
  let result = "";
  for(let i=0; i<s.length; i++){
    let index = sourceTemplate.indexOf(s[i]);
    if(index>=0){
      result += targetTemplate[index];
    }
    else{
      result += s[i];
    }
  }
  return result;
}


/**
 * convert full will to halfwidth
 * @param {string} s 
 */
const convertFullWidthKatakataToHalfWidth = (s) => {
  const fullwidth = "。「」、・ヲァィゥェォャュョッーアイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワン゛゜"
  const halfwidth = "｡｢｣､･ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝﾞﾟ";
  return convertStringByTemplate(s, fullwidth, halfwidth); 
}

/**
 * convert small katakana to large katakaba 
 * @param {string} s 
 */
const convertSmallKatakanaToLargeKatakana = (s) => {
  const smallKatakana = "ｧｨｩｪｫｬｭｮｯ";
  const largeKatakana = "ｱｲｳｴｵﾔﾕﾖﾂ"
  return convertStringByTemplate(s, smallKatakana, largeKatakana); 
}

/**
 * convert fullwill to halfwidth, lower case, replace tab with 4 spaces
 * @param {string} s 
 */
export const standardizeString = (s) => {
  let result = convertFullWidthEnglishToHalfWidth(s);
  result = convertFullWidthKatakataToHalfWidth(result);
  result = convertSmallKatakanaToLargeKatakana(result);
  result = result.toLowerCase();
  return result;
}

/**
 * useHook
 */
export const useOutsideClick = (callback, state = [], isValidation = () => true) => {
  const ref = React.useRef(null);

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  }

  React.useEffect(() => {
    if (isValidation()) {
      document.addEventListener('mousedown', handleClick);
      return () => document.removeEventListener('mousedown', handleClick);
    }
  }, [...state]);

  return ref;
}

export const useAllSensors = (initList = [], isSearch = false) => {
  const [allSensors, setAllSensors] = React.useState(initList);
  const { sensorGroups } = useSensorGroups();

  const handleModifiedInfo = React.useCallback((info, isSearched) => {
    const targetGroup = sensorGroups.find(g => g.id === info.groupId);
        const messageInfo = targetGroup && targetGroup.messages && targetGroup.messages[info.status];
        const statusValue = {
            color: '#000',
            content: UNDEFINED_TEXT, 
          };
        let status = UNDEFINED_TEXT;
        let message = UNDEFINED_TEXT;
        if (messageInfo && info.status >= 0) {
            statusValue.color = messageInfo.color;
            statusValue.content = messageInfo.status;
            status = messageInfo.status;
            message = messageInfo.message.replaceAll(SENSOR_NAME_TEMPLATE, '').replaceAll(SENSOR_LOCATION_TEMPLATE, '');
        } 
        if (isSearched) {
          const sensorInfo = [
            info.managementId,
            info.name,
            info.tx,
            status,
            message,
          ].map((info) => standardizeString(info)).join('');
          return {
              search: sensorInfo,
              statusValue,
              message,
          };
        } else {
          return {
            statusValue, 
            message,
          }
        }
        
  }, [sensorGroups])
  
  React.useEffect(() => {
    if (initList.length) {
      const sensors = initList.map((item) => {
        const { search, statusValue, message } = handleModifiedInfo(item, isSearch);
        return {
          ...item,
          statusValue,
          message,
          search: search ? search : null,
        }
      });
      setAllSensors(sensors);
    } else {
      setAllSensors([]);
    }
  }, [isSearch, initList, handleModifiedInfo]);
  
  return [allSensors];
}

/**
 * modified the value to correct the format
 * @param {any} value
 * @param {string} id
 */
export const modifiedValue = (value, id) => {
  if (id === SENSOR_PROPERTIES.CREATEDAT) {
    return value ? value.split(' ')[0] : UNDEFINED_TEXT;
  }
  if (typeof value === 'string') {
    if (id === SENSOR_PROPERTIES.ID) {
      return value.split('+')[0];
    }
    return value.trim() ? value : UNDEFINED_TEXT;
  } else if (typeof value === 'number') {
    return value.toFixed(3);
  } else if (typeof value === 'undefined') {
    return UNDEFINED_TEXT;
  }
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    } 
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// useReducer for sensorList
export const reducer = (state, action) => {
  const { sensor, list, type } = action;

  switch (type) {
    case 'init': 
      return list;
    case 'delete':
      const deleteIndex = state.findIndex(item => item.id === sensor.id);
      if (deleteIndex < 0) return state;
      const newState = [...state];
      newState.splice(deleteIndex, 1);
      return newState;
    case 'add':
      return [...state, sensor];
    case 'update':
      const updateIndex = state.findIndex(item => item.id === sensor.id);
      if (updateIndex < 0) return state;
      const updateState = [...state];
      updateState.splice(updateIndex, 1, sensor);
      return updateState;
    default:
      return state;
  }
}


//convert hex to rgba 
const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)

const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, "g"))

const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16)

export const hexToRGB = (hex) => {
    if (!isValidHex(hex)) {throw new Error("Invalid HEX")}
    const chunkSize = Math.floor((hex.length - 1) / 3);
    const hexArr = getChunksFromString(hex.slice(1), chunkSize);
    const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
    return (r + g + b);
}