import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LoginPage from './pages/Login/LoginPage';
import PageWrapper from './pages/PageWrapper';
import AuthProvider from './context/AuthContext'
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import GeneralSettingProvider from './context/GeneralSettingContext';
import SensorGroupsProvider from './context/SensorGroupsContext';
import DialogProvider from './context/DialogContext';
Amplify.configure(awsconfig);

function App() {
  /**** States and Variables ****/
  /**** Event Handlers ****/
  /**** Main Render *****/
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path='/top'
            element={
              <AuthProvider>
                <GeneralSettingProvider>
                  <SensorGroupsProvider>
                    <DialogProvider>
                      <PageWrapper />
                    </DialogProvider>
                  </SensorGroupsProvider>
                </GeneralSettingProvider>
              </AuthProvider>
            }
          />
          <Route path='*' element={
            <GeneralSettingProvider>
              <LoginPage />
            </GeneralSettingProvider>
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
