import React from 'react';
import PropTypes from 'prop-types';
import "./ModifyGroupForm.css";
import { TEXT_GROUP_NAME } from 'constants/message';
import { useForm } from 'react-hook-form';
import { SENSOR_STATUS_ABNORMAL, SENSOR_STATUS_ALERT, SENSOR_STATUS_ATTENTION, SENSOR_STATUS_NORMAL } from 'constants/config';

const SENSOR_STATUS_NORMAL_VALUE = 13;
const SENSOR_STATUS_ALERT_VALUE = 11;
const SENSOR_STATUS_ATTENTION_VALUE = 7;

const propTypes={
  group: PropTypes.object,
  onModify: PropTypes.func,
  onCancel: PropTypes.func
};
const defaultProps={
  group: {},
  onModify: (newGroup)=>{},
  onCancel: ()=>{}
};
const ModifyGroupForm = (props) => {
  /*** States and Variables ***/
  const {
    register, handleSubmit, formState: { errors }
  } = useForm();
  const initGroup = props.group;
  /*** Processing ***/
  /*** Sub Components ***/
  const renderMessageSetting = () => {
    return <>
      <div className='modify_group_form_message_setting_row'>
        <div className='modify_group_form_message_setting_bit_cell'>
          接点1
        </div>
        <div className='modify_group_form_message_setting_bit_cell'>
          接点2
        </div>
        <div className='modify_group_form_message_setting_bit_cell'>
          接点3
        </div>
        <div className='modify_group_form_message_setting_bit_cell'>
          接点4
        </div>
        <div className='modify_group_form_message_setting_status_cell modify_group_label'>
          ステータス
        </div>
        <div className='modify_group_form_message_setting_color_cell modify_group_label'>
          色
        </div>
        <div className='modify_group_form_message_setting_message_cell'>
          メッセージ
        </div>
      </div>
      <div className='modify_group_form_message_setting_separator'/>
      {Array.from({length:16}).map((_, i)=>{
        const defaultStatus = (i===SENSOR_STATUS_NORMAL_VALUE && SENSOR_STATUS_NORMAL) || (i===SENSOR_STATUS_ALERT_VALUE && SENSOR_STATUS_ALERT) || (i===SENSOR_STATUS_ATTENTION_VALUE && SENSOR_STATUS_ATTENTION) || SENSOR_STATUS_ABNORMAL;
        const targetMessage = initGroup?.messages && initGroup.messages[i];
        let statusText = targetMessage?.status;
        statusText = statusText || statusText===''? statusText: defaultStatus;
        const defaultStatusVal = targetMessage?.value || statusText;
        return <>
          <div 
          key={i}
          className='modify_group_form_message_setting_row'>
            <div className='modify_group_form_message_setting_bit_cell'>
              {(i&0b1000)>>3}
            </div>
            <div className='modify_group_form_message_setting_bit_cell'>
              {(i&0b0100)>>2}
            </div>
            <div className='modify_group_form_message_setting_bit_cell'>
              {(i&0b0010)>>1}
            </div>
            <div className='modify_group_form_message_setting_bit_cell'>
              {i&0b0001}
            </div>
            <div className='modify_group_form_message_setting_status_cell'>
              <select 
              className='modify_group_form_message_setting_status_select'
              defaultValue={defaultStatusVal}
              {...register(`messages.${i}.value`)}>
                <option value={SENSOR_STATUS_ABNORMAL}>{SENSOR_STATUS_ABNORMAL}</option>
                <option value={SENSOR_STATUS_NORMAL}>{SENSOR_STATUS_NORMAL}</option>
                <option value={SENSOR_STATUS_ATTENTION}>{SENSOR_STATUS_ATTENTION}</option>
                <option value={SENSOR_STATUS_ALERT}>{SENSOR_STATUS_ALERT}</option>
              </select>
              <input 
              type='text'
              className='modify_group_form_status_input'
              defaultValue={statusText}
              {...register(`messages.${i}.status`)}/>
            </div>
            <div className='modify_group_form_message_setting_color_cell'>
              <input 
              type="color"
              className='modify_group_form_color_input'
              defaultValue={(initGroup?.messages && initGroup.messages[i] && initGroup.messages[i].color) || '#000000'}
              {...register(`messages.${i}.color`)}/>
            </div>
            <div className='modify_group_form_message_setting_message_cell'>
              <input 
              type='text'
              className='modify_group_form_message_input'
              defaultValue={(initGroup?.messages && initGroup.messages[i] && initGroup.messages[i].message) || ''}
              {...register(`messages.${i}.message`)}/>
            </div>
          </div>
          <div 
          key={16*2+i}
          className='modify_group_form_message_setting_separator'/>
        </>
        })}
      <div className='modify_group_form_message_setting_row'>
        ％sensor:：センサ名称　%place：設置場所に置換します。
      </div>
      <div className='modify_group_form_message_setting_separator'/>
    </>
  }
  /*** Event Handlers ***/
  const onSubmit = (data) => {
    if (initGroup.id) {
      data.id = initGroup.id;
    }
    props.onModify(data);
  }
  /*** Main Render ***/
  return <form 
  onSubmit={handleSubmit(onSubmit)}
  className='modify_group_form_container'>
    <div className='modify_group_form_item_row'>
      <label className='modify_group_form_item_key'>
        {TEXT_GROUP_NAME}
      </label>
      <div className='modify_group_form_item_value'>
        <input 
        type='text'
        defaultValue={initGroup.name}
        {...register('name')}/>
      </div>
    </div>
    <div className='modify_group_form_item_row'>
      メッセージ設定
    </div>
    {renderMessageSetting()}
    <div className='modify_group_form_button_container'>
      <button className='modify_group_form_button button_active'>
        {props.group?.id? "更新": "作成"}
      </button>
      <button 
      type='button'
      className='modify_group_form_button button_cancel'
      onClick={props.onCancel}>
        キャンセル
      </button>
    </div>
  </form>;
}
ModifyGroupForm.propTypes = propTypes;
ModifyGroupForm.defaultProps = defaultProps;
export default ModifyGroupForm;