import '../MapPage.css';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSensorGroups } from 'context/SensorGroupsContext';
import { useGeneralSetting } from 'context/GeneralSettingContext';
import { SENSOR_INFO, SENSOR_PROPERTIES, UNDEFINED_TEXT } from 'utils/constanst';
import { SENSOR_LOCATION_TEMPLATE, SENSOR_NAME_TEMPLATE, SENSOR_STATUS_ALERT } from 'constants/config';
import { LockState } from 'models';
import Icon, { IconList } from 'components/Icon';
import { hexToRGB } from 'utils/function';

const SensorInfoCard = ({ sensor, width, isDrag }) => {
    const [info, setInfo] = React.useState(sensor);
    const { sensorGroups } = useSensorGroups();
    const { generalSetting } = useGeneralSetting();
    const modifiedSensor = useCallback((sensorInfo) => {
      const targetGroup = sensorGroups.find( g=> g.id === sensorInfo.groupId);
      const messageInfo = targetGroup?.messages && targetGroup.messages[sensorInfo.status];
      const status = {
        color: '#000',
        content: UNDEFINED_TEXT, 
      };
      let message = UNDEFINED_TEXT;
      if (messageInfo && sensorInfo.status >= 0) {
        status.color = messageInfo.color;
        status.content = messageInfo.status;
        message = messageInfo.message.replaceAll(SENSOR_NAME_TEMPLATE, sensorInfo.name).replaceAll(SENSOR_LOCATION_TEMPLATE, sensorInfo.location);
      }
      return {
        statusValue: status, 
        message,
      }
    }, [sensorGroups]);
  
    React.useEffect(() => {
      if (sensor) {
        if (sensor.statusValue !== undefined) {
          return setInfo(sensor);
        } else {
          const { statusValue, message } = modifiedSensor(sensor);
          setInfo({
            ...sensor,
            statusValue,
            message,
          })
        } 
      }
    }, [sensor, modifiedSensor]);
  
    return (
      <div className={`map-page_flex-row map_page_card-content ${isDrag ? 'dragble' : ''}`} >
          <div className='map-page_flex-row map_page_card-content-container'>
            {
              SENSOR_INFO.map((item, index) => {
                if (item.key === SENSOR_PROPERTIES.STATUS) {
                  return (
                    <div key={index} className='map-page_flex-row map_page_card-info'>
                      <p className='normal_text card-info_label' style={{ '--width': width }}>{item.label}</p>
                      <p className='map-page_flex-row normal_text card-info_value'>
                        {
                          info?.statusValue?.content && 
                                <span 
                                    className={'card-info_value-text'} 
                                    style={{ 
                                        background: info?.statusValue?.color,
                                        color: hexToRGB(info?.statusValue?.color) >= 400 ? 'black' : 'white' 
                                    }}
                                >
                                    {info?.statusValue?.content}
                                </span>
                        }
                        {
                          sensor.isLocked === LockState.LOCKED && 
                          generalSetting.alertOn &&
                          <span className='card-info_value-text' style={{ background: '#ff0000'}}>警報ラッチ</span>
                        }
                      </p>
                    </div>
                  )
                }
                return (
                  <div key={index} className='map-page_flex-row map_page_card-info'>
                    <p className='normal_text card-info_label' style={{ '--width': width }}>{item.label}</p>
                    <p className='normal_text card-info_value'>{info[item.key]}</p>
                  </div>
                )
              })
            }
          </div>
      </div>
    )
  }
  
  SensorInfoCard.propTypes = {
    sensor: PropTypes.object.isRequired,
    width: PropTypes.string,
    isDrag: PropTypes.bool,
  }
  SensorInfoCard.defaultProps = {
    sensor: {},
    width: '',
    isDrag: false,
  }
  
const PopupContent = ({ setIsOpen, sensors, onUnlockSensor, setSensor, onViewSensorDetail, onSendMail }) => {
    const handleClose = () => {
      setIsOpen(false);
      setSensor(null);
    }
    return (
      <div className='map_page_sensor_card'>
        <div className='map_page_close_btn_container' >
          <div className='map-page_flex-row map_page_close_btn' onClick={handleClose} onTouchStart={handleClose}>
            <Icon icon={IconList.iconClose} containerStyle='icon_container' />
            <p className='close_btn-text'>閉じる</p>
          </div>
        </div>
        <div className='map_page_sensor_container'>
          {
            sensors?.map((sensor, index) => (
              <div key={index}>
                <SensorInfoCard sensor={sensor} isDrag={true} />
                <FunctionCard sensor={sensor} onUnlock={onUnlockSensor} onViewSensorDetail={onViewSensorDetail} onSendMail={onSendMail} />
              </div>
            ))
          }
        </div>
      </div>
    )
  }
  
  PopupContent.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    sensors: PropTypes.array,
    onUnlockSensor: PropTypes.func,
    onViewSensorDetail: PropTypes.func,
    onSendMail: PropTypes.func,
  }
  
  PopupContent.defaultProps = {
    setIsOpen: () => {},
    sensors: [],
    onUnlockSensor: () => {},
    onViewSensorDetail: (sensor) => {},
    onSendMail: (sensor) => {},
  }
  
const FunctionCard = ({ sensor, isReload, onUnlock, onViewSensorDetail, onSendMail }) => {
    const { generalSetting } = useGeneralSetting();
    const { sensorGroups } = useSensorGroups();
    const handleClick = (e) => {
      e.stopPropagation();
      onSendMail(sensor);
    }
    const isLocked = () => {
      if (sensor?.isLocked === LockState.LOCKED && generalSetting?.alertOn) {
        const targetGroup = sensorGroups.find( g=> g.id === sensor.groupId);
        const status = sensor.status;
        if (status === -1) {
          return true;
        } else {
          const messageInfo = targetGroup?.messages && targetGroup.messages[status];
          return messageInfo.value !== SENSOR_STATUS_ALERT;
        }
      }
      return false;
    }

    return (
      <div className='map-page_flex-row map_page_card-function'>
          {
            isLocked() &&
            <button className='map-page_flex-row function-unclock' onClick={() => onUnlock(sensor)} onTouchStart={() => onUnlock(sensor)}>
              <span>警報復旧</span>
            </button>
          }
          <button className='map-page_flex-row function-detail' onClick={() => onViewSensorDetail(sensor)} onTouchStart={() => onViewSensorDetail(sensor)}>
            <span>詳細</span>
            <Icon icon={IconList.iconAllow}/>
          </button>
          <button className='function-email' onClick={handleClick} onTouchStart={handleClick}>
            <Icon icon={IconList.iconMail} containerStyle='icon_container' />
          </button>
          
          { isReload && <Icon icon={IconList.iconReload} containerStyle='icon_container' /> }
      </div>
    )
  }
  
FunctionCard.propTypes = {
    sensor: PropTypes.object.isRequired,
    onUnlock: PropTypes.func.isRequired,
    isReload: PropTypes.bool,
    onViewSensorDetail: PropTypes.func,
    onSendMail: PropTypes.func,
}
  
FunctionCard.defaultProps = {
    sensor: {},
    onUnlock: (sensor) => {},
    isReload: true,
    onViewSensorDetail: (sensor) => {},
    onSendMail: () => {},
}

export {
    SensorInfoCard,
    PopupContent,
    FunctionCard,
}