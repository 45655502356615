import React from 'react';
import PropTypes from 'prop-types';
import "./HistoryPage.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { apiAllListHistories } from 'apis/SensorApi';
import moment from 'moment';
import FanLoadingIcon from 'components/FanLoadingIcon';
import { useDialog } from 'context/DialogContext';
import { DIALOG_ERROR_DOWNLOAD_MORE_THAN_A_YEAR_OF_HISTORY, DIALOG_ERROR_GENERAL_ERROR_MESSAGE } from 'constants/message';
import DetailHeader from 'components/DetailHeader'
import { useSensorGroups } from 'context/SensorGroupsContext';
/**
 * A subpage to open history from other pages
 */

const propTypes={
  prevPage: PropTypes.string,
  onClose: PropTypes.func
};
const defaultProps={
  prevPage: '',
  onClose: () => {}
};
const HistoryPage = (props) => {
  /*** States and Variables ***/
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [loading, setLoading] =  React.useState(false);
  const { sensorGroups } = useSensorGroups();
  const { error } = useDialog();
  /*** Processing ***/
  React.useEffect(()=>{
    const end = new Date();
    end.setMinutes(end.getMinutes() - end.getTimezoneOffset())
    setEndTime(moment(end));
    const start = new Date(end);
    start.setFullYear(start.getFullYear()-1);
    setStartTime(moment(start));
  }, []);
  
  /*** Sub Components ***/
  /*** Event Handlers ***/
  const onChangeStartDate = (e) => {
    const start = moment(e.target.value);
    start.set('hour', 0);
    start.set('minute', 0);
    start.set('second', 0);
    setStartTime(moment(start));
  }
  const onChangeEndDate = (e) => {
    const end = moment(e.target.value);
    end.set('hour', 23);
    end.set('minute', 59);
    end.set('second', 59);
    setEndTime(moment(end));
  }
  const onDownloadHistory = async () => {
    if (endTime.valueOf() - startTime.valueOf() > 366*86400000) {
      error(DIALOG_ERROR_DOWNLOAD_MORE_THAN_A_YEAR_OF_HISTORY);
      return;
    }
    if (loading) return;
    setLoading(true);
    const res = await apiAllListHistories(startTime.toISOString(), endTime.toISOString());
    if (!res.success) {
      error(DIALOG_ERROR_GENERAL_ERROR_MESSAGE);
      setLoading(false);
      return;
    }
    let content = 'name,tx,status,payload,createdAt';
    const groupedSensors = {};
    for(const item of res.data) {
      if (!groupedSensors[item.sensorId]) {
        groupedSensors[item.sensorId] = [item];
      }
      else {
        groupedSensors[item.sensorId].push(item);
      }
    }
    for (const sensorId of Object.keys(groupedSensors)) {
      const targetGroup = sensorGroups.find(g=>g.id===groupedSensors[sensorId][0].groupId);
      for (const item of groupedSensors[sensorId]) {
        content += `\n${item.sensorName},${item.tx},${targetGroup.messages[item.status].value},${item.payload},${item.createdAt}`;
      }
    }
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(new Blob([content], {type: "text/plain"}));
    a.download = "result.csv";
    a.click();
    setLoading(false);
  }
  /*** Main Render ***/
  return (
    <div className='sensor_list_page_container'>
      <DetailHeader 
        mainLabel={props.prevPage}
        subLabel='履歴ダウンロード'
        onClick={props.onClose}
      />
      <div className='history_page_flex_row history_page_container'>
        <div className='history_page_one_line_item'>
          <label className='history_page_item_key'>
            期間
          </label>
          <div className='history_page_item_value'>
            {startTime && <input 
            type="date"
            className='history_page_date_picker'
            value={startTime.format('yyyy-MM-DD')}
            onChange={onChangeStartDate}/>}
            &nbsp;~&nbsp;
            {endTime && <input 
            type="date"
            className='history_page_date_picker'
            value={endTime.format('yyyy-MM-DD')}
            onChange={onChangeEndDate}/>}
          </div>
        </div>
        <button 
          className='history_page_button_download button_active'
          onClick={onDownloadHistory}
        >
          履歴ダウンロード
        </button>
      </div>
      {loading && <FanLoadingIcon size={80} />}
    </div>
  )
  // return <div className='history_page_container'>
  //   <div className='history_page_path_container'>
  //     <div className='history_page_path'>
  //       <label 
  //       onClick={props.onClose}
  //       className='history_page_path_item'>
  //         {props.prevPage}
  //       </label>
  //       &nbsp;
  //       <FontAwesomeIcon icon={faChevronRight}/>
  //       &nbsp;
  //       <label className='history_page_path_item'>
  //         履歴
  //       </label>
  //     </div>
  //   </div>
  //   <div className='history_page_one_line_item'>
  //     <label className='history_page_item_key'>
  //       開始時間
  //     </label>
  //     {startTime && <input 
  //     type="datetime-local"
  //     value={startTime.toISOString().substr(0,19)}
  //     onChange={(e)=>{setStartTime(moment(e.target.value))}}/>}
  //   </div>
  //   <div className='history_page_one_line_item'>
  //     <label className='history_page_item_key'>
  //       終了時間
  //     </label>
  //     {endTime && <input 
  //     type="datetime-local"
  //     value={endTime.toISOString().substr(0,19)}
  //     onChange={(e)=>{setEndTime(moment(e.target.value))}}/>}
  //   </div>
  //   <button 
  //   className='history_page_button_download button_active'
  //   onClick={onDownloadHistory}>
  //     履歴ダウンロード
  //   </button>
  //   {loading && <FanLoadingIcon size={80} />}
  // </div>;
}
HistoryPage.propTypes = propTypes;
HistoryPage.defaultProps = defaultProps;
export default HistoryPage;