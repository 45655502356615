import './Selections.css';
import React from 'react';
import iconDown from 'assets/svgicon/icon-arrow-down.svg';
import PropTypes from 'prop-types';
import Icon, { IconList } from './Icon';
import { useOutsideClick } from 'utils/function';

const Selection = (props) => {

    const [value, setValue] = React.useState(props.defaultValue);
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.filterValues.option !== value) {
            setValue(props.filterValues.option);
        }
    }, [props.filterValues.option]);

    const handleClickOutside = () => setIsOpen(false);
    const isValidation = () => isOpen;
    
    const refContainer = useOutsideClick(handleClickOutside, [isOpen], isValidation);

    const handleClick = (option) => () => {
        setValue(option);
        setIsOpen(false);
        if (option === props.defaultValue) {
            props.refreshFilter();
        } else {
            const temp = props.filterValues?.values?.filter((item) => item?.statusValue?.content === option);
            props.setFilterValues((prev) => ({
                ...prev,
                filterValues: temp,
                option,
            }))
        }
        
    }
    const handleReresh = () => {
        setValue(props.defaultValue);
        props.refreshFilter();
    }
  
    return (
        <div ref={refContainer} className='selection_flex-row filter'>
            <div className='selection_flex-row selections' onClick={() => setIsOpen(!isOpen)}>
                <label>{value}</label>
                <Icon icon={IconList.iconArrowDown} iconStyle={ isOpen ? 'rotate-down' : ''}/>
            </div>
            {
                props.isReload && <Icon icon={IconList.iconReload} onClick={handleReresh} />
            }
            {
                isOpen &&  <div className=' options'>
                    <div className='selection_flex-row options_container'>

                    </div>
                    {
                        props.options.map((option, index) => (
                            <div 
                                className='option-item' 
                                key={index}
                                style={{
                                    '--background': option === value ? '#f3f5f8' : 'transparent',
                                }}
                                onClick={handleClick(option)}
                            >
                                {option}
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

Selection.propTypes = {
    defaultValue: PropTypes.string,
    options: PropTypes.array,
    isReload: PropTypes.bool,
    setFilterValues: PropTypes.func,
    filterValues: PropTypes.object,
    refreshFilter: PropTypes.func,
}

Selection.defaultProps = {
    defaultValue: '',
    options: [],
    isReload: true,
    setFilterValues: () => {},
    filterValues: {},
    refreshFilter: () => {},
}

export default Selection;