/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      contact
      phone
      address
      description
      accountType
      isLocked
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      contact
      phone
      address
      description
      accountType
      isLocked
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      contact
      phone
      address
      description
      accountType
      isLocked
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSensor = /* GraphQL */ `
  mutation CreateSensor(
    $input: CreateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    createSensor(input: $input, condition: $condition) {
      id
      apiGroupId
      groupId
      managementId
      name
      status
      lat
      lng
      location
      description
      tx
      isLocked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSensor = /* GraphQL */ `
  mutation UpdateSensor(
    $input: UpdateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    updateSensor(input: $input, condition: $condition) {
      id
      apiGroupId
      groupId
      managementId
      name
      status
      lat
      lng
      location
      description
      tx
      isLocked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSensor = /* GraphQL */ `
  mutation DeleteSensor(
    $input: DeleteSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    deleteSensor(input: $input, condition: $condition) {
      id
      apiGroupId
      groupId
      managementId
      name
      status
      lat
      lng
      location
      description
      tx
      isLocked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSensorGroup = /* GraphQL */ `
  mutation CreateSensorGroup(
    $input: CreateSensorGroupInput!
    $condition: ModelSensorGroupConditionInput
  ) {
    createSensorGroup(input: $input, condition: $condition) {
      id
      name
      messages {
        status
        message
        color
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSensorGroup = /* GraphQL */ `
  mutation UpdateSensorGroup(
    $input: UpdateSensorGroupInput!
    $condition: ModelSensorGroupConditionInput
  ) {
    updateSensorGroup(input: $input, condition: $condition) {
      id
      name
      messages {
        status
        message
        color
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSensorGroup = /* GraphQL */ `
  mutation DeleteSensorGroup(
    $input: DeleteSensorGroupInput!
    $condition: ModelSensorGroupConditionInput
  ) {
    deleteSensorGroup(input: $input, condition: $condition) {
      id
      name
      messages {
        status
        message
        color
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSensorHistory = /* GraphQL */ `
  mutation CreateSensorHistory(
    $input: CreateSensorHistoryInput!
    $condition: ModelSensorHistoryConditionInput
  ) {
    createSensorHistory(input: $input, condition: $condition) {
      sensorId
      status
      lat
      lng
      createdAt
      tx
      payload
      updatedAt
      __typename
    }
  }
`;
export const createSensorChangeHistory = /* GraphQL */ `
  mutation CreateSensorChangeHistory(
    $input: CreateSensorChangeHistoryInput!
    $condition: ModelSensorChangeHistoryConditionInput
  ) {
    createSensorChangeHistory(input: $input, condition: $condition) {
      sensorId
      status
      lat
      lng
      createdAt
      tx
      payload
      updatedAt
      __typename
    }
  }
`;
export const adminCreateCognitoUser = /* GraphQL */ `
  mutation AdminCreateCognitoUser($input: AdminCreateCognitoUserInput) {
    adminCreateCognitoUser(input: $input) {
      success
      error
      data
      __typename
    }
  }
`;
export const adminSetCognitoUserPassword = /* GraphQL */ `
  mutation AdminSetCognitoUserPassword(
    $input: AdminSetCognitoUserPasswordInput
  ) {
    adminSetCognitoUserPassword(input: $input) {
      success
      error
      data
      __typename
    }
  }
`;
export const adminDeleteCognitoUser = /* GraphQL */ `
  mutation AdminDeleteCognitoUser($input: AdminDeleteCognitoUserInput) {
    adminDeleteCognitoUser(input: $input) {
      success
      error
      data
      __typename
    }
  }
`;
export const adminAddCognitoUserToAdminGroup = /* GraphQL */ `
  mutation AdminAddCognitoUserToAdminGroup(
    $input: AdminAddCognitoUserToAdminGroupInput
  ) {
    adminAddCognitoUserToAdminGroup(input: $input) {
      success
      error
      data
      __typename
    }
  }
`;
export const adminRemoveCognitoUserFromAdminGroup = /* GraphQL */ `
  mutation AdminRemoveCognitoUserFromAdminGroup(
    $input: AdminRemoveCognitoUserFromAdminGroupInput
  ) {
    adminRemoveCognitoUserFromAdminGroup(input: $input) {
      success
      error
      data
      __typename
    }
  }
`;
export const adminSendMail = /* GraphQL */ `
  mutation AdminSendMail($input: SendMailInput) {
    adminSendMail(input: $input) {
      success
      error
      data
      __typename
    }
  }
`;
