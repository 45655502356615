import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import "./ModifySensorForm.css";
import { useSensorGroups } from 'context/SensorGroupsContext';
import { DIALOG_ERROR_INVALID_LAT, DIALOG_ERROR_INVALID_LNG, TEXT_BUTTON_CREATE, TEXT_BUTTON_UPDATE, TEXT_CANCEL, TEXT_DESCRIPTION, TEXT_ELTRES_ID, TEXT_GROUP_ID, TEXT_LATITUDE, TEXT_LOCATION, TEXT_LONGITUDE, TEXT_MANAGEMENT_ID, TEXT_MESSAGE_GROUP, TEXT_NAME } from 'constants/message';
import { SENSOR_MAX_LAT, SENSOR_MAX_LNG, SENSOR_MIN_LAT, SENSOR_MIN_LNG } from 'constants/config';
import { useDialog } from 'context/DialogContext';

const FORM_ITEMS = [
  {name: 'managementId', displayName: TEXT_MANAGEMENT_ID, notNull: true},
  {name: 'name', displayName: TEXT_NAME, notNull: true},
  {name: 'location', displayName: TEXT_LOCATION, notNull: true},
  {name: 'groupId', displayName: TEXT_MESSAGE_GROUP, notNull: true},
  {name: 'id', displayName: TEXT_ELTRES_ID, notNull: true},
  {name: 'apiGroupId', displayName: TEXT_GROUP_ID, notNull: true},
  {name: 'lat', displayName: TEXT_LATITUDE, notNull: true},
  {name: 'lng', displayName: TEXT_LONGITUDE, notNull: true},
  {name: 'description', displayName: TEXT_DESCRIPTION, notNull: false}
];

const propTypes={
  sensor: PropTypes.object,
  onModify: PropTypes.func,
  onCancel: PropTypes.func
};
const defaultProps={
  sensor: {},
  onModify: (newSensor) => {},
  onCancel: () => {}
};
const ModifySensorForm = (props) => {
  /*** States and Variables ***/
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const initSensor = props.sensor;
  const { sensorGroups } = useSensorGroups();
  const { error } = useDialog();
  /*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  const onSubmit = async (data) => {
    if(!data.id) data.id = initSensor.id;
    const parsedLat = parseFloat(data.lat);
    const parsedLng = parseFloat(data.lng);
    if (isNaN(data.lat) || !isFinite(parsedLat) || isNaN(parsedLat) || parsedLat<SENSOR_MIN_LAT || parsedLat>SENSOR_MAX_LAT) {
      error(DIALOG_ERROR_INVALID_LAT);
      return;
    }
    if (isNaN(data.lng) || !isFinite(parsedLng) || isNaN(parsedLng) || parsedLng<SENSOR_MIN_LNG || parsedLng>SENSOR_MAX_LNG) {
      error(DIALOG_ERROR_INVALID_LNG);
      return;
    }
    
    props.onModify(data);
  }
  /*** Main Render ***/
  const groupIds = sensorGroups.map(group=>group.id);
  const groupId = groupIds.includes(initSensor.groupId && initSensor.groupId)? initSensor.groupId: null;
  return <form 
  className='modify_sensor_form_container'
  onSubmit={handleSubmit(onSubmit)}>
    {FORM_ITEMS.map(item=>{
      return <>
      <div 
      key={'item' + item.name}
      className='modify_sensor_form_item_line'>
        <label className='modify_sensor_form_item_key'>
          {item.displayName}
          {item.notNull? <label className='modify_sensor_form_notnull_field'>*</label>: null}
        </label>
        {item.name==='groupId'?
        <select
        className='modify_sensor_form_group_select'
        defaultValue={groupId}
        {...register(item.name, {required: true})}>
          <option 
          selected={!groupId? true: false}
          value=""
          disabled>グループを選択</option>
          {sensorGroups.map(group=>{
            return <option value={group.id} key={group.id}>
              {group.name}
            </option>
          })}
        </select>:
        <input
        type='text'
        disabled={initSensor.id && item.name==='id'}
        defaultValue={initSensor && (item.name!=='id'? initSensor[item.name]: initSensor[item.name]?.split('+')[0])}
        {...register(item.name, {required: (item.name==='id' && !initSensor.id) || (item.name!=='id' && item.notNull)})}/>}
      </div>
      {errors[item.name] && item.notNull && <label
      key={'error' + item.name}
      className='modify_sensor_form_error'>
        {`${item.displayName}を設定していません。`}
      </label>}
      </>
    })}
    <div className='modify_sensor_form_buttons_contaienr'>
      <input 
      className='modify_sensor_form_button button_active'
      type="submit"
      value={initSensor.id?TEXT_BUTTON_UPDATE: TEXT_BUTTON_CREATE} />
      <button 
      className='modify_sensor_form_button button_cancel'
      type='button'
      onClick={props.onCancel}>
        {TEXT_CANCEL}
      </button>
    </div>
    
  </form>
}
ModifySensorForm.propTypes = propTypes;
ModifySensorForm.defaultProps = defaultProps;
export default ModifySensorForm;