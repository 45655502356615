import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEnvelope, faTrash, faMapLocationDot } from "@fortawesome/free-solid-svg-icons"
import "./SensorDetailList.css";
import BaseList from '../../../components/BaseList';
import { useSensorGroups } from 'context/SensorGroupsContext';
import { ITEM_NUM_PER_PAGE, SENSOR_LOCATION_TEMPLATE, SENSOR_NAME_TEMPLATE, SENSOR_STATUS_ALERT, UNDEFINED_SENSOR_MESSAGE, UNDEFINED_SENSOR_STATUS } from 'constants/config';
import { TEXT_GROUP_ID, TEXT_NAME, TEXT_DATA_RECEIVED_DATETIME, TEXT_LOCATION, TEXT_LATITUDE, TEXT_LONGITUDE, TEXT_ELTRES_ID, TEXT_STATUS, TEXT_MESSAGE, TEXT_REGISTERED_DATE } from 'constants/message';
import { LockState } from 'models';
import { ROLE, SENSOR_PROPERTIES } from 'utils/constanst';
import { useGeneralSetting } from 'context/GeneralSettingContext';
import { TableHeader, TableRow } from './SensorTable';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  isManagementList: PropTypes.bool,
  curPage: PropTypes.number,
  onEditItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onViewItem: PropTypes.func,
  onSendMail: PropTypes.func,
  onFocusItem: PropTypes.func,
  onPageChange: PropTypes.func,
  functionBtn: PropTypes.func,
};
const defaultProps = {
  items: [],
  isManagementList: false,
  onEditItem: (item) => {},
  onDeleteItem: (item)=>{},
  onViewItem: (item)=>{},
  onSendMail: (item)=>{},
  onFocusItem: (item)=>{},
  onPageChange: (newPage)=>{}
};
const SensorDetailList = (props) => {
  /*** States and Variables ***/
  const { sensorGroups } = useSensorGroups();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(SENSOR_PROPERTIES.MANAGE_ID);
  const { generalSetting } = useGeneralSetting();
  /*** Processing ***/
  /*** Sub Components ***/
  const renderListHeader = () => {
    return <>
      <div className='sensor_detail_list_item'>
        <div className='sensor_detail_list_item_id_cell'>
          管理番号
        </div>
        <div className={props.isManagementList? 'sensor_detail_list_management_name_cell': 'sensor_detail_list_name_cell'}>
          {TEXT_NAME}
        </div>
        <div className={props.isManagementList? 'sensor_detail_list_management_location_cell': 'sensor_detail_list_location_cell'}>
          {TEXT_LOCATION}
        </div>
        {props.isManagementList ? <>
          <div className='sensor_detail_list_management_date_cell'>
            {TEXT_REGISTERED_DATE}
          </div>
          <div className='sensor_detail_list_item_id_cell'>
            {TEXT_ELTRES_ID}
          </div>
          <div className='sensor_detail_list_group_cell'>
            {TEXT_GROUP_ID}
          </div>
          <div className='sensor_detail_list_lat_cell'>
            {TEXT_LATITUDE}
          </div>
          <div className='sensor_detail_list_lon_cell'>
            {TEXT_LONGITUDE}
          </div>
        </>:
        <>
          <div className='sensor_detail_list_item_date_cell'>
            {TEXT_DATA_RECEIVED_DATETIME}
          </div>
          <div className='sensor_detail_list_item_status_cell'>
            {TEXT_STATUS}
          </div>
          <div className='sensor_detail_list_message_cell'>
            {TEXT_MESSAGE}
          </div>
        </>
        }
        <div className={props.isManagementList? 'sensor_detail_list_management_actions_cell': 'sensor_detail_list_actions_cell'}/>
      </div>
      <div className='sensor_detail_list_separator' />
    </>;
  }
  const renderItemRow = (item) => {
    const targetGroup = sensorGroups.find(g=>g.id===item.groupId);
    const targetMessageInfo = targetGroup?.messages && targetGroup.messages[item.status];
    const alertMessageInfo = targetGroup?.messages && targetGroup.messages.find(m=>m.value===SENSOR_STATUS_ALERT);
    return <>
        <div
        key={'item_' + item.id}
        className='sensor_detail_list_item'>
        <div
          onClick={() => {
            props.isManagementList? props.onEditItem(item): props.onViewItem(item)
          }}
          className='sensor_detail_list_item_id_cell text-link'>
          {item.managementId}
        </div>
        <div className={props.isManagementList? 'sensor_detail_list_management_name_cell': 'sensor_detail_list_name_cell'}>
          {item.name}
        </div>
        <div className={props.isManagementList? 'sensor_detail_list_management_location_cell': 'sensor_detail_list_location_cell'}>
          {item.location}
        </div>
        {props.isManagementList ? <>
          <div className='sensor_detail_list_management_date_cell'>
            {item.createdAt.split(' ')[0]}
          </div>
          <div className='sensor_detail_list_item_id_cell'>
            {item.id.split('+')[0]}
          </div>
          <div className='sensor_detail_list_group_cell'>
            {item.apiGroupId}
          </div>
          <div className='sensor_detail_list_lat_cell'>
            {item.lat.toFixed(3)}
          </div>
          <div className='sensor_detail_list_lon_cell'>
            {item.lng.toFixed(3)}
          </div>
        </>:
        <>
          <div className='sensor_detail_list_item_date_cell'>
            {item.tx}
          </div>
          <div className='sensor_detail_list_item_status_cell'>
            {item.status>=0? <div 
            style={{backgroundColor: targetMessageInfo?.color || '#000000'}}
            className='sensor_detail_list_item_status'>
              {targetMessageInfo?.status}
            </div>: UNDEFINED_SENSOR_STATUS}
            {item.isLocked===LockState.LOCKED && generalSetting.alertOn &&
            <div
            style={{backgroundColor: alertMessageInfo?.color || '#000000'}}
            className='sensor_detail_list_item_status'>
              警報ラッチ
            </div>}
          </div>
          <div className='sensor_detail_list_message_cell'>
            {item.status>=0 ?targetMessageInfo?.message?.replaceAll(SENSOR_NAME_TEMPLATE, item.name).replaceAll(SENSOR_LOCATION_TEMPLATE, item.location) : UNDEFINED_SENSOR_MESSAGE}
          </div>
        </>
        }
        <div className={props.isManagementList? 'sensor_detail_list_management_actions_cell': 'sensor_detail_list_actions_cell'}>
          {!props.isManagementList && <>
            <button 
            className='sensor_detail_list_button_mail'
            onClick={()=>{props.onSendMail(item)}}
            title="メール送信">
              <FontAwesomeIcon icon={faEnvelope}/>
            </button>
            <button 
            className='sensor_detail_list_button_delete'
            onClick={()=>{props.onFocusItem(item)}}
            title="マップを開く">
              <FontAwesomeIcon icon={faMapLocationDot}/>
            </button>
          </>}
          {props.isManagementList && <>
            <button 
            className='sensor_detail_list_button_delete'
            onClick={()=>{props.onDeleteItem(item)}}
            title="センサ削除">
              <FontAwesomeIcon icon={faTrash}/>
            </button>
          </>}
          
        </div>
      </div>
      <div 
      key={'separator' + item.id}
      className='sensor_detail_list_separator' />
    </>;
  }
  /*** Event Handlers ***/
  const isValidationColumn = (item) => {
    return props.isManagementList ?
      item.role === ROLE.MANAGE || item.role === ROLE.GENERAL :
      item.role === ROLE.LIST || item.role === ROLE.GENERAL
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }
  
  /*** Main Render ***/
  return <BaseList
    items={props.items}
    // renderHeader={renderListHeader}
    // renderItem={renderItemRow}
    renderHeader={() => <TableHeader 
                          role={ROLE.LIST} 
                          isValidationColumn={isValidationColumn} 
                          onRequestSort={handleRequestSort}
                          order={order}
                          orderBy={orderBy} 
                          />}
    renderItem={(item, i) => <TableRow 
                                sensor={item} 
                                key={i} 
                                onSendMail={props.onSendMail} 
                                onFocusItem={props.onFocusItem} 
                                onViewItem={props.onViewItem} 
                                onEditItem={props.onEditItem}
                                isManagementList={props.isManagementList} 
                                isValidationColumn={isValidationColumn}
                                onDeleteItem={props.onDeleteItem}
                            />}
    curPage={props.curPage}
    onPageChange={props.onPageChange}
    itemNumPerPage={ITEM_NUM_PER_PAGE}
    isNew={true}
    functionBtn={props.functionBtn}
    isSort={true}
    order={order}
    orderBy={orderBy}
    />;
}





SensorDetailList.propTypes = propTypes;
SensorDetailList.defaultProps = defaultProps;
export default SensorDetailList;