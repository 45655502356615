import { Storage } from 'aws-amplify';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

const Context = createContext({});

export default function GeneralSettingProvider({ children }) {
  const [generalSetting, setGeneralSetting] = useState();
  useEffect(() => {
    getGeneralSetting();
  }, [])

  const getGeneralSetting = async () => {
    try {
      const res = await Storage.get('setting/general_setting.json', {download: true, cacheControl: 'no-cache'});
      const setting = JSON.parse(await res.Body.text());
      setGeneralSetting(setting);
      document.title = setting.appTitle || '冠水監視';
    }
    catch (e) {
      // console.error("ERROR", e);
    }
  }

  return (
    <Context.Provider value={{ generalSetting, setGeneralSetting }}>
      {children}
    </Context.Provider>
  );
}

export const useGeneralSetting = () => useContext(Context);
