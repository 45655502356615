import './SensorSearchbar.css';
import React from 'react';
import PropTypes from 'prop-types';
import Selection from 'components/Selections';
import Searchbar from 'components/Searchbar';
import { useOutsideClick } from 'utils/function';
import Icon, { IconList } from 'components/Icon';
import { FunctionCard, SensorInfoCard } from './SensorInfoCard';
import iconLocked from 'assets/icon_locked.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_FILTER_STATUS = 'すべてのステータス';

const initValue = {
    values: [],
    filterValues: [],
    option: DEFAULT_FILTER_STATUS,
}

const SensorSearchbar = (props) => {
    const [keyword, setKeyword] = React.useState('');
    const [preKeyword, setPreKeyword] = React.useState('');
    const [sensorList, setSensorList] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isShow, setIsShow] = React.useState(true);
    const [groupStatus, setGroupStatus] = React.useState([DEFAULT_FILTER_STATUS]);
    const [value, setValue] = React.useState(initValue);

    // handle Click outside
    const isValidation = () => isOpen && !keyword;
    const resetSearch = () => {
        setKeyword('');
        setPreKeyword('');
        setSensorList([]);
        setIsOpen(false);
        setIsShow(true);
        refreshFilter();
        setIsShow(true);
        refreshFilter();
    }

    const refContainer = useOutsideClick(resetSearch, [isOpen, keyword], isValidation);

    //filter
    React.useEffect(() => {
        setValue((prev) => ({
            ...prev,
            values: sensorList,
            filterValues: sensorList,
            option: DEFAULT_FILTER_STATUS,
        }))
    }, [sensorList]);

    const refreshFilter = () => {
        setValue((prev) => ({
            ...prev,
            filterValues: prev.values,
            option: DEFAULT_FILTER_STATUS,
        }))
    }

    //other function
    const handleClickSensor = (sensor) => {
        props.onClick(sensor);
        props.setSensorInfo([sensor]);
    }


    //listSensor 
    const handleShowAllSensors = async () => {
        props.onReload();
        setKeyword('');
        setPreKeyword('');
        setIsShow(true);
        setIsOpen(true);
    }

    // main render
    return (
        <div ref={refContainer} className='sensor_searchbar'>
            <div className='search_flex-row sensor_searchbar'>
                <div className='search_flex-row label' onClick={handleShowAllSensors}>
                    <Icon icon={IconList.iconSensor} /> 
                    <label className='sensor_searchbar_label-text'>センサ一覧</label>
                </div>
                <Searchbar 
                    initSensors={props.initSensors} 
                    setSensorList={setSensorList} 
                    setIsOpen={setIsOpen} 
                    placeholder='センサーを検索'
                    setKeyword={setKeyword} 
                    setPreKeyword={setPreKeyword}
                    keyword={keyword}
                    preKeyword={preKeyword}
                    setGroupStatus={setGroupStatus}
                    defaultFilterStatus={[DEFAULT_FILTER_STATUS]}
                    onReload={props.onReload}
                />
            </div>
            {
                isOpen && 
                <div className='search_flex-row card-result' style={{ '--padding': isShow ? '30px' : '53px' }}>
                    {
                        isShow && <>
                            <div className='search_flex-row result-info'>
                                <div className='search_flex-row result-count'>
                                    <label>合計： {value.filterValues.length} 件</label>
                                    <div className='search_flex-row search-delete' onClick={resetSearch}>
                                        <Icon icon={IconList.iconAllow} iconStyle='rotate-down' />
                                        <label style={{ color: '#5B87B4' }}>検索結果をクリア</label>
                                    </div>
                                </div>
                                <Selection 
                                    defaultValue={DEFAULT_FILTER_STATUS} 
                                    options={groupStatus} 
                                    isReload={true} 
                                    setFilterValues={setValue} 
                                    filterValues={value} 
                                    refreshFilter={refreshFilter}
                                />
                            </div>
                            <div className='result-list'>
                                {
                                    value.filterValues.map((sensor) => (
                                        <div key={sensor.id} className={`result-item ${sensor.id === props.sensorInfo?.[0]?.id ? 'selected' : ''}`} onClick={() => handleClickSensor(sensor)}>
                                            <SensorInfoCard sensor={sensor} width='27%'/>
                                            <FunctionCard 
                                                sensor={sensor} 
                                                onUnlock={props.onUnlock} 
                                                onViewSensorDetail={props.onViewSensorDetail} 
                                                isReload={false} 
                                                onSendMail={props.onSendMail}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                        
                    }
                    <div className='search_flex-row short-btn' onClick={() => setIsShow(!isShow)}>
                        <Icon icon={IconList.iconArrowUp} iconStyle={isShow ? '' : 'rotate-down' } />
                        <label>たたむ</label>
                    </div>
                </div>
            }
            {
                props.hasWarningSensor &&
                <div className='alert_container'>
                    <FontAwesomeIcon 
                        icon={faTriangleExclamation}
                        style={{color: 'red'}}
                        className='page_wrapper_header_button'/>
                </div>
            }
        </div>
        
    )
}

SensorSearchbar.propTypes = {
    initSensors: PropTypes.array,
    onClick: PropTypes.func,
    onUnlock: PropTypes.func,
    setSensorInfo: PropTypes.func,
    sensorInfo: PropTypes.array,
    onViewSensorDetail: PropTypes.func,
    onSendMail: PropTypes.func,
    onReload: PropTypes.func,
    hasWarningSensor: PropTypes.bool,
}

SensorSearchbar.defaultProps = {
    initSensors: [],
    onClick: () => {},
    onUnlock: () => {},
    setSensorInfo: () => {},
    sensorInfo: [],
    onViewSensorDetail: () => {},
    onSendMail: () => {},
    onReload: () => {},
    hasWarningSensor: false,
}

export default SensorSearchbar;