import AWS from 'aws-sdk';
import {Auth, API} from 'aws-amplify';
import {adminAddCognitoUserToAdminGroup, adminCreateCognitoUser, adminDeleteCognitoUser, adminRemoveCognitoUserFromAdminGroup, adminSetCognitoUserPassword, createUser, deleteUser, updateUser} from 'graphql/mutations';
import {listUsers, getUserByEmail, getUser} from 'graphql/queries';
import {AUTH_MODE} from 'constants/config';
import moment from 'moment';
import { convertDateTimeFormat, convertMomentFormat } from 'utils/function';
import { AccountType } from 'models';

export const loginCognitoUser = async (email, password) => {
  try {
    await Auth.signIn(email.trim(), password);
    return { code: 1 };
  } catch (e) {
    return { code: 0, message: e.message }
  }
}

export const logoutCognitoUser = async () => {
  try {
    await Auth.signOut({ global: true })
  } catch (e) {
    // console.error(e);
  }
}

export const sendVerificationCodeForgotPassword = async (username) => {
  return await Auth.forgotPassword(username)
    .then((data) => { return { status: 1, message: data } })
    .catch((err) => { return { status: 0, message: err } });
}

export const submitNewPasswordWithVerificationCode = async (username, code, newPassword) => {
  return await Auth.forgotPasswordSubmit(username, code, newPassword)
    .then((data) => { return { status: 1, message: data } })
    .catch((err) => { return { status: 0, message: err } });
}

const _apiAdminCreateCognitoUser = async (input) => {
  try {
    const response = await API.graphql({
      query: adminCreateCognitoUser,
      variables: {
        input
      },
      authMode: AUTH_MODE
    });
    return response.data?.adminCreateCognitoUser;
  } catch (e) {
    return {
      success: false,
      error: e
    };
  }
}

export const createCognitoUser = async (data) => {
  try {
    const response = await Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        email: data.email,
        name: data.name
      },
      autoSignIn: {
        enabled: false
      }
    })
    return response;
  } catch (e) {
    // console.error("ERROR", e);
  }
}

export const apiListUsers = async (nextToken = null) => {
  try {
    const response = await API.graphql({
      query: listUsers,
      variables: {
        nextToken
      },
      authMode: AUTH_MODE
    });
    const users = response.data?.listUsers?.items;
    for (const user of users) {
      user.createdAt = user?.createdAt && convertDateTimeFormat(user.createdAt);
      user.updatedAt = user?.updatedAt && convertDateTimeFormat(user.updatedAt);
    }
    return {
      success: true,
      data: users
    };
  } catch (e) {
    return {
      success: false,
      error: e
    };
  }
}

export const apiGetUserByEmail = async (email) => {
  try {
    const response = await API.graphql({
      query: getUserByEmail,
      variables: {
        email
      },
      authMode: AUTH_MODE
    });
    return response.data?.getUserByEmail?.items[0];
  } catch (e) {
    return null;
  }
}

export const apiCreateUser = async (input) => {
  try {
    const createResult = await API.graphql({
      query: createUser,
      variables: {
        input: {
          name: input.name,
          email: input.email,
          accountType: input.accountType,
          contact: input.contact,
          phone: input.phone,
          description: input.description
        }
      },
      authMode: AUTH_MODE
    });
    const createData = createResult.data?.createUser;
    const cognitoResult = await _apiAdminCreateCognitoUser({
      type: 'createUser',
      userId: createData.id,
      name: input.name,
      email: input.email,
      password: input.password
    })
    if (cognitoResult.success) {
      if (input.accountType===AccountType.ADMIN || input.accountType===AccountType.MANAGER) {
        const changeRoleResult = await _addUserToAdminGroup(input.email);
        if (!changeRoleResult.success) return changeRoleResult;
      }
    }
    const updateResult = await apiUpdateUser({ id: createData.id, isActive: true });
    if (!updateResult.success) return updateResult;
    if (createData.updatedAt) createData.updatedAt = convertMomentFormat(createData.updatedAt);
    if (createData.createdAt) createData.createdAt = convertMomentFormat(createData.createdAt);
    return {
      success: true,
      data: createData
    }
  } catch (e) {
    return {
      success: false,
      error: e
    };
  }
}

const _addUserToAdminGroup = async (email) => {
  try {
    const response = await API.graphql({
      query: adminAddCognitoUserToAdminGroup,
      variables: {
        input: {
          type: 'addUserToAdminGroup',
          email
        }
      },
      authMode: AUTH_MODE
    });
    return response.data.adminAddCognitoUserToAdminGroup;
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const _removeUserFromAdminGroup = async (email) => {
  try {
    const response = await API.graphql({
      query: adminRemoveCognitoUserFromAdminGroup,
      variables: {
        input: {
          type: 'removeUserFromAdminGroup',
          email
        }
      },
      authMode: AUTH_MODE
    });
    return response.data.adminRemoveCognitoUserFromAdminGroup;
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

export const apiUpdateUser = async (input) => {
  try {
    if (input.accountType) {
      const changeRoleResult = input.accountType===AccountType.USER? await _removeUserFromAdminGroup(input.email): await _addUserToAdminGroup(input.email);
      if (!changeRoleResult.success) {
        return changeRoleResult;
      }
    }
    const response = await API.graphql({
      query: updateUser,
      variables: {
        input
      },
      authMode: AUTH_MODE
    });
    const user = response.data?.updateUser;
    user.createdAt = user?.createdAt && convertDateTimeFormat(user.createdAt);
    user.updatedAt = user?.updatedAt && convertDateTimeFormat(user.updatedAt);
    return {
      success: true,
      data: user
    }
  } catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

export const apiDeleteUser = async (userId, userEmail) => {
  try {
    let response = await API.graphql({
      query: adminDeleteCognitoUser,
      variables: {
        input: {
          type: 'deleteUser',
          email: userEmail
        }
      },
      authMode: AUTH_MODE
    });
    const adminDeleteResult = response.data.adminDeleteCognitoUser;
    if (!adminDeleteResult.success) {
      return adminDeleteResult;
    }
    const deleteResult = await API.graphql({
      query: deleteUser,
      variables: {
        input: {
          id: userId
        }
      },
      authMode: AUTH_MODE
    });
    return {
      success: true,
      data: deleteResult.data.deleteUser
    };
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

export const apiGetUser = async (userId) => {
  try {
    const res = await API.graphql({
      query: getUser,
      variables: {
        id: userId
      },
      authMode: AUTH_MODE
    });
    const user = res.data?.getUser;
    if (user?.createdAt) user.createdAt = convertDateTimeFormat(user.createdAt);
    if (user?.udatedAt) user.udatedAt = convertDateTimeFormat(user.updatedAt);
    return {
      success: true,
      data: user
    };
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

export const apiAdminChangeUserPassword = async (userEmail, password) => {
  try {
    const response = await API.graphql({
      query: adminSetCognitoUserPassword,
      variables: {
        input: {
          type: 'setUserPassword',
          email: userEmail,
          password: password
        }
      },
      authMode: AUTH_MODE
    });
    return {
      success: true,
      data: response.data.adminSetCognitoUserPassword
    }
  }
  catch(e) {
    return {
      success: false,
      error: e
    }
  }
}