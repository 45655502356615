import { API } from "aws-amplify"
import { listSensorChangeHistorys, listSensorGroups, listSensorHistorys, listSensors } from "../graphql/queries"
import { createSensorGroup, updateSensorGroup, deleteSensor, createSensor, updateSensor, createSensorHistory, createSensorChangeHistory, deleteSensorGroup } from "../graphql/mutations";
import {AUTH_MODE, DEFAULT_SENSOR_STATUS, ITEM_NUM_PER_PAGE} from 'constants/config';
import { convertDateTimeFormat, convertMomentFormat } from "utils/function";
import { LockState } from "models";
import {v4 as uuidv4} from 'uuid';
import { TEXT_ERROR_SENSOR_ALREADY_EXISTED } from "constants/message";

const apiListGroups = async () => {
  try {
    const params = {
      query: listSensorGroups,
      authMode: AUTH_MODE
    }
    let nextToken = null;
    const groups = [];
    while(true) {
      params.nextToken = nextToken;
      const res = await API.graphql(params);
      groups.push(...res.data?.listSensorGroups?.items);
      nextToken = res.data?.listSensorGroups?.nextToken;
      if (!nextToken) break;
    }
    for (const group of groups) {
      group.createdAt = group.createdAt? convertDateTimeFormat(group.createdAt): null;
      group.updatedAt = group.updatedAt? convertDateTimeFormat(group.updatedAt): null;
    }
    return {
      success: true,
      data: groups
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const apiCreateGroup = async (groupData) => {
  try {
    const res = await API.graphql({
      query: createSensorGroup,
      variables: {input: groupData},
      authMode: AUTH_MODE
    })
    const group = res.data?.createSensorGroup;
    group.createdAt = group.createdAt? convertDateTimeFormat(group.createdAt): null;
    group.updatedAt = group.updatedAt? convertDateTimeFormat(group.updatedAt): null;
    return {
      success: true,
      data: group
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const apiUpdateGroup = async (groupData) => {
  try {
    const res = await API.graphql({
      query: updateSensorGroup,
      variables: {input: groupData},
      authMode: AUTH_MODE
    })
    const group = res.data?.updateSensorGroup;
    group.createdAt = group.createdAt? convertDateTimeFormat(group.createdAt): null;
    group.updatedAt = group.updatedAt? convertDateTimeFormat(group.updatedAt): null;
    return {
      success: true,
      data: group
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const apiDeleteGroup = async (groupId) => {
  try {
    const res = await API.graphql({
      query: deleteSensorGroup,
      variables: {input: {id: groupId}},
      authMode: AUTH_MODE
    })
    const data = res.data?.deleteSensorGroup;
    return {
      success: true,
      data: data
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const apiCreateSensor = async (data) => {
  try {
    const response = await API.graphql({
      query: listSensors,
      variables: {
        filter: {
          id: {
            beginsWith: data.id
          }
        }
      },
      authMode: AUTH_MODE
    });
    if (response?.data?.listSensors?.items?.length) {
      return {
        success: false,
        error: TEXT_ERROR_SENSOR_ALREADY_EXISTED
      }
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
  data.status = DEFAULT_SENSOR_STATUS;
  data.isLocked = LockState.NOLOCK;
  data.id = `${data.id}+${uuidv4()}`;
  try {
    const response = await API.graphql({
      query: createSensor,
      variables: {input: data},
      authMode: AUTH_MODE
    });
    const sensor = response.data?.createSensor;
    sensor.createdAt = sensor.createdAt? convertDateTimeFormat(sensor.createdAt): null;
    sensor.updatedAt = sensor.updatedAt? convertMomentFormat(sensor.updatedAt): null;
    return {
      success: true,
      data: sensor
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const apiUpdateSensor = async (data) => {
  try {
    const res = await API.graphql({
      query: updateSensor,
      variables: {input: data},
      authMode: AUTH_MODE
    });
    const sensor = res.data?.updateSensor;
    sensor.createdAt = sensor.createdAt? convertDateTimeFormat(sensor.createdAt): null;
    sensor.updatedAt = sensor.updatedAt? convertMomentFormat(sensor.updatedAt): null;
    return {
      success: true,
      data: sensor
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const apiDeleteSensor = async (sensorId) => {
  try {
    const res = await API.graphql({
      query: deleteSensor,
      variables: {input: {id: sensorId}},
      authMode: AUTH_MODE
    });
    return {
      success: true,
      data: res.data?.deleteSensor
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const apiListHistories = async (sensorId, nextToken=null, limit=2*ITEM_NUM_PER_PAGE) => {
  try {
    const res = await API.graphql({
      query: listSensorChangeHistorys,
      variables: {
        sensorId,
        nextToken,
        sortDirection: 'DESC',
        limit
      },
      authMode: AUTH_MODE
    });
    for(const history of res.data?.listSensorChangeHistorys.items) {
      history.createdAt = history.createdAt? convertDateTimeFormat(history.createdAt): null;
      history.updatedAt = history.updatedAt? convertMomentFormat(history.updatedAt): null;
      history.tx = history.tx? convertMomentFormat(history.tx): null;
    }
    return {
      success: true,
      data: res.data?.listSensorChangeHistorys.items,
      nextToken: res.data?.listSensorChangeHistorys.nextToken
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const apiAllListHistories = async (startTime, endTime) => {
  try {
    let nextToken = null;
    const sensors = [];
    while (true) {
      const res = await API.graphql({
        query: listSensors,
        variables: {
          nextToken
        },
        authMode: AUTH_MODE
      });
      nextToken = res.data.listSensors.nextToken;
      sensors.push(...res.data.listSensors.items);
      if (!nextToken) break;
    }
    nextToken = null;
    const data = [];
    while (true) {
      const filter = {
        and: [
          {and: [
              {tx: {ge: startTime}},
              {tx: {le: endTime}},
            ]
          },
          {or: sensors.map(sensor=>({sensorId: {eq: sensor.id}}))}
        ]
      };
      const res = await API.graphql({
        query: listSensorHistorys,
        variables: {
          nextToken,
          filter: filter
        },
        authMode: AUTH_MODE
      });
      nextToken = res.data.listSensorHistorys.nextToken;
      data.push(...res.data.listSensorHistorys.items);
      if (!nextToken) break;
    }
    for(const item of data) {
      const sensor = sensors.find(s=>s.id===item.sensorId);
      item.groupId = sensor.groupId;
      item.sensorName = sensor.name;
    }
    return {
      success: true,
      data: data
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
export {
  apiListGroups,
  apiCreateGroup,
  apiUpdateGroup,
  apiDeleteGroup,
  apiCreateSensor,
  apiUpdateSensor,
  apiDeleteSensor,
  apiListHistories,
  apiAllListHistories
}