export const AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS';
export const CURRENT_PAGE_DEFAULT = 1;
export const ITEM_NUM_PER_PAGE = 100;
export const MAX_PAGE = 5;

// Time Format
export const MOMENT_FORMAT = 'yyyy/MM/DD HH:mm:ss';
export const DATE_TIME_FORMAT = 'yyyy/MM/DD HH:mm';
export const DATE_FORMAT = 'yyyy/MM/DD'

// Sensor
export const DEFAULT_SENSOR_STATUS = -1;
export const UNDEFINED_SENSOR_RECEIVED_TIME = ' ';
export const UNDEFINED_SENSOR_MESSAGE = ' ';
export const UNDEFINED_SENSOR_STATUS = ' ';
export const SENSOR_LOCATION_TEMPLATE = '%location';
export const SENSOR_NAME_TEMPLATE = '%sensor';
export const SENSOR_STATUS_NORMAL = '平常';
export const SENSOR_STATUS_ABNORMAL = '異常';
export const SENSOR_STATUS_ALERT = '警報';
export const SENSOR_STATUS_ATTENTION = '注意';
export const SENSOR_MIN_LAT = -89.85;
export const SENSOR_MAX_LAT = 89.85;
export const SENSOR_MIN_LNG = -180;
export const SENSOR_MAX_LNG = 180;